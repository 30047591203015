import React from "react";
import "./bubble.css";

const Bubble: React.FC<{ title: String; selected?: Boolean; onClick: any }> = ({
  title,
  selected,
  onClick,
}) => {
  return (
    <div className={`bubble ${selected && "bubble-filled"}`} onClick={onClick}>
      {title}
    </div>
  );
};

export default Bubble;
