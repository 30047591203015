import React from "react";

const ArrowDown = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49973 1V12.17L2.61973 7.29C2.22973 6.9 1.58973 6.9 1.19973 7.29C0.809727 7.68 0.809727 8.31 1.19973 8.7L7.78973 15.29C8.17973 15.68 8.80973 15.68 9.19973 15.29L15.7897 8.7C16.1797 8.31 16.1797 7.68 15.7897 7.29C15.3997 6.9 14.7697 6.9 14.3797 7.29L9.49973 12.17V1C9.49973 0.45 9.04973 0 8.49973 0C7.94973 0 7.49973 0.45 7.49973 1Z"
      fill="#39881B"
    />
  </svg>
);

export default ArrowDown;
