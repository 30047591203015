const PhoneButton = () => <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2167_12708)">
<circle cx="26.5" cy="22.5097" r="21.7778" fill="white"/>
<path d="M32 12.01L22 12C20.9 12 20 12.9 20 14V32C20 33.1 20.9 34 22 34H32C33.1 34 34 33.1 34 32V14C34 12.9 33.1 12.01 32 12.01ZM32 30H22V16H32V30Z" fill="#53B62D"/>
</g>
<defs>
<filter id="filter0_d_2167_12708" x="0.722229" y="0.731934" width="51.5555" height="51.5555" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2167_12708"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2167_12708" result="shape"/>
</filter>
</defs>
</svg>;

export default PhoneButton;
