import React, { useEffect, useState } from 'react';
import { getBlogPostBySlug } from '../../service/actions';
import './article.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Article = () => {
    const [post, setPost] = useState<any>();

    useEffect(() => {
        const fetchBlogPostBySlug = async () => {
            try {
                const result = await getBlogPostBySlug(`privacy`);
                if (result && result.data) {
                    setPost(result.data.posts[0]);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogPostBySlug();
    // eslint-disable-next-line
    }, []);

    return(
        <>
            <Navbar />
        <div className='article-wrapper'>
            <div className='article-root'>
                {
                post && 
                <>
                    <h1 className='header-title'>{post.title}</h1>
                    <h2 className='subheader'>{post.excerpt}</h2>
                    <div dangerouslySetInnerHTML={{__html: post.feature_image_caption}} className='feature-image-caption'/>
                    <div dangerouslySetInnerHTML={{ __html: post.html.replaceAll('http://54.91.148.4/', 'http://ghost.petpair.co/').replaceAll('figure', 'div').replaceAll('figcaption', 'div class="article-img-caption"') }} />
                </>
                }
            </div>
        </div>
            <Footer />
        </>
    )

};


export default Article;