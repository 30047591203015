const BackArrow = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.80425 5.76384L2.02183 6.54626L3.12835 6.54626L13.3675 6.54626C13.6185 6.54626 13.8258 6.75356 13.8258 7.00459C13.8258 7.25563 13.6185 7.46292 13.3675 7.46292L3.12835 7.46293L2.02183 7.46293L2.80425 8.24535L7.27759 12.7187C7.4561 12.8972 7.4561 13.1845 7.27759 13.363C7.09908 13.5415 6.81178 13.5415 6.63327 13.363L0.592435 7.32217C0.413925 7.14366 0.413925 6.85636 0.592435 6.67785L6.63327 0.637016C6.81178 0.458506 7.09908 0.458506 7.27759 0.637016C7.4561 0.815525 7.4561 1.11199 7.27759 1.2905L2.80425 5.76384Z"
      fill="black"
      stroke="black"
      strokeWidth="0.916667"
    />
  </svg>
);

export default BackArrow;
