const EmailButton = () => <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2167_12705)">
<circle cx="25.7778" cy="22.5097" r="21.7778" fill="white"/>
<path d="M33 15H17C15.9 15 15.01 15.9 15.01 17L15 29C15 30.1 15.9 31 17 31H33C34.1 31 35 30.1 35 29V17C35 15.9 34.1 15 33 15ZM32 29H18C17.45 29 17 28.55 17 28V19L23.94 23.34C24.59 23.75 25.41 23.75 26.06 23.34L33 19V28C33 28.55 32.55 29 32 29ZM25 22L17 17H33L25 22Z" fill="#53B62D"/>
</g>
<defs>
<filter id="filter0_d_2167_12705" x="0" y="0.731934" width="51.5555" height="51.5555" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2167_12705"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2167_12705" result="shape"/>
</filter>
</defs>
</svg>;

export default EmailButton;
