import React, { useState, useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import "./shelters.css";
import Navbar from "../../components/Navbar";
import ArrowDown from "../../assets/svg/ArrowDown";
import Button from "../../components/Button";
import costs from "../../assets/images/costs-shelters.png";
import fosters from "../../assets/images/fosters-shelters.png";
import transport from "../../assets/images/transport-shelters.png";
import devine from "../../assets/images/devine.png";
import parlier from "../../assets/images/parlier.png";
import austin from "../../assets/images/austin.png";
import hollister from "../../assets/images/hollister.png";
import chatham from "../../assets/images/chatham.png";
import vernon from "../../assets/images/vernon-parish.png";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';


const questions = [
  {
    question: "Who fosters with PetPair?",
    answer:
      "We find the best foster caregivers across the United States. They should have prior experience fostering multiple animals, know how to handle common challenges (e.g. house training), and (ideally) have a regular presence on social media platforms.",
  },
  {
    question: "What if I have an existing foster program?",
    answer:
      "We’d still love to work together! Through our network, your fosters will learn how to tackle the challenges of fostering from the best-of-the-best. This will reduce foster burnout, foster turnover, and the number of questions your staff needs to answer. If you need us to help you free up capacity, we’re always there, to support as much or as little as you need.",
  },
  {
    question: "How does PetPair transport animals?",
    answer:
      "We work with you to find trusted transport partners you feel comfortable with! If you have an existing network, we are happy to make them our 'first choice' transport partner. We also have our own partners, developed through partnership with Doobert, as well as vetted and trusted contractors.",
  },
  {
    question: "How does PetPair handle returns?",
    answer:
      "In the case of a failed adoption, we will first direct the pet to the foster. If the foster is unable to take on the pet at this time, we will work with you to find an appropriate solution.",
  },
  {
    question: "How does PetPair expand my shelter’s reach?",
    answer:
      "We’ve built a platform to showcase each and every adoptable pet in their best light. On each pet profile page, we’ll put your shelter front and center – and as more fosters join PetPair and post about their pets, they’ll become more aware of your shelter and its impact. We also empower and encourage fosters to share – 78% are willing to to share stories and content, with encouragement from us!",
  },
  {
    question: "Is PetPair a 501(c)3?",
    answer:
      "Yes! We run all foster and adoption outcomes through our 501(c)3 organization. We do have a venture-backed, for-profit arm, so sustainability is not an immediate concern! That being said, we plan to earn revenue through affiliate marketing efforts targeted toward the adopter.",
  },
  {
    question: "What else does PetPair do?",
    answer:
      "We conduct background checks on each and every foster, coordinate transport of pets between shelters, fosters, and adopters, and ensure transparency of outcome for our shelters.",
  },
];

const Shelters = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth < 600);
  const { t } = useTranslation();

  const toggleAccordion = (index: number) => {
    datadogLogs.logger.info(`FAQ clicked. Question: ${questions[index].question}`, {
      url: window.location.href,
    });
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    datadogLogs.logger.info("Shelters page loaded", {
      url: window.location.href,
    });

    const handleResize = () => {
      setIsMediumScreen(window.innerWidth < 540);
      console.log(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
  <title>{`PetPair | Shelters and Rescues`}</title>
  <meta name="description" content={`Partner with PetPair to drive attention to your adoptable dogs and increase your capacity for care. Sign up today!`} />
</Helmet>

      <Navbar />
      <div className="shelters-root">
        <div className="shelters-section">
          <span className="shelters-intro-title">
            {t("shelterPage.mainHeaderTagline")}
          </span>
          <div className="shelters-description-container">
            <p className="shelters-intro-description">
              {t("shelterPage.mainHeaderDescription")}
            </p>
          </div>
          <div className="shelters-cta-buttons">
            <Button
              title={t("shelterPage.mainHeaderCTA1")}
              filled
              medium={isMediumScreen}
              location="mailto:adopt@petpair.co"
            />
            <Button
              title={t("shelterPage.mainHeaderCTA2")}
              filled
              medium={isMediumScreen}
              location="mailto:daniel@petpair.co"
            />
          </div>
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
            <span className="section1-scroll-text">
              {t("aboutPage.scrollExplainer")}
            </span>
          </div>
        </div>
        <div className="shelters-section">
          <span className="shelters-section-title">
            {t("shelterPage.valuePropositionHeader")}
          </span>
          <div className="shelters-section-description-container">
            <p className="shelters-section-description">
              {t("shelterPage.valuePropositionDescription")}
            </p>
          </div>
          <br />
          <div className="shelters-benefits-container">
            <div className="shelters-benefit">
              <img alt='PetPair' src={costs} className="shelters-benefit-img" />
              <span className="shelters-benefit-title">
                {t("shelterPage.valuePropHeader1")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.valuePropDescription1")}
              </p>
            </div>
            <div className="shelters-benefit">
              <img alt='PetPair' src={fosters} className="shelters-benefit-img" />
              <span className="shelters-benefit-title">
                {t("shelterPage.valuePropHeader2")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.valuePropDescription2")}
              </p>
            </div>
            <div className="shelters-benefit">
              <img alt='PetPair' src={transport} className="shelters-benefit-img" />
              <span className="shelters-benefit-title">
                {t("shelterPage.valuePropHeader3")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.valuePropDescription3")}
              </p>
            </div>
          </div>
          <br />
          <Button
            title={t("shelterPage.valuePropositionCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>
        <div className="shelters-section">
          <span className="shelters-section-title">
            {t("shelterPage.ourProcessHeader")}
          </span>
          <div className="shelters-process-container">
            <div className="shelters-process">
              <div className="shelters-step-icon1">1</div>
              <span className="shelters-benefit-title">
                {t("shelterPage.step1Header")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.step1Description")}
              </p>
            </div>
            <div className="step-line-division" />
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="shelters-process">
              <div className="shelters-step-icon">2</div>
              <span className="shelters-benefit-title">
                {t("shelterPage.step2Header")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.step2Description")}
              </p>
            </div>
            <div className="step-line-division" />
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="shelters-process">
              <div className="shelters-step-icon">3</div>
              <span className="shelters-benefit-title">
                {t("shelterPage.step3Header")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.step3Description")}
              </p>
            </div>
            <div className="step-line-division" />
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="shelters-process">
              <div className="shelters-step-icon">4</div>
              <span className="shelters-benefit-title">
                {t("shelterPage.step4Header")}
              </span>
              <p className="shelters-benefit-description">
                {t("shelterPage.step4Description")}
              </p>
            </div>
          </div>
          <br />
          <Button
            title={t("shelterPage.ourProcessCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>

        <div className="shelters-section">
          <span className="shelters-section-title">
            {t("shelterPage.testimonialHeader")}
          </span>
          <div className="shelters-section-description-container">
            <p className="shelters-section-description">
              {t("shelterPage.testimonialDescription")}
            </p>
          </div>
          <div className="shelters-partners">
            <div className="statement-partner">
              <div className="partner-info">
                {/* <img alt='PetPair' src={poulter} className='partner-img' /> */}
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("shelterPage.testimonialTitle1")}
                  </span>
                  <span className="partner-name">
                    {t("shelterPage.testimonialname1")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("shelterPage.testimonialdescription1")}
              </p>
            </div>
            <div className="statement-partner">
              <div className="partner-info">
                {/* <img alt='PetPair' src={poulter} className='partner-img' /> */}
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("shelterPage.testimonialTitle2")}
                  </span>
                  <span className="partner-name">
                    {t("shelterPage.testimonialname2")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("shelterPage.testimonialdescription2")}
              </p>
            </div>
            <div className="statement-partner">
              <div className="partner-info">
                {/* <img alt='PetPair' src={poulter} className='partner-img' /> */}
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("shelterPage.testimonialTitle3")}
                  </span>
                  <span className="partner-name">
                    {t("shelterPage.testimonialname3")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("shelterPage.testimonialdescription3")}
              </p>
            </div>
          </div>
          <div className="partner-logos">
            <img alt='PetPair' src={devine} className="partner-logo" />
            <img alt='PetPair' src={chatham} className="partner-logo" />
            <img alt='PetPair' src={hollister} className="partner-logo" />
            <img alt='PetPair' src={vernon} className="partner-logo" />
            <img alt='PetPair' src={parlier} className="partner-logo" />
            <img alt='PetPair' src={austin} className="partner-logo" />
          </div>
          <br />
          <br />
          <Button
            title={t("shelterPage.testimonialCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>
        <div className="shelters-section">
          <span className="shelters-section-title">
            {t("shelterPage.faqHeader")}
          </span>
          <div className="questions-container">
            {questions.map((q, index) => (
              <div className="accordion-container" key={index}>
                <div
                  className={`question-accordion ${
                    activeIndex === index ? "accordion-open" : ""
                  }`}
                >
                  <span
                    className="accordion-question"
                    onClick={() => toggleAccordion(index)}
                  >
                    {q.question}
                  </span>
                  <div className="accordion-content">
                    <p className="accordion-answer">{q.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          <Button
            title={t("shelterPage.faqCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
        </div>
      </div>
    </>
  );
};

export default Shelters;
