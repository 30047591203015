import React from "react";
import "./dog-adoption-card.css";
import { formatDate } from "../../utils";

const DogAdoptionCard: React.FC<{
  dogName: string;
  adoptionDate: string;
  dogPhoto: string;
  breed?: string;
}> = ({ dogName, adoptionDate, dogPhoto, breed }) => {
  return (
    <div className="adoption-card">
      <img src={dogPhoto} className="adoption-card-photo" alt='PetPair'/>
      <div className="adoption-card-text-container">
        <span className="adoption-card-date">
          {breed ? breed : `Submitted ${formatDate(adoptionDate)}`}
        </span>
        <span className="adoption-card-name">{dogName}</span>
      </div>
    </div>
  );
};

export default DogAdoptionCard;
