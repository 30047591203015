import React, { useState, useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import "./fosters.css";
import Navbar from "../../components/Navbar";
import ArrowDown from "../../assets/svg/ArrowDown";
import Button from "../../components/Button";
import dogs from "../../assets/images/dogs-playing.png";
import support from "../../assets/images/petpair-support.png";
import network from "../../assets/images/foster-network.png";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';


const questions = [
  {
    question: "What are the benefits of fostering through PetPair?",
    answer:
      "We’re dog people who care deeply about YOU as a foster. We encourage our fosters to de-stress, and we connect our fosters to a network who can guide you through dealing with pets’ behavioral issues, breed-specific training, separation anxiety, and more. We also make the adoption process quick and easy to make sure you can focus on providing the best care for your pet. And, due to our unique partnerships, we will also be able to compensate our fosters for each successful foster outcome (adoption).",
  },
  {
    question: "What are the responsibilities of a PetPair Foster Champion?",
    answer:
      "When you sign up, you are volunteering to help save a pet’s life by opening up your home to them. It’ll be your job to provide a safe, clean, and caring environment, as well as food, water, litter, exercise and socialization. PetPair will support you with resources (like toys and materials) and guidance throughout your fostering journey!",
  },
  {
    question: "What kinds of pets can I foster?",
    answer:
      "Right now, PetPair is primarily focused on dogs – soon, we'll be working with feline friends as well!",
  },
  {
    question: "How does the adopter matching process work?",
    answer:
      "We'll tailor the adopter matches according to their personality match with your foster dog.",
  },
  {
    question:
      "I'm already fostering with a shelter or rescue. Can I still help?",
    answer:
      "We’ve built a platform to showcase each and every adoptable pet in their best light. On each pet profile page, we’ll put your shelter front and center – and as more fosters join PetPair and post about their pets, they’ll become more aware of your shelter and its impact. We also empower and encourage fosters to share – 78% are willing to to share stories and content, with encouragement from us!",
  },
  {
    question: "I am not able to foster right now. How can I help?",
    answer:
      "Sign up to share your expertise, join our foster community, and help with short-term foster stays for animals on the move!",
  },
  {
    question: "How do you handle returns?",
    answer:
      "In the unlikely event that an adopter and a pet do not fit, we will return a pet to you, the foster, and contact previous applicants to see if there is a match.",
  },
  {
    question: "How do I meet adopters?",
    answer:
      "At PetPair, your safety is our top priority. We will facilitate both in-person and virtual meetings, depending on your preference. Either way, every adopter match will be vetted, and you will be able to opt-in to background checks for each prospective adopter.",
  },
];

const Fosters = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth < 600);
  const { t } = useTranslation();

  const toggleAccordion = (index: number) => {
    datadogLogs.logger.info(`FAQ clicked. Question: ${questions[index].question}`, {
      url: window.location.href,
    });
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    datadogLogs.logger.info("Fosters page loaded", {
      url: window.location.href,
    });

    const handleResize = () => {
      setIsMediumScreen(window.innerWidth < 540);
      console.log(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
  <title>{`PetPair | Join our Foster Network, Save a Life!`}</title>
  <meta name="description" content={`Join our network of volunteer fosters and learn how you can can save the lives of at-risk dogs in your area!`} />
</Helmet>

      <Navbar />
      <div className="fosters-root">
        <div className="fosters-section">
          <span className="fosters-intro-title">
            {t("fosterPage.mainHeaderTagline")}
          </span>
          <div className="fosters-description-container">
            <p className="fosters-intro-description">
              {t("fosterPage.mainHeaderDescription")}
            </p>
          </div>
          <div className="fosters-cta-buttons">
            <Button
              title={t("fosterPage.mainHeaderCTA1")}
              filled
              medium={isMediumScreen}
              location='/foster-form'
            />
          </div>
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
            <span className="section1-scroll-text">
              {t("aboutPage.scrollExplainer")}
            </span>
          </div>
        </div>
        <div className="fosters-section">
          <span className="fosters-section-title">
            {t("fosterPage.valuePropositionHeader")}
          </span>
          <div className="fosters-section-description-container">
            <p className="fosters-section-description">
              {t("fosterPage.valuePropositionDescription")}
            </p>
          </div>

          <div className="fosters-benefits-container">
            <div className="fosters-benefit">
              <img alt='PetPair' src={dogs} className="fosters-benefit-img" />
              <span className="fosters-benefit-title">
                {t("fosterPage.valuePropHeader1")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.valuePropDescription1")}
              </p>
            </div>
            <div className="fosters-benefit">
              <img alt='PetPair' src={support} className="fosters-benefit-img" />
              <span className="fosters-benefit-title">
                {t("fosterPage.valuePropHeader2")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.valuePropDescription2")}
              </p>
            </div>
            <div className="fosters-benefit">
              <img alt='PetPair' src={network} className="fosters-benefit-img" />
              <span className="fosters-benefit-title">
                {t("fosterPage.valuePropHeader3")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.valuePropDescription3")}
              </p>
            </div>
          </div>
          <br />
          <Button
            title={t("fosterPage.valuePropositionCTA")}
            filled
            medium={isMediumScreen}
            location='/foster-form'
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>
        <div className="fosters-section">
          <span className="fosters-section-title">
            {t("fosterPage.ourProcessHeader")}
          </span>
          <div className="shelters-process-container">
            <div className="shelters-process">
              <div className="shelters-step-icon1">1</div>
              <span className="fosters-benefit-title">
                {t("fosterPage.step1Header")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.step1Description")}
              </p>
            </div>
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="step-line-division" />
            <div className="shelters-process">
              <div className="shelters-step-icon">2</div>
              <span className="fosters-benefit-title">
                {t("fosterPage.step2Header")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.step2Description")}
              </p>
            </div>
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="step-line-division" />
            <div className="shelters-process">
              <div className="shelters-step-icon">3</div>
              <span className="fosters-benefit-title">
                {t("fosterPage.step3Header")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.step3Description")}
              </p>
            </div>
            <div className="shelters-step-arrow-down">
              <ArrowDown />
            </div>
            <div className="step-line-division" />
            <div className="shelters-process">
              <div className="shelters-step-icon">4</div>
              <span className="fosters-benefit-title">
                {t("fosterPage.step4Header")}
              </span>
              <p className="fosters-benefit-description">
                {t("fosterPage.step4Description")}
              </p>
            </div>
          </div>
          <br />
          <Button
            title={t("fosterPage.ourProcessCTA")}
            filled
            medium={isMediumScreen}
            location="/foster-form"
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>

        <div className="fosters-section">
          <span className="fosters-section-title">
            {t("fosterPage.testimonialHeader")}
          </span>
          <br />
          <br />
          <div className="fosters-partners">
            <div className="statement-partner">
              <div className="partner-info">
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("fosterPage.testimonialTitle1")}
                  </span>
                  <span className="partner-name">
                    {t("fosterPage.testimonialname1")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("fosterPage.testimonialDescription1")}
              </p>
            </div>

            <div className="statement-partner">
              <div className="partner-info">
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("fosterPage.testimonialTitle2")}
                  </span>
                  <span className="partner-name">
                    {t("fosterPage.testimonialname2")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("fosterPage.testimonialDescription2")}
              </p>
            </div>

            <div className="statement-partner">
              <div className="partner-info">
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("fosterPage.testimonialTitle3")}
                  </span>
                  <span className="partner-name">
                    {t("fosterPage.testimonialname3")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("fosterPage.testimonialDescription3")}
              </p>
            </div>
          </div>
          <div className="fosters-partners">
            <div className="statement-partner">
              <div className="partner-info">
                <div className="partner-text-info">
                  <span className="partner-job-title">
                    {t("fosterPage.testimonialTitle4")}
                  </span>
                  <span className="partner-name">
                    {t("fosterPage.testimonialname4")}
                  </span>
                </div>
              </div>
              <p className="partner-statement">
                {t("fosterPage.testimonialDescription4")}
              </p>
            </div>
          </div>

          <br />
          <br />
          <br />
          <Button
            title={t("fosterPage.testimonialCTA")}
            filled
            medium={isMediumScreen}
            location="/foster-form"
          />
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
          </div>
        </div>
        <div className="fosters-section">
          <span className="fosters-section-title">
            {t("fosterPage.faqHeader")}
          </span>
          <div className="questions-container">
            {questions.map((q, index) => (
              <div className="accordion-container" key={index}>
                <div
                  className={`question-accordion ${
                    activeIndex === index ? "accordion-open" : ""
                  }`}
                >
                  <span
                    className="accordion-question"
                    onClick={() => toggleAccordion(index)}
                  >
                    {q.question}
                  </span>
                  <div className="accordion-content">
                    <p className="accordion-answer">{q.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <br />
          <Button
            title={t("fosterPage.faqCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
        </div>
      </div>
    </>
  );
};

export default Fosters;
