// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import "./profile.css";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import { Divider, Switch } from "antd";
import Input from "../../components/Input";
import Footer from "../../components/Footer";
import DogCard from "../../components/DogCard";
import InputMask from "react-input-mask";
import Spinner from "../../components/Spinner";
import {
  getToken,
  getUsers,
  editUser,
  getDogs,
  updatePassword,
} from "../../service/actions";
import { Modal } from "antd";
import { showToast } from "../../utils";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';

const ProfileSettings = () => {
  const [tab, setTab] = useState(1);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);
  const [favorite_dogs, setFavoriteDogs] = useState([]);
  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [activeApplications, setActiveApplications] = useState([]);
  const [activeApplicationsStatus, setActiveApplicationsStatus] = useState({});

  const navigate = useParamsNavigate();
  const location = useLocation();

  useEffect(() => {
    document.title = `PetPair | My Profile`;
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    console.log("selected tab", tab);
    if (tab && tab >= 1 && tab <= 3) {
      setTab(parseInt(tab));
    }
    getToken()
      .then((token) => {
        if (token) setToken(JSON.parse(token));
        else navigate("/");
      })
      .catch(() => {
        navigate("/");
      });
  }, [location.search, navigate]);

  useEffect(() => {
    if (token)
      getUsers(token).then((res) => {
        if (res) {
          setUser(res.data);

          localStorage.setItem("user", JSON.stringify(res.data));
        }
        setLoading(false);
      });
  }, [token, disabled]);

  useEffect(() => {
    if (user && !name && !lastName) {
      let nameArr = user.name?.split(" ") || [];
      setName(nameArr[0]);
      setLastName(
        nameArr
          .filter((name) => name !== nameArr[0])
          .toString()
          .replaceAll(",", " ")
      );
      if (user.favorite_dogs && user.favorite_dogs.length > 0)
        getDogs("", "", user.favorite_dogs, "name", [], [], []).then((result) => {
          setFavoriteDogs(result.dogs);
        });
    }

    if (user) {
      let dogIds = [];
      datadogLogs.logger.info("Profile settings page loaded", {
        user,
        url: window.location.href,
      });
      user.adoptionsAsAdopter.map((adoption) => {
        dogIds.push(adoption.dogId);
        setActiveApplicationsStatus((prevState: any) => {
          return {
            ...prevState,
            [adoption.dogId]: { status: adoption.status, id: adoption.id },
          };
        });
        return 1;
      });
      if (user.adoptionsAsAdopter.length > 0)
        getDogs("", "", dogIds, "name", [], [], []).then((result) => {
          setActiveApplications(result.dogs);
        });
    }
  }, [user, name, lastName]);

  if (loading) {
    return (
      <div>
        <Navbar />
        <Spinner isDone={false} page />
      </div>
    );
  }

  const handleInputChange = (e: any) => {
    const { name: field, value } = e.target;
    if (field === "name") {
      setName(value);
      setUser((prevState) => ({ ...prevState, name: value + " " + lastName }));
    } else if (field === "lastName") {
      setLastName(value);
      setUser((prevState) => ({ ...prevState, name: name + " " + value }));
    } else {
      setUser((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  const handlePassword = (e: any) => {
    setPassword((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handlePasswordUpdate = () => {
    const newPassword = password.new;
    const { current, confirm } = password;

    if (newPassword !== confirm)
      showToast(
        "The passwords are different. Please make sure the new password and confirmation match."
      );
    else if (!newPassword || !current || !confirm)
      showToast(
        "All fields must be filled. Please complete all the required fields."
      );
    else if (current === confirm)
      showToast(
        "New password cannot be the same as the current password. Please choose a different password."
      );
    else
      updatePassword(current, newPassword, user.id, token)
        .then(() => {
          setIsModalOpen(false);
          showToast("Password changed");
        })
        .catch((err) => {
          showToast("An error occurred, password could not be changed.");
        });
  };

  const handleSwitchChange = (e, type) => {
    datadogLogs.logger.info(`${type} toggle clicked on user profile`, {
      user,
      e,
      type,
      url: window.location.href,
    });
    setUser((prevState) => {
      const updatedUser = { ...prevState, [type]: e };
      editUser(updatedUser, token).then(() => {
        showToast("Changes saved!");
      });
      return updatedUser;
    });
  };

  const submitUserData = () => {
    editUser(user, token)
      .then(() => {
        setDisabled(true);
      })
      .catch(() => showToast("Could not edit profile, try again later"));
  };

  return (
    <div>
      <Navbar />
      <div className="profile-root"> 
        <div className="profile-header">
          <span className="title">{t("profile.header")}</span>
          <div className="profile-nav">
            <div className="options">
              <span
                className={`option first-option ${tab === 1 && "selected"}`}
                onClick={() => {
                  setTab(1);
                  datadogLogs.logger.info(`${t("profile.myDetailsTab")} tab clicked.`, {
                    user,
                    url: window.location.href,
                  });
                }}
              >
                {t("profile.myDetailsTab")}
              </span>
              <span
                className={`option ${tab === 2 && "selected"}`}
                onClick={() => {
                  setTab(2);
                  datadogLogs.logger.info(`${t("profile.favoriteDogsTab")} tab clicked.`, {
                    user,
                    url: window.location.href,
                  });
                }}
              >
                {t("profile.favoriteDogsTab")}
              </span>
              <span
                className={`option ${tab === 3 && "selected"}`}
                onClick={() => {
                  setTab(3);
                  datadogLogs.logger.info(`Applications tab clicked.`, {
                    user,
                    url: window.location.href,
                  });
                }}
              >
                Applications
              </span>
            </div>
          </div>
        </div>
        {tab === 1 && (
          <div className="profile-tab1">
            <div className={`header ${!disabled && "profile-enabled"}`}>
              <div className="leftp">
                <span className="profile-inner-title">
                  {t("profile.personalInformationHeader")}
                </span>
                <span className="profile-inner-title2">
                  {t("profile.personalInformationDescription")}
                </span>
              </div>
              <div className={`rightp`}>
                {!disabled ? (
                  <div className="edit-profile">
                    <Button
                      title={t("profile.cancelButton")}
                      small
                      onClick={() => setDisabled(true)}
                    />
                    <Button
                      title={t("profile.saveButton")}
                      filled
                      small
                      onClick={submitUserData}
                    />
                  </div>
                ) : (
                  <Button
                    title={t("profile.editButton")}
                    filled
                    onClick={() => setDisabled(false)}
                    small
                  />
                )}
              </div>
            </div>
            <Divider />
            <div className="profile-content">
              <span className="profile-inner-title">{t("profile.name")}</span>
              <div className="form-double">
                <Input
                  label={t("profile.firstName")}
                  profileSettings
                  disabled={disabled}
                  value={name}
                  name="name"
                  onChange={handleInputChange}
                />
                <Input
                  label={t("profile.lastName")}
                  profileSettings
                  disabled={disabled}
                  value={lastName}
                  name="lastName"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="profile-content2">
              <span className="profile-inner-title">
                {t("profile.emailAndPhone")}
              </span>
              <div className="form-double">
                <Input
                  label={t("profile.emailAddress")}
                  profileSettings
                  disabled
                  uneditable
                  value={user.email}
                  name="email"
                  onChange={handleInputChange}
                />
                <InputMask
                  mask="(999) 999-9999"
                  value={user.phone}
                  disabled={disabled}
                  name="phone"
                  onChange={handleInputChange}
                >
                  {(inputProps) => (
                    <Input
                      label={t("profile.phone")}
                      profileSettings
                      disabled={disabled}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </div>
            <div className="profile-content2">
              <span className="profile-inner-title">
                {t("profile.address")}
              </span>
              <div className="form-single">
                <Input
                  label={t("profile.homeAddress")}
                  profileSettings
                  disabled={disabled}
                  value={user.address}
                  name="address"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="profile-content2">
              <span className="profile-inner-title">
                {t("profile.password")}
              </span>
              <div className="form-single">
                <Input
                  label={t("profile.currentPassword")}
                  profileSettings
                  disabled
                  value="*******"
                  type="password"
                />
              </div>
            </div>
            <div className="profile-content2">
              <span
                className="profile-password"
                onClick={() => {
                  datadogLogs.logger.info(`Change password clicked.`, {
                    user,
                    url: window.location.href,
                  });
                  setIsModalOpen(true);
                }}
              >
                {t("profile.changePassword")}
              </span>
            </div>
            <div className="profile-content2">
              <a className="profile-delete" href='mailto:adopt@petpair.co?subject=Delete%20Account'>
                {t("profile.deleteAccount")}
              </a>
            </div>
            <Divider />
            <div className="profile-notification">
              <span className="profile-inner-title">
                {t("profile.notificationSettings")}
              </span>
              <span className="profile-inner-title2">
                {t("profile.notificationSettingsDescription")}
              </span>
            </div>
            <Divider />
            <div className="notification-panel">
              <div className="leftp">
                <span className="profile-inner-title">
                  {t("profile.emailNotificationsTitle")}
                </span>
                <span className="profile-inner-title2">
                  {t("profile.emailNotificationsDescription")}
                </span>
              </div>
              <div className="rightp">
                <Switch
                  checked={user.email_notifications}
                  name="email_notification"
                  onChange={(e) => handleSwitchChange(e, "email_notifications")}
                />
              </div>
            </div>

            <div className="notification-panel">
              <div className="leftp">
                <span className="profile-inner-title">
                  {t("profile.phoneNotificationsTitle")}
                </span>
                <span className="profile-inner-title2">
                  {t("profile.phoneNotificationsDescription")}
                </span>
              </div>
              <div className="rightp">
                <Switch
                  checked={user.phone_notifications}
                  name="phone_notification"
                  onChange={(e) => handleSwitchChange(e, "phone_notifications")}
                />
              </div>
            </div>
          </div>
        )}
        {tab === 2 && (
          <div className="profile-tab2">
            <div className="profile-tab2-dogs">
              {user &&
                favorite_dogs.map((dog: any, index: any) => (
                  <DogCard
                  dog={dog}
                    allDogs
                    key={dog.slug + dog.name + dog.index}
                    name={dog.name}
                    age={dog.age}
                    weight={dog.weight}
                    breed={dog.breed.name}
                    img={(dog.photos[0] || '')}
                    location={"/dog/" + dog.slug}
                    id={dog.id}
                    setFavoriteParent={setFavoriteDogs}
                  />
                ))}
            </div>
            <div className="profile-tab2-button">
              <Button
                filled
                title="Find more dogs like these"
                onClick={() => navigate("/discovery")}
              />
            </div>
          </div>
        )}
        {tab === 3 && (
          <div className="profile-tab3">
            <span className="profile-tab3-title">Active Applications</span>
            <div className="profile-tab3-dogs">
              {user &&
                activeApplications.length > 0 &&
                activeApplications.map((dog: any, index: any) => (
                  <DogCard
                    application
                    dog={dog}
                    applicationId={activeApplicationsStatus[dog.id]?.id}
                    applicationStatus={activeApplicationsStatus[dog.id]?.status}
                    allDogs
                    key={dog.slug + dog.name + dog.index}
                    name={dog.name}
                    age={dog.age}
                    weight={dog.weight}
                    breed={dog.breed.name}
                    slug={dog.slug}
                    img={(dog.photos[0] || '')}
                    location={"/dog/" + dog.slug}
                    id={dog.id}
                    setFavoriteParent={setFavoriteDogs}
                  />
                ))}
            </div>

            <Divider />
            {/* <span className='profile-tab3-title'>Previous Applications</span>
                        <span className='profile-tab3-title2'>Some text here</span>
                        <div className='profile-tab3-dogs'>
                            
                        </div> */}
          </div>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={null}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <div className="account-creation-header">
          <span className="account-creation-title">
            {t("profile.changePassword")}
          </span>
        </div>
        <Input
          placeholder={t("profile.currentPassword")}
          label={t("profile.currentPassword")}
          type="password"
          value={password.current}
          onChange={handlePassword}
          name="current"
        />
        <br />
        <Input
          placeholder={t("profile.newPassword")}
          label={t("profile.newPassword")}
          type="password"
          value={password.new}
          onChange={handlePassword}
          name="new"
        />
        <br />
        <Input
          placeholder={t("profile.confirmPassword")}
          label={t("profile.confirmPassword")}
          type="password"
          value={password.confirm}
          onChange={handlePassword}
          name="confirm"
        />
        <br />
        <Button
          filled
          title={t("profile.changePassword")}
          onClick={handlePasswordUpdate}
        />
        <br />
      </Modal>
      <Footer />
    </div>
  );
};

export default ProfileSettings;
