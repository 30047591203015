import React, { useState } from "react";
import QuestionIcon from "../../assets/svg/QuestionIcon";
import "./tag.css";

const Tag: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="tag">
      <span>{title}</span>
      <div
        className="tag-icon"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <QuestionIcon />
        {isTooltipVisible && <div className="tooltip">{description}</div>}
      </div>
    </div>
  );
};

export default Tag;
