// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import {
  getDogDimensions,
  getUserByEmail,
  savePreference,
  registerSocial
} from "../../service/actions";
import "./quizxp.css";
// import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";
import CarIcon from "../../assets/svg/CarIcon";
import BabyStrollerIcon from "../../assets/svg/BabyStrollerIcon";
import WeightGreenIcon from "../../assets/svg/WeightGreenIcon";
import QuizStarIcon from "../../assets/svg/QuizStarIcon";
import toast from 'react-hot-toast';
import Bubble from "../../components/Bubble";
import EmailIcon from "../../assets/svg/EmailIcon";

const ApplicationEntryPoint = (props) => {
  // const { t, i18n } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modifier, setModifier] = useState('');
  const [traits, setTraits] = useState<any>([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [preferredSize, setPreferredSize] = useState([]);
  const [dogMustBeGoodWith, setDogMustBeGoodWith] = useState([]);
  const [pickupAvailability, setPickupAvailability] = useState('');
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(true);

  useEffect(() => {
    const sendPreference = async () => {
      const result = await savePreference(email, [], selectedTraits, preferredSize, pickupAvailability, dogMustBeGoodWith);
      datadogLogs.logger.info(
        `Successfully saved preference for user: ${email}`,
        {
          selectedTraits,
          preferredSize,
          dogMustBeGoodWith,
          pickupAvailability,
          result
        }
      );
    }
    sendPreference();
    //eslint-disable-next-line
  }, [preferredSize, selectedTraits, pickupAvailability, dogMustBeGoodWith])


  const navigate = useParamsNavigate();


  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const dogDimensions = async () => {
      const dimensions = await getDogDimensions();
      setTraits(dimensions.traits);
    };
    dogDimensions();

    datadogLogs.logger.info(
      `Non-application quiz experience page loaded.`,
      { url: window.location.href }
    );

  }, []);

  const handleBubbleClick = (type, value) => {
    if (type === "age") {
      if (selectedAge.includes(value))
        setSelectedAge((prevState) => prevState.filter((e) => e !== value));
      else setSelectedAge((prevState) => [...prevState, value]);
    }
    if (type === "traits") {
      let name = value.name;
      if (selectedTraits.includes(name)) {
        setSelectedTraits((prevState) => prevState.filter((e) => e !== name));
      } else {
        setSelectedTraits((prevState) => [...prevState, name]);
      }
    }
    if (type === "weight") {
      if (selectedWeight.includes(value))
        setSelectedWeight((prevState) => prevState.filter((e) => e !== value));
      else {
        setSelectedWeight((prevState) => [...prevState, value]);
      }
    }
  };

  const handleDogMustBeGoodWithChange = (value, isChecked) => {
    if (isChecked) {
      setDogMustBeGoodWith((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setDogMustBeGoodWith((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  
  const handlePreferredSizeChange = (value, isChecked) => {
    if (isChecked) {
      setPreferredSize((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setPreferredSize((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const routerLocation = useLocation();

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    if (modifierParam) setModifier(modifierParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);

  const handleEmailRequest = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email.", { style: { fontWeight: 500 } });
      return;
    }
    

    try {
      await getUserByEmail(email);

      setShowEmail(false);

    } catch (err) {
      if (err.response && err.response.status === 404) {
        try {
          const data = await registerSocial({ email, type: 'QUIZ' });
          
          datadogLogs.logger.info(
            `Account successfully created using quiz xp. Email: ${email}`,
            {
              dog: adoptRequest.data.adoption.dog,
              user: adoptRequest.data.adoption.user,
              url: window.location.href,
              data
            }
          );

          setShowEmail(false);

        } catch (err) {
          console.log(err);
        }
      } else {
        toast.error(
          "We could not process your request at this time, please try again later.", { style: { fontWeight: 500 } }
        );

      }
    }
  };


  return (
    <div className="adoption">
      <div className="short-app-left">
        <div className="adoption-left-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            <Counter max={3} current={2} />
          </div>
          <div>
              <div className="short-adoption-header">
                <span className="adoption-left-title">{"Let’s get an idea of what you’re looking for."}</span>
                <span className="adoption-left-description">
                  {"We need your email so we can save your top adoptable matches."}
                </span>
              </div>

              <div className="adoption-quiz-form-container">
                <br />
                <div className='adoption-quiz-title'>
                  <EmailIcon />
                  <p className='adoption-quiz-title-text'>Email *</p>
                </div>
                <Input disabled={!showEmail} value={email} onChange={(e) => {
                  setEmail(e.target.value)
                }} placeholder='Enter your email' onBlur={handleEmailRequest} required/>
                <br/>
                <div className='adoption-quiz-title'>
                  <CarIcon />
                  <p className='adoption-quiz-title-text'>How far would you drive?</p>
                </div>
                <div className="app-radio-options-vertical">
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="Interested in transport"
                      id="interested-transport"
                      checked={pickupAvailability === "Interested in transport"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="interested-transport">Interested in transport</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="<30 min"
                      id="30min-pickup"
                      checked={pickupAvailability === "<30 min"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="30min-pickup">{'<30 min'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="30-60 min"
                      id="30-60min-pickup"
                      checked={pickupAvailability === "30-60 min"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="30-60min-pickup">{'30-60 min'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="1-2 hrs"
                      id="1-2hrs-pickup"
                      checked={pickupAvailability === "1-2 hrs"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="1-2hrs-pickup">{'1-2 hrs'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="2+ hrs"
                      id="2hrs-pickup"
                      checked={pickupAvailability === "2+ hrs"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="2hrs-pickup">{'2+ hrs'}</label>
                  </div>
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <BabyStrollerIcon />
                  <p className='adoption-quiz-title-text'>Should your dog be friendly with...</p>
                </div>
                <div className="checkbox-grid">
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Cats")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Cats",
                          e.target.checked
                        )
                      }
                    />
                    Cats
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Kids")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Kids",
                          e.target.checked
                        )
                      }
                    />
                    Kids
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Dogs")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Dogs",
                          e.target.checked
                        )
                      }
                    />
                    Dogs
                  </label>
                  {/* Add more checkboxes and labels as needed */}
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <WeightGreenIcon />
                  <p className='adoption-quiz-title-text'>How big should they be?</p>
                </div>
                <div className="checkbox-list">
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Small (<20 lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Small (<20 lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Small (<20 lbs)'}
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Medium (20-50 lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Medium (20-50 lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Medium (20-50 lbs)'}
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Large (50+ lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Large (50+ lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Large (50+ lbs)'}
                  </label>
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <QuizStarIcon />
                  <p className='adoption-quiz-title-text'>Almost done! Finally, which traits matter the most to you?</p>
                </div>
                <div className="onboarding-bubbles">
                  {traits.map((element, index) => (
                    <Bubble
                      title={element.name}
                      index={element.name + index}
                      selected={selectedTraits.includes(element.name)}
                      onClick={() =>
                        handleBubbleClick("traits", {
                          name: element.name,
                          id: element.id,
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="adoption-email-mobile-button-wrapper">
        {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
        {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
        <div className='app-entry-mobile-bar-wrapper'>
          <Button
            title={'Show all dogs'}
            medium
            location={'/discovery'}
          />
          <Button
            title={'Next'}
            filled
            medium
            onClick={() => {
              if(email) {
                navigate(`/quiz-info?email=${email}`)
              } else {
                toast.error("Please enter an email before going to the next page.", { style: { fontWeight: 500 } });
              }
            }}
          />
        </div>
      </div>



      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >

      </Modal>

    </div>
  );
};

export default ApplicationEntryPoint;
