// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import {
  getUserByEmail,
  updateUser
} from "../../service/actions";
import "../QuizXP/quizxp.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';
import InputMask from "react-input-mask";
import { usePlacesWidget } from "react-google-autocomplete";
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";

const ApplicationEntryPoint = (props) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modifier, setModifier] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState("");
  const [user, setUser] = useState();
  const [phone, setPhone] = useState();
  const [location, setLocation] = useState();

  const { ref: locationRef } = usePlacesWidget({
    apiKey: `AIzaSyDMfA1xyYe0k6Ajf9uqkYNyjB3n813mzd0`,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
    options: {
      types: 'locality'
    }
  });

  const navigate = useParamsNavigate();


  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };


  const routerLocation = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const emailParam = queryParams.get('email');


    if(emailParam) {
      setEmail(emailParam);
    }

    datadogLogs.logger.info(
      `User info page loaded after non-application quiz experience.`,
      { url: window.location.href }
    );

// eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const { data } = await getUserByEmail(email);
      const { user, token } = data;
      setUser(user);
      setToken(token);
    };
    if(email)
      getUser();
  }, [email]);

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    if (modifierParam) setModifier(modifierParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (token && (phone || location)) handleUserUpdate();
    }, 500);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [phone, location]);

  const handleUserUpdate = async () => {
    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateUser(email, phone, user.name, location, token);

      // Handle the result as per your requirement
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data));
        console.log("Adoption updated successfully", result);
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };



  return (
    <div className="adoption">
      <div className="short-app-left">
        <div className="adoption-left-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            <Counter max={3} current={3} />
          </div>
          <div>
              <div className="short-adoption-header">
                <span className="adoption-left-title">{"✔ Saved! Let’s get some final details."}</span>
                <span className="adoption-left-description">
                  {"Sharing your town will help us find dogs that fit your preferences near you."}
                </span>
              </div>

              <div className="adoption-quiz-form-container">
                <br />
                <Input ref={locationRef} label='Where do you want to adopt?' required value={location} onChange={(e) => {
                  setLocation(e.target.value)
                }} placeholder='Austin, Texas' />
                <br/>
                <>
                    <InputMask
                      mask="(999) 999-9999"
                      value={phone}
                      onChange={handlePhoneChange}
                    >
                      {(inputProps) => (
                        <Input
                          label={t("user.phoneLabel")}
                          {...inputProps}
                          placeholder="(440) 555-1234"
                          value={phone}
                          onChange={handlePhoneChange}
                          required
                        />
                      )}
                    </InputMask>
                    <p className="adoption-call-disclaimer">By providing your number for us to text, you’ll be the first to know when there’s a new dog in your area.</p>
                    <br />
                  </>
                
              </div>
            </div>
        </div>
      </div>

      <div className="adoption-email-mobile-button-wrapper">
        {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
        {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
        <div className='app-entry-mobile-bar-wrapper'>
          <Button
            title={'Show all dogs'}
            medium
            location={'/discovery'}
          />
          <Button
            title={'Show my matches'}
            filled
            medium
            location={`/discovery`}
          />
        </div>
      </div>


      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >

      </Modal>

    </div>
  );
};

export default ApplicationEntryPoint;
