import React from "react";

const ArrowForward = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58317 11.9166L14.8223 11.9166L10.349 16.3899C9.9915 16.7474 9.9915 17.3341 10.349 17.6916C10.7065 18.0491 11.284 18.0491 11.6415 17.6916L17.6823 11.6508C18.0398 11.2933 18.0398 10.7158 17.6823 10.3583L11.6415 4.31742C11.284 3.95992 10.7065 3.95992 10.349 4.31742C9.9915 4.67492 9.9915 5.25242 10.349 5.60992L14.8223 10.0833L4.58317 10.0833C4.079 10.0833 3.6665 10.4958 3.6665 10.9999C3.6665 11.5041 4.079 11.9166 4.58317 11.9166Z"
      fill="#3B9419"
    />
  </svg>
);

export default ArrowForward;
