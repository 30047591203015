import React from "react";

const Health = (props: any) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_918_5296)">
      <rect x="9" y="9" width="32" height="32" rx="16" fill="white" />
      <g filter="url(#filter1_b_918_5296)">
        <path
          d="M29.4421 17C26.4668 17 25.0002 19.9091 25.0002 19.9091C25.0002 19.9091 23.5336 17 20.5583 17C18.1402 17 16.2254 19.0064 16.2007 21.4004C16.1502 26.37 20.1756 29.904 24.5877 32.874C24.7094 32.9561 24.8531 33 25.0002 33C25.1473 33 25.291 32.9561 25.4127 32.874C29.8244 29.904 33.8497 26.37 33.7997 21.4004C33.775 19.0064 31.8602 17 29.4421 17Z"
          stroke="#46A522"
          strokeWidth="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_918_5296"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_918_5296"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_918_5296"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_918_5296"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_918_5296"
        x="11.4502"
        y="12.25"
        width="27.1001"
        height="25.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_918_5296"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_918_5296"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Health;
