// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import UnlockIcon from "../../assets/svg/UnlockIcon";
import logo from "../../assets/images/deals-logo.png";
import whiteLogo from "../../assets/images/white-logo.png";
import './deals.css';
import BoltIcon from "../../assets/svg/BoltIcon";
import DogIcon from "../../assets/svg/DogIcon";
import EmailButton from "../../assets/svg/EmailButton";
import PhoneButton from "../../assets/svg//PhoneButton";
import { useLocation } from "react-router-dom";
import { useParamsNavigate } from '../../utils';
import Info from "../../assets/svg//Info";
import WhatsAppButtonWhite from "../../assets/svg/WhatsAppButtonWhite";
import { getFirstProduct, checkPurchaseLimit } from '../../service/actions';
import Button from '../../components/Button';
import { Modal } from 'antd';
import supermanDog from "../../assets/images/superman-dog.png";
import { datadogLogs } from "@datadog/browser-logs";
// import DealsLogo from "../../assets/svg/petpair_deals_logo";


/* eslint-disable  */
const Deals = () => {

    const [countdown, setCountdown] = useState('xx:xx:xx');
    const [product, setProduct] = useState();
    // eslint-disable-next-line
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shelter, setShelter] = useState();
    // eslint-disable-next-line
    const [dogName, setDogName] = useState();
    const [email, setEmail] = useState();
    const [canPurchase, setCanPurchase] = useState(true);

    const footerRef = useRef(null);

    const routerLocation = useLocation();
    const navigate = useParamsNavigate();


    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const checkUserPurchaseLimit = async () => {
            try {
                // Assuming you have access to the user's email and product ID
                const { canPurchaseMore } = await checkPurchaseLimit(email, product.id);
                setCanPurchase(canPurchaseMore);
                console.log('Can purchase more:', canPurchaseMore.canPurchaseMore);
                // Do something based on the value of canPurchaseMore
            } catch (error) {
                console.error('Error checking purchase limit:', error);
            }
        };

        if (product && email) {
            checkUserPurchaseLimit();
        }
    }, [product, email]);

    useEffect(() => {
        datadogLogs.logger.info(`Deals page loaded!`, {
            url: window.location.href,
          });
    }, []);



    const queryParams = new URLSearchParams(routerLocation.search);
    useEffect(() => {
        const shelter = queryParams.get('shelter');
        const dogName = queryParams.get('dogName');
        const email = queryParams.get('email');

        if (shelter)
            setShelter(shelter);
        if (dogName)
            setDogName(dogName);
        if (email)
            setEmail(email);


        const calculateCountdown = () => {
            // Get current date and time in UTC
            const nowUTC = new Date();

            // Convert UTC time to CST (UTC-6)
            const nowCST = new Date(nowUTC.toLocaleString("en-US", { timeZone: "America/Chicago" }));

            // Get midnight CST of the next day
            const midnightCST = new Date(nowCST);
            midnightCST.setDate(midnightCST.getDate() + 1);
            midnightCST.setHours(0, 0, 0, 0);

            // Calculate time remaining until midnight CST
            const timeRemaining = midnightCST - nowCST;

            // Convert milliseconds to hours, minutes, and seconds
            const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
            const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
            const seconds = Math.floor((timeRemaining / 1000) % 60);

            // Format the time as HH:MM:SS
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

            setCountdown(formattedTime);
        };


        const timer = setInterval(calculateCountdown, 1000);

        fetchFirstProduct();

        // Cleanup function
        return () => clearInterval(timer);

    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // Adjust this threshold as per your needs
        };

        const handleIntersection = (entries: any) => {
            const footerEntry = entries[0];
            setIsFooterVisible(footerEntry.isIntersecting);
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                // eslint-disable-next-line
                observer.unobserve(footerRef.current);
            }
        };
    }, [product]);

    const fetchFirstProduct = async () => {
        try {
            const product = await getFirstProduct();
            setProduct(product);
            console.log('First product:', product);
        } catch (err) {
            console.error('Error fetching first product:', err);
        }
    };

    if (!product) {
        return;
    }


    return (
        <>
            <div className='deals-page-root'>
                <div className='deals-root'>
                    <div className='deals-container-left'>
                        <div className='deals-header'>
                            <div className='deals-logo-container'>
                                <img className='deals-logo' src={logo} alt='PetPair' />
                            </div>
                            <div className='deals-info-button'>
                                <Button title={<Info />} small onClick={() => setIsModalVisible(true)} />
                            </div>
                        </div>
                        <span className='deals-page-title'>{canPurchase ? 'Your deal of the day' : 'Next deal of the day'}</span>
                        {canPurchase && <span className='deals-product-name'>{product.name}</span>}
                        {!canPurchase && <div className='deals-product-head-section2'>
                            <div className={shelter ? 'deals-benefit-container-shelter' : 'deals-benefit-container1'}>
                                <UnlockIcon />
                                <BoltIcon />
                            </div>
                            <div className='deals-benefit-container2'>
                                <span className='deals-benefit-title'>{shelter ? `${shelter} adopter exclusive price unlocked` : 'Referral price unlocked'}</span>
                                <span className='deals-benefit-title'>New deal in {countdown}</span>
                            </div>
                        </div>}

                        <div className={!canPurchase && 'deals-blurred-content'}>
                            <div className='deals-product-image'>
                                <img className='deals-product-image-object' src={product.images[0]} alt='PetPair Product' />
                            </div>
                        </div>
                        <br />
                    </div>
                    <div className='deals-container-right'>

                    <span className='deals-page-title-desktop'>{canPurchase ? 'Your deal of the day' : 'Next deal of the day'}</span>
                        {canPurchase && <span className='deals-product-name-desktop'>{product.name}</span>}
                    <div className='deals-product-head-section'>
                        <div className={shelter ? 'deals-benefit-container-shelter' : 'deals-benefit-container1'}>
                            <UnlockIcon />
                            <BoltIcon />
                        </div>
                        <div className='deals-benefit-container2'>
                            <span className='deals-benefit-title'>{shelter ? `${shelter} adopter exclusive price unlocked` : 'Referral price unlocked'}</span>
                            <span className='deals-benefit-title'>Expires in {countdown}</span>
                        </div>
                    </div>
                    <br />
                    <div className="deals-price-container">
                        <span className='deals-price'>${product.price}</span>
                        <span className='deals-og-price'>${product.ogPrice} on Chewy</span>
                    </div>
                    <p className='deals-product-description'>
                        <DogIcon fill='green' />  <span className='deals-product-description-bold'>Why Hannah chose this deal: </span> {product.whyThisDeal.replaceAll('{{dogName}}', dogName ? dogName : 'your dog')}
                    </p>
                    <p className='deals-product-description'>
                        <span className='deals-product-description-bold'>More about today’s product: </span><br /><br />

                        {product.description}
                    </p>
                    <p className='deals-product-description'>
                        <span className='deals-product-description-bold'>How PetPair Deals works</span><br /><br />

                        When you adopted instead of going to a breeder, you joined a very special club. Now, you’ll get rewarded for adopting with exclusive deals you won’t find anywhere else. We curate one deal every day in partnership with shelters nationwide to reward adopters like you.
                    </p>
                    </div>
                </div>

            </div>
            {canPurchase && <div className={"deals-mobile-cta"}>
                <div className='deals-meter-container'>
                    <BoltIcon width={7} />
                    <span className='deals-meter'>{`${product.availableUnits} deals left`}</span>
                </div>
                <div
                    className={
                        "deals-mobile-cta-left"
                    }
                >
                    <Button
                        title={'Not right now'}
                        onClick={() => {
                            navigate('/deals/confirm');
                        }}
                        medium
                    />
                    <Button
                        title={'Buy now'}
                        medium
                        filled
                        onClick={() => {
                            navigate(`/product-checkout`);
                        }}
                    />
                </div>
            </div>}
            <div className='deals-footer' ref={footerRef}>
                <span>Contact Us</span>
                <br />
                <div className='deals-contact-buttons'>
                    <a className='deals-footer-button' href='mailto:daniel+deals@petpair.co'><EmailButton /></a>
                    <a className='deals-footer-button' href='sms:+12295866454'><PhoneButton /></a>
                    <a className='deals-footer-button' href='https://wa.me/18325294282'><WhatsAppButtonWhite /></a>
                </div>
            </div>
            <Modal
                visible={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <></>
                ]}
                closable={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0)'
                }}
                wrapClassName="transparent-modal"
            >
                {product && (
                    <div className='deals-modal-content'>
                        <img src={supermanDog} className='deals-modal-image' />
                        <img src={whiteLogo} className='deals-modal-logo' />
                        <p className='deals-modal-text'>
                            At PetPair, we’re all about supporting the entire pet parenting journey. We started as an adoption platform, and now, we scour the web for exclusive deals for your pet you won’t find anywhere else. These deals are only available to adopters and their friends.
                        </p>
                        <br />
                        <div className='deals-modal-button-container'><Button title="Let's go" filled medium onClick={() => setIsModalVisible(false)} /></div>
                    </div>
                )}
            </Modal>
        </>
    )

};

export default Deals;
