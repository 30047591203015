import React from "react";

const CalendarIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 1.66659H10V0.999919C10 0.633252 9.7 0.333252 9.33333 0.333252C8.96667 0.333252 8.66667 0.633252 8.66667 0.999919V1.66659H3.33333V0.999919C3.33333 0.633252 3.03333 0.333252 2.66667 0.333252C2.3 0.333252 2 0.633252 2 0.999919V1.66659H1.33333C0.593333 1.66659 0.00666666 2.26659 0.00666666 2.99992L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99992C12 2.26659 11.4 1.66659 10.6667 1.66659ZM10.6667 11.6666C10.6667 12.0333 10.3667 12.3333 10 12.3333H2C1.63333 12.3333 1.33333 12.0333 1.33333 11.6666V4.99992H10.6667V11.6666ZM2.66667 6.33325H4V7.66659H2.66667V6.33325ZM5.33333 6.33325H6.66667V7.66659H5.33333V6.33325ZM8 6.33325H9.33333V7.66659H8V6.33325Z"
      fill="black"
    />
  </svg>
);

export default CalendarIcon;
