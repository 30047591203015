import React from "react";
import Home from "../pages/Home";
import Onboarding from "../pages/Onboarding";
import DogDiscovery from "../pages/DogDiscovery";
import DogProfile from "../pages/DogProfile";
import ProfileSettings from "../pages/ProfileSettings";
import AdoptionApp from "../pages/AdoptionApp";
import Checkout from "../pages/Checkout";
import CheckoutSuccess from "../pages/CheckoutSuccess";
import CongratsAdoption from "../pages/CongratsAdoption";
import Agreement from "../pages/Agreement";
import FosterAgreement from "../pages/FosterAgreement";
import About from "../pages/About";
import Shelters from "../pages/Shelters";
import Fosters from "../pages/Fosters";
import FacebookApp from "../pages/FacebookApp";
import Application from "../pages/Application";
import NotFound from "../pages/NotFound";
import ShortApp from "../pages/ShortApp";
import News from "../pages/News";
import Article from "../pages/Article";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Sitemap from "../pages/Sitemap";
import ApplicationEntryPoint from "../pages/ApplicationEntryPoint";
import ApplicationSchedule from "../pages/ApplicationSchedule";
import ApplicationEmailInput from "../pages/ApplicationEmailInput";
import QuizXP from "../pages/QuizXP";
import QuizUserInfo from "../pages/QuizUserInfo";
import FosterForm from "../pages/FosterForm";
import ContactForm from "../pages/ContactForm";
import Deals from "../pages/Deals";
import ProductCheckout from "../pages/ProductCheckout";
import ProductCheckoutConfirm from "../pages/ProductCheckoutConfirm";

const routes: any = [
  {
    path: "/",
    element: <Home />,
    title: "Home",
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
    errorElement: <NotFound />
  },
  {
    path: "/discovery",
    element: <DogDiscovery />,
    errorElement: <NotFound />
  },
  {
    path: "/dog/:slug",
    element: <DogProfile />,
    errorElement: <NotFound />
  },
  {
    path: "/doggos/:slug",
    element: <DogProfile />,
    errorElement: <NotFound />
  },
  {
    path: "/profile",
    element: <ProfileSettings />,
    errorElement: <NotFound />
  },
  {
    path: "/work/dogs",
    element: <DogDiscovery />,
    errorElement: <NotFound />
  },
  {
    path: "/adopt/:type/:userId/:dogId",
    element: <AdoptionApp />,
    errorElement: <NotFound />
  },
  {
    path: "/checkout/:adoptionId",
    element: <Checkout />,
    errorElement: <NotFound />
  },
  {
    path: "/checkout/success/:adoptionId",
    element: <CheckoutSuccess />,
    errorElement: <NotFound />
  },
  {
    path: "/congrats",
    element: <CongratsAdoption />,
    errorElement: <NotFound />
  },
  {
    path: "/agreement/:adoptionId",
    element: <Agreement />,
    errorElement: <NotFound />
  },
  {
    path: "/foster-agreement/:adoptionId",
    element: <FosterAgreement />,
    errorElement: <NotFound />
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <NotFound />
  },

  {
    path: "/shelters",
    element: <Shelters />,
    errorElement: <NotFound />
  },
  {
    path: "/fosters",
    element: <Fosters />,
    errorElement: <NotFound />
  },
  {
    path: "/deals/confirm",
    element: <ProductCheckoutConfirm />,
    errorElement: <NotFound />
  },
  {
    path: "/adopt/:dogId/facebook-app",
    element: <FacebookApp />,
    errorElement: <NotFound />
  },
  {
    path: "/fast-app/:dogId",
    element: <Application />,
    errorElement: <NotFound />
  },
  {
    path: "/application/:dogId",
    element: <ApplicationEntryPoint />,
    errorElement: <NotFound />
  },
  {
    path: "/contact-us",
    element: <ContactForm />,
    errorElement: <NotFound />
  },
  {
    path: "/schedule-call/:dogId",
    element: <ApplicationSchedule />,
    errorElement: <NotFound />
  },
  {
    path: "/quiz",
    element: <QuizXP />,
    errorElement: <NotFound />
  },
  {
    path: "/quiz-info",
    element: <QuizUserInfo />,
    errorElement: <NotFound />
  },
  {
    path: "/foster-form",
    element: <FosterForm />,
    errorElement: <NotFound />
  },
  {
    path: "/product-checkout",
    element: <ProductCheckout />,
    errorElement: <NotFound />
  },
  {
    path: "/application/step2/:dogId",
    element: <ApplicationEmailInput />,
    errorElement: <NotFound />
  },
  {
    path: "/application/step3/:dogId",
    element: <ShortApp />,
    errorElement: <NotFound />
  },
  {
    path: "/blog",
    element: <News />,
    errorElement: <NotFound />
  },
  {
    path: "/blog/post/:slug",
    element: <Article />,
    errorElement: <NotFound />
  },
  {
    path: "/terms-of-use",
    element: <Terms />,
    errorElement: <NotFound />
  },
  {
    path: "/sitemap.xml",
    element: <Sitemap />,
    errorElement: <NotFound />
  },
  {
    path: "/deals",
    element: <Deals />,
    errorElement: <NotFound />
  },
  {
    path: "/privacy",
    element: <Privacy />,
    errorElement: <NotFound />
  },
  {
    path: "/*",
    element: <NotFound />,
  },
];

export default routes;
