import React from "react";

const DropdownUpArrow = () => (
  <svg
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89512 6.37102L5.50012 2.97602L2.10512 6.37101C1.76387 6.71226 1.21262 6.71226 0.871367 6.37101C0.530117 6.02976 0.530117 5.47851 0.871367 5.13726L4.88762 1.12102C5.22887 0.779765 5.78012 0.779765 6.12137 1.12102L10.1376 5.13727C10.4789 5.47852 10.4789 6.02977 10.1376 6.37102C9.79637 6.70352 9.23637 6.71227 8.89512 6.37102Z"
      fill="#3B9419"
    />
  </svg>
);

export default DropdownUpArrow;
