import React from "react";
import "./home.css";
import Button from "../../components/Button";
import illustration from "../../assets/images/ladystick.png";
import { useTranslation } from "react-i18next";

const Section4 = () => {
  const { t } = useTranslation();
  return (
    <div className="home-section4">
      <div className="section4-main-content">
        <div className="section4-left">
          <img alt='PetPair' src={illustration} className="section4-img" />
        </div>
        <div className="section4-right">
          <span className="section4-title">{t("home.heading3")}</span>
          <br />
          <br />
          <p className="section4-title2">{t("home.description3")}</p>
          <div className="section3-buttons">
            <Button
              title={t("home.primaryButton3")}
              location="/foster-form"
              filled
            />
            <Button
              title={t("home.secondaryButton3")}
              location="/fosters"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
