// @ts-nocheck

const DogPlate = () => (
  <svg
    width="67"
    height="41"
    viewBox="0 0 67 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.9023 19.4494L62.2636 5.12453C62.2636 5.12453 57.7899 0.679001 38.9024 0.51408C20.015 0.34959 13.8848 5.12453 13.8848 5.12453L9.24558 19.4494C9.24558 19.4494 14.2158 25.8709 38.074 25.706C61.9321 25.5415 66.9023 19.4494 66.9023 19.4494Z"
      fill="#53B62D"
    />
    <path
      d="M62.2634 5.12448C62.2634 5.12448 62.5945 11.2166 38.0738 11.0521C13.5532 10.8872 13.8847 5.12448 13.8847 5.12448C13.8847 5.12448 21.0088 0.176422 38.0738 0.51015C38.0738 0.51015 54.8079 -0.144359 62.2634 5.12448Z"
      fill="#396F00"
    />
    <path
      d="M18.2753 5.76951C18.2753 7.67949 27.1397 9.22723 38.0742 9.22723C49.0091 9.22723 57.873 7.67949 57.873 5.76951C57.873 3.85997 49.0091 2.31177 38.0742 2.31177C27.1397 2.31177 18.2753 3.85997 18.2753 5.76951Z"
      fill="#CE2824"
    />
    <path
      d="M64.4948 12.0149C64.4948 12.0149 53.9795 30.8103 9.24558 19.4493C9.24558 19.4493 16.1047 27.5175 48.2139 25.212C48.2139 25.212 62.2636 23.8948 66.9023 19.4493L64.4948 12.0149Z"
      fill="#46A522"
    />
    <path
      d="M56.8691 6.85569C56.8691 6.85569 40.6582 1.81611 19.2767 6.85569C19.2767 6.85569 22.9962 9.22719 37.7415 9.22719C37.7415 9.22719 52.3046 9.26691 56.8691 6.85569Z"
      fill="#A0D2C7"
    />
    <path
      d="M52.9815 5.94092C52.9815 5.94092 49.6738 8.90778 32.2755 9.10508C32.2755 9.10508 50.8133 9.92536 56.8691 6.85576L52.9815 5.94092Z"
      fill="#8CB8AE"
    />
    <mask
      id="mask0_519_24313"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="17"
      y="-6"
      width="42"
      height="16"
    >
      <path
        d="M17.6934 5.4393C17.6934 3.19802 26.775 -5.14941 37.9748 -5.14941C49.1747 -5.14941 58.2563 3.19802 58.2563 5.4393C58.2563 7.68058 49.1747 9.49831 37.9748 9.49831C26.775 9.49831 17.6934 7.68058 17.6934 5.4393Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_519_24313)">
      <path
        d="M17.6934 5.43919C17.6934 3.19792 26.775 1.38019 37.9748 1.38019C49.1747 1.38019 58.2563 3.19792 58.2563 5.43919C58.2563 7.68047 49.1747 9.4982 37.9748 9.4982C26.775 9.4982 17.6934 7.68047 17.6934 5.43919Z"
        fill="url(#paint0_linear_519_24313)"
      />
      <path
        d="M37.5428 3.40973C37.5428 4.09799 36.5364 4.64508 35.3076 4.64508C34.0787 4.64508 33.0723 4.09799 33.0723 3.40973C33.0723 2.72146 34.0787 2.17438 35.3076 2.17438C36.5364 2.17438 37.5428 2.72146 37.5428 3.40973Z"
        fill="url(#paint1_linear_519_24313)"
      />
      <path
        d="M29.1737 8.33356C29.1737 9.02182 28.1672 9.56891 26.9384 9.56891C25.7096 9.56891 24.7031 9.02182 24.7031 8.33356C24.7031 7.64529 25.7096 7.09821 26.9384 7.09821C28.1672 7.09821 29.1737 7.64529 29.1737 8.33356Z"
        fill="url(#paint2_linear_519_24313)"
      />
      <path
        d="M23.0038 8.05115C22.7698 8.66882 21.6697 8.65117 20.5462 7.9982C19.4227 7.36288 18.7088 6.3393 18.9429 5.72163C19.1769 5.10395 20.277 5.1216 21.4005 5.77457C22.524 6.40989 23.2379 7.43347 23.0038 8.05115Z"
        fill="url(#paint3_linear_519_24313)"
      />
      <path
        d="M33.0233 6.67462C33.0233 7.36289 32.0168 7.90997 30.788 7.90997C29.5592 7.90997 28.5527 7.36289 28.5527 6.67462C28.5527 5.98635 29.5592 5.43927 30.788 5.43927C32.0168 5.43927 33.0233 5.98635 33.0233 6.67462Z"
        fill="url(#paint4_linear_519_24313)"
      />
      <path
        d="M27.8514 5.43927C27.8514 6.12753 26.845 6.67462 25.6161 6.67462C24.3873 6.67462 23.3809 6.12753 23.3809 5.43927C23.3809 4.751 24.3873 4.20392 25.6161 4.20392C26.845 4.20392 27.8514 4.751 27.8514 5.43927Z"
        fill="url(#paint5_linear_519_24313)"
      />
      <path
        d="M42.4909 8.54529C42.3973 9.21591 41.3323 9.55122 40.1152 9.30415C38.8981 9.05708 37.9852 8.29822 38.0789 7.6276C38.1725 6.95698 39.2375 6.62167 40.4546 6.86874C41.6717 7.11581 42.5846 7.87467 42.4909 8.54529Z"
        fill="url(#paint6_linear_519_24313)"
      />
      <path
        d="M28.5543 3.00389C28.6011 3.67451 27.6414 4.32748 26.4009 4.45102C25.1721 4.57455 24.1305 4.11571 24.0837 3.44509C24.0369 2.77447 24.9965 2.1215 26.237 1.99796C27.4659 1.87443 28.5074 2.33327 28.5543 3.00389Z"
        fill="url(#paint7_linear_519_24313)"
      />
      <path
        d="M35.3757 4.82154C35.2821 5.49215 34.2171 5.82746 33 5.58039C31.7829 5.33332 30.87 4.57447 30.9636 3.90385C31.0573 3.23323 32.1222 2.89792 33.3394 3.14499C34.5565 3.39206 35.4693 4.15092 35.3757 4.82154Z"
        fill="url(#paint8_linear_519_24313)"
      />
      <path
        d="M35.073 9.05713C34.9793 9.72775 33.9144 10.0631 32.6972 9.81599C31.4801 9.56892 30.5673 8.81006 30.6609 8.13944C30.7545 7.46882 31.8195 7.13351 33.0366 7.38058C34.2537 7.62765 35.1666 8.38651 35.073 9.05713Z"
        fill="url(#paint9_linear_519_24313)"
      />
      <path
        d="M22.2571 4.08042C22.5263 4.68044 21.8826 5.75696 20.8059 6.49817C19.7292 7.23938 18.6291 7.36292 18.36 6.78054C18.0791 6.18051 18.7345 5.10399 19.8112 4.36278C20.8878 3.62157 21.9879 3.49804 22.2571 4.08042Z"
        fill="url(#paint10_linear_519_24313)"
      />
      <path
        d="M30.6609 2.93319C30.7546 3.60381 29.8534 4.36267 28.6246 4.62738C27.4075 4.8921 26.3425 4.55679 26.2489 3.88617C26.1552 3.21556 27.0564 2.4567 28.2852 2.19198C29.5023 1.92726 30.5673 2.26257 30.6609 2.93319Z"
        fill="url(#paint11_linear_519_24313)"
      />
      <path
        d="M24.5164 3.56863C24.61 4.23925 23.7089 4.9981 22.4801 5.26282C21.2629 5.52754 20.198 5.19223 20.1043 4.52161C20.0107 3.85099 20.9118 3.09214 22.1407 2.82742C23.3695 2.5627 24.4228 2.89801 24.5164 3.56863Z"
        fill="url(#paint12_linear_519_24313)"
      />
      <path
        d="M23.5922 7.39821C23.3347 7.99823 22.2346 7.92764 21.1345 7.22173C20.0344 6.51582 19.3556 5.45694 19.6131 4.85692C19.8706 4.25689 20.9707 4.32748 22.0708 5.0334C23.1709 5.73931 23.8496 6.79818 23.5922 7.39821Z"
        fill="url(#paint13_linear_519_24313)"
      />
      <path
        d="M27.607 3.48034C27.7474 4.13331 26.8931 4.98041 25.6876 5.36866C24.4939 5.73926 23.4055 5.52749 23.2651 4.85687C23.1247 4.18625 23.979 3.3568 25.1844 2.96855C26.3781 2.59795 27.4665 2.80972 27.607 3.48034Z"
        fill="url(#paint14_linear_519_24313)"
      />
      <path
        d="M26.0034 4.9627C26.1439 5.61567 25.2895 6.46277 24.0841 6.85102C22.8904 7.22162 21.802 7.00985 21.6616 6.33923C21.5212 5.68626 22.3755 4.83916 23.5809 4.45091C24.7746 4.08031 25.863 4.29208 26.0034 4.9627Z"
        fill="url(#paint15_linear_519_24313)"
      />
      <path
        d="M33.5383 1.41563C33.8777 1.96271 33.3393 3.14511 32.3446 4.04516C31.3498 4.9452 30.2848 5.24521 29.9455 4.69813C29.6061 4.15104 30.1444 2.96864 31.1392 2.0686C32.1339 1.16856 33.1989 0.868542 33.5383 1.41563Z"
        fill="url(#paint16_linear_519_24313)"
      />
      <path
        d="M38.5588 4.22162C38.7694 4.85695 38.0087 5.82758 36.8618 6.39231C35.7149 6.95704 34.6148 6.92174 34.4042 6.28642C34.1935 5.6511 34.9542 4.68047 36.1011 4.11574C37.248 3.551 38.3481 3.5863 38.5588 4.22162Z"
        fill="url(#paint17_linear_519_24313)"
      />
      <path
        d="M40.4202 5.38635C40.268 6.03932 39.1796 6.23345 37.9859 5.82755C36.7922 5.42165 35.9496 4.5569 36.1017 3.88629C36.2539 3.23332 37.3423 3.03919 38.536 3.44509C39.7297 3.85099 40.5723 4.71574 40.4202 5.38635Z"
        fill="url(#paint18_linear_519_24313)"
      />
      <path
        d="M33.1766 3.09205C33.0479 3.76267 31.9712 4.00974 30.7658 3.67443C29.5604 3.32147 28.6826 2.50967 28.8114 1.83905C28.9401 1.16843 30.0168 0.921364 31.2222 1.25667C32.4276 1.60963 33.3053 2.42143 33.1766 3.09205Z"
        fill="url(#paint19_linear_519_24313)"
      />
      <path
        d="M38.7938 9.163C38.7587 9.85126 37.7405 10.3278 36.5 10.2395C35.2594 10.1513 34.2881 9.5336 34.3232 8.84533C34.3583 8.15707 35.3765 7.68058 36.617 7.76882C37.8575 7.85706 38.8289 8.47473 38.7938 9.163Z"
        fill="url(#paint20_linear_519_24313)"
      />
      <path
        d="M37.1317 6.03928C37.0965 6.72754 36.0784 7.20404 34.8379 7.1158C33.5973 7.02756 32.626 6.40988 32.6611 5.72162C32.6962 5.03335 33.7144 4.55686 34.9549 4.6451C36.1954 4.73334 37.1668 5.35101 37.1317 6.03928Z"
        fill="url(#paint21_linear_519_24313)"
      />
      <path
        d="M22.3367 5.15683C22.3016 5.8451 21.2835 6.32159 20.0429 6.23335C18.8024 6.14511 17.831 5.52744 17.8662 4.83917C17.9013 4.1509 18.9194 3.67441 20.16 3.76265C21.4005 3.85089 22.3718 4.46857 22.3367 5.15683Z"
        fill="url(#paint22_linear_519_24313)"
      />
      <path
        d="M26.2137 6.83341C26.1786 7.52167 25.1604 7.99817 23.9199 7.90993C22.6794 7.82169 21.708 7.20401 21.7431 6.51575C21.7782 5.82748 22.7964 5.35099 24.0369 5.43923C25.2774 5.52747 26.2488 6.14514 26.2137 6.83341Z"
        fill="url(#paint23_linear_519_24313)"
      />
      <path
        d="M32.2293 8.03342C32.1942 8.72169 31.176 9.19818 29.9355 9.10994C28.695 9.0217 27.7236 8.40402 27.7587 7.71576C27.7938 7.02749 28.812 6.551 30.0525 6.63924C31.2931 6.72748 32.2644 7.34515 32.2293 8.03342Z"
        fill="url(#paint24_linear_519_24313)"
      />
      <path
        d="M30.66 4.75091C30.8472 5.38623 30.0397 6.32157 28.8694 6.81571C27.6991 7.30985 26.6107 7.18631 26.4235 6.55099C26.2362 5.91567 27.0437 4.98033 28.214 4.48619C29.3844 3.99205 30.4727 4.11559 30.66 4.75091Z"
        fill="url(#paint25_linear_519_24313)"
      />
      <path
        d="M26.0858 6.74517C26.273 7.38049 25.4655 8.31583 24.2952 8.80997C23.1249 9.30411 22.0365 9.18058 21.8493 8.54525C21.662 7.90993 22.4695 6.97459 23.6398 6.48045C24.8101 5.98631 25.8985 6.10985 26.0858 6.74517Z"
        fill="url(#paint26_linear_519_24313)"
      />
      <path
        d="M38.5955 6.35693C38.7828 6.99225 37.9753 7.92759 36.805 8.42173C35.6347 8.91587 34.5463 8.79233 34.359 8.15701C34.1718 7.52169 34.9793 6.58635 36.1496 6.09221C37.3199 5.59807 38.4083 5.7216 38.5955 6.35693Z"
        fill="url(#paint27_linear_519_24313)"
      />
      <path
        d="M36.6404 2.10384C36.7106 2.77446 35.7861 3.49802 34.5572 3.69215C33.3284 3.90392 32.2751 3.51567 32.2049 2.84505C32.1347 2.17443 33.0592 1.45087 34.2881 1.25674C35.5169 1.06262 36.5702 1.43322 36.6404 2.10384Z"
        fill="url(#paint28_linear_519_24313)"
      />
      <path
        d="M40.0564 2.77443C39.8809 3.4274 38.7925 3.56858 37.6105 3.10974C36.4284 2.6509 35.6209 1.73321 35.7848 1.09789C35.9603 0.444915 37.0487 0.303732 38.2307 0.762576C39.4127 1.22142 40.2202 2.13911 40.0564 2.77443Z"
        fill="url(#paint29_linear_519_24313)"
      />
      <path
        d="M35.0703 7.30998C35.082 7.99825 34.0872 8.56298 32.8584 8.59827C31.6296 8.63357 30.6114 8.10413 30.5997 7.43352C30.588 6.74525 31.5828 6.18052 32.8116 6.14522C34.0521 6.10993 35.0586 6.63936 35.0703 7.30998Z"
        fill="url(#paint30_linear_519_24313)"
      />
      <path
        d="M34.6855 5.36857C34.6972 6.05684 33.7025 6.62157 32.4736 6.65687C31.2448 6.69216 30.2266 6.16273 30.2149 5.49211C30.2032 4.80384 31.198 4.23911 32.4268 4.20382C33.6674 4.16852 34.6738 4.69796 34.6855 5.36857Z"
        fill="url(#paint31_linear_519_24313)"
      />
      <path
        d="M28.3202 7.36285C28.3202 8.05112 27.3137 8.5982 26.0849 8.5982C24.8561 8.5982 23.8496 8.05112 23.8496 7.36285C23.8496 6.67459 24.8561 6.1275 26.0849 6.1275C27.3137 6.1275 28.3202 6.67459 28.3202 7.36285Z"
        fill="url(#paint32_linear_519_24313)"
      />
      <path
        d="M38.0098 3.46271C38.0098 4.15097 39.0162 4.69806 40.2451 4.69806C41.4739 4.69806 42.4803 4.15097 42.4803 3.46271C42.4803 2.77444 41.4739 2.22736 40.2451 2.22736C39.0162 2.22736 38.0098 2.77444 38.0098 3.46271Z"
        fill="url(#paint33_linear_519_24313)"
      />
      <path
        d="M46.377 8.40411C46.377 9.09238 47.3834 9.63946 48.6122 9.63946C49.8411 9.63946 50.8475 9.09238 50.8475 8.40411C50.8475 7.71585 49.8411 7.16876 48.6122 7.16876C47.3834 7.16876 46.377 7.71585 46.377 8.40411Z"
        fill="url(#paint34_linear_519_24313)"
      />
      <path
        d="M52.5444 8.1217C52.7785 8.73938 53.8786 8.72173 55.0021 8.06876C56.1256 7.43343 56.8394 6.40986 56.6054 5.79218C56.3713 5.17451 55.2712 5.19216 54.1477 5.84513C53.0242 6.48045 52.3104 7.50403 52.5444 8.1217Z"
        fill="url(#paint35_linear_519_24313)"
      />
      <path
        d="M42.5273 6.72754C42.5273 7.4158 43.5338 7.96289 44.7626 7.96289C45.9915 7.96289 46.9979 7.4158 46.9979 6.72754C46.9979 6.03927 45.9915 5.49219 44.7626 5.49219C43.5338 5.49219 42.5273 6.03927 42.5273 6.72754Z"
        fill="url(#paint36_linear_519_24313)"
      />
      <path
        d="M47.7012 5.50982C47.7012 6.19809 48.7076 6.74517 49.9365 6.74517C51.1653 6.74517 52.1718 6.19809 52.1718 5.50982C52.1718 4.82156 51.1653 4.27448 49.9365 4.27448C48.7076 4.27448 47.7012 4.82156 47.7012 5.50982Z"
        fill="url(#paint37_linear_519_24313)"
      />
      <path
        d="M33.0593 8.61591C33.153 9.28653 34.2179 9.62184 35.4351 9.37477C36.6522 9.1277 37.565 8.36884 37.4714 7.69822C37.3778 7.0276 36.3128 6.69229 35.0957 6.93936C33.8786 7.18643 32.9657 7.94529 33.0593 8.61591Z"
        fill="url(#paint38_linear_519_24313)"
      />
      <path
        d="M46.9977 3.07445C46.9509 3.74507 47.9106 4.39804 49.1511 4.52157C50.3799 4.64511 51.4215 4.18626 51.4683 3.51564C51.5151 2.84503 50.5555 2.19206 49.3149 2.06852C48.0861 1.94499 47.0445 2.40383 46.9977 3.07445Z"
        fill="url(#paint39_linear_519_24313)"
      />
      <path
        d="M40.1765 4.89209C40.2702 5.56271 41.3351 5.89802 42.5523 5.65095C43.7694 5.40388 44.6822 4.64502 44.5886 3.9744C44.495 3.30379 43.43 2.96848 42.2129 3.21555C40.9957 3.46262 40.0829 4.22147 40.1765 4.89209Z"
        fill="url(#paint40_linear_519_24313)"
      />
      <path
        d="M40.4812 9.12769C40.5748 9.79831 41.6398 10.1336 42.8569 9.88655C44.0741 9.63948 44.9869 8.88062 44.8933 8.21C44.7997 7.53938 43.7347 7.20407 42.5176 7.45114C41.3004 7.69821 40.3876 8.45707 40.4812 9.12769Z"
        fill="url(#paint41_linear_519_24313)"
      />
      <path
        d="M53.2955 4.15103C53.0146 4.75106 53.67 5.82758 54.7467 6.56879C55.8234 7.31 56.9235 7.43353 57.1927 6.85116C57.4618 6.25113 56.8182 5.17461 55.7415 4.4334C54.6648 3.69219 53.5647 3.56865 53.2955 4.15103Z"
        fill="url(#paint42_linear_519_24313)"
      />
      <path
        d="M45.9344 1.45089C45.8408 2.12151 46.7419 2.88037 47.9708 3.14508C49.1879 3.4098 50.2529 3.07449 50.3465 2.40388C50.4401 1.73326 49.539 0.974398 48.3101 0.709681C47.0813 0.444963 46.028 0.780272 45.9344 1.45089Z"
        fill="url(#paint43_linear_519_24313)"
      />
      <path
        d="M44.8914 3.00387C44.7978 3.67449 45.699 4.43335 46.9278 4.69806C48.1449 4.96278 49.2099 4.62747 49.3035 3.95685C49.3971 3.28623 48.496 2.52738 47.2672 2.26266C46.0501 1.99794 44.9851 2.33325 44.8914 3.00387Z"
        fill="url(#paint44_linear_519_24313)"
      />
      <path
        d="M51.036 3.63918C50.9424 4.3098 51.8435 5.06866 53.0723 5.33338C54.2894 5.5981 55.3544 5.26279 55.448 4.59217C55.5417 3.92155 54.6405 3.16269 53.4117 2.89797C52.1946 2.63326 51.1296 2.96857 51.036 3.63918Z"
        fill="url(#paint45_linear_519_24313)"
      />
      <path
        d="M51.9608 7.46876C52.2182 8.06879 53.3183 7.9982 54.4184 7.29229C55.5185 6.58637 56.1973 5.5275 55.9398 4.92747C55.6824 4.32745 54.5823 4.39804 53.4822 5.10395C52.3821 5.80987 51.7033 6.86874 51.9608 7.46876Z"
        fill="url(#paint46_linear_519_24313)"
      />
      <path
        d="M47.9467 3.5509C47.8063 4.20387 48.6606 5.05096 49.8661 5.43922C51.0598 5.80982 52.1482 5.59805 52.2886 4.92743C52.429 4.25681 51.5747 3.42736 50.3693 3.03911C49.1756 2.6685 48.0872 2.88028 47.9467 3.5509Z"
        fill="url(#paint47_linear_519_24313)"
      />
      <path
        d="M49.5503 5.03332C49.4098 5.68629 50.2642 6.53338 51.4696 6.92164C52.6633 7.29224 53.7517 7.08047 53.8921 6.40985C54.0325 5.75688 53.1782 4.90978 51.9728 4.52153C50.7791 4.15092 49.6907 4.3627 49.5503 5.03332Z"
        fill="url(#paint48_linear_519_24313)"
      />
      <path
        d="M50.6967 2.17437C50.5563 2.82734 51.4106 3.67444 52.6161 4.06269C53.8098 4.43329 54.8982 4.22152 55.0386 3.5509C55.179 2.89793 54.3247 2.05083 53.1193 1.66258C51.9256 1.29198 50.8372 1.50375 50.6967 2.17437Z"
        fill="url(#paint49_linear_519_24313)"
      />
      <path
        d="M42.0119 1.48612C41.6725 2.0332 42.2108 3.21561 43.2056 4.11565C44.2003 5.01569 45.2653 5.3157 45.6047 4.76862C45.9441 4.22154 45.4058 3.03913 44.411 2.13909C43.4162 1.23905 42.3512 0.939038 42.0119 1.48612Z"
        fill="url(#paint50_linear_519_24313)"
      />
      <path
        d="M36.9921 4.27448C36.7814 4.9098 37.5421 5.88043 38.689 6.44517C39.8359 7.0099 40.936 6.9746 41.1467 6.33928C41.3573 5.70396 40.5966 4.73332 39.4497 4.16859C38.3028 3.60386 37.2027 3.63916 36.9921 4.27448Z"
        fill="url(#paint51_linear_519_24313)"
      />
      <path
        d="M35.131 5.45691C35.2832 6.10988 36.3716 6.30401 37.5653 5.89811C38.759 5.49221 39.6016 4.62746 39.4495 3.95684C39.2973 3.30387 38.2089 3.10975 37.0152 3.51565C35.8215 3.92155 34.9789 4.78629 35.131 5.45691Z"
        fill="url(#paint52_linear_519_24313)"
      />
      <path
        d="M42.3758 3.16267C42.5045 3.83329 43.5812 4.08036 44.7867 3.74505C45.9921 3.39209 46.8698 2.58029 46.7411 1.90967C46.6123 1.23905 45.5356 0.991982 44.3302 1.32729C43.1248 1.68025 42.2471 2.49205 42.3758 3.16267Z"
        fill="url(#paint53_linear_519_24313)"
      />
      <path
        d="M36.7587 9.23349C36.7938 9.92176 37.812 10.3983 39.0525 10.31C40.2931 10.2218 41.2644 9.6041 41.2293 8.91583C41.1942 8.22756 40.176 7.75107 38.9355 7.83931C37.695 7.92755 36.7236 8.54523 36.7587 9.23349Z"
        fill="url(#paint54_linear_519_24313)"
      />
      <path
        d="M38.4189 6.10984C38.454 6.7981 39.4722 7.27459 40.7127 7.18635C41.9532 7.09811 42.9246 6.48044 42.8895 5.79217C42.8544 5.10391 41.8362 4.62742 40.5957 4.71565C39.3551 4.80389 38.3838 5.42157 38.4189 6.10984Z"
        fill="url(#paint55_linear_519_24313)"
      />
      <path
        d="M53.2119 5.22751C53.247 5.91578 54.2651 6.39227 55.5057 6.30403C56.7462 6.21579 57.7175 5.59812 57.6824 4.90985C57.6473 4.22158 56.6292 3.74509 55.3886 3.83333C54.1481 3.92157 53.1768 4.53924 53.2119 5.22751Z"
        fill="url(#paint56_linear_519_24313)"
      />
      <path
        d="M49.3388 6.88639C49.3739 7.57465 50.3921 8.05114 51.6326 7.9629C52.8731 7.87466 53.8445 7.25699 53.8094 6.56872C53.7743 5.88046 52.7561 5.40397 51.5156 5.4922C50.2751 5.58044 49.3037 6.19812 49.3388 6.88639Z"
        fill="url(#paint57_linear_519_24313)"
      />
      <path
        d="M43.3232 8.10404C43.3583 8.7923 44.3765 9.2688 45.617 9.18056C46.8575 9.09232 47.8289 8.47464 47.7938 7.78638C47.7587 7.09811 46.7288 6.62162 45.5 6.70986C44.2594 6.7981 43.2881 7.41577 43.3232 8.10404Z"
        fill="url(#paint58_linear_519_24313)"
      />
      <path
        d="M44.8922 4.82159C44.705 5.45691 45.5125 6.39225 46.6828 6.88639C47.8531 7.38053 48.9415 7.25699 49.1287 6.62167C49.316 5.98635 48.5085 5.05101 47.3382 4.55687C46.1679 4.06273 45.0795 4.18627 44.8922 4.82159Z"
        fill="url(#paint59_linear_519_24313)"
      />
      <path
        d="M49.4684 6.81585C49.2811 7.45117 50.0887 8.38651 51.259 8.88065C52.4293 9.37479 53.5177 9.25125 53.7049 8.61593C53.8922 7.98061 53.0846 7.04527 51.9143 6.55113C50.744 6.05699 49.6556 6.18053 49.4684 6.81585Z"
        fill="url(#paint60_linear_519_24313)"
      />
      <path
        d="M36.9586 6.42748C36.7714 7.06281 37.5789 7.99814 38.7492 8.49228C39.9195 8.98642 41.0079 8.86289 41.1951 8.22757C41.3824 7.59224 40.5749 6.65691 39.4046 6.16277C38.2343 5.66863 37.1459 5.79216 36.9586 6.42748Z"
        fill="url(#paint61_linear_519_24313)"
      />
      <path
        d="M38.9139 2.15676C38.8437 2.82737 39.7682 3.55094 40.9971 3.74506C42.2259 3.95684 43.2792 3.56858 43.3494 2.89797C43.4196 2.22735 42.4951 1.50379 41.2662 1.30966C40.0374 1.11553 38.9841 1.48614 38.9139 2.15676Z"
        fill="url(#paint62_linear_519_24313)"
      />
      <path
        d="M35.4938 2.84499C35.6693 3.49796 36.7577 3.63914 37.9397 3.1803C39.1217 2.72145 39.9292 1.80376 39.7654 1.16844C39.5898 0.515471 38.5015 0.374289 37.3194 0.833133C36.1374 1.29198 35.3299 2.20967 35.4938 2.84499Z"
        fill="url(#paint63_linear_519_24313)"
      />
      <path
        d="M40.4669 7.36284C40.4552 8.0511 41.45 8.61584 42.6788 8.65113C43.9076 8.68643 44.9258 8.15699 44.9375 7.48637C44.9492 6.79811 43.9544 6.23337 42.7256 6.19808C41.4968 6.16278 40.4786 6.69222 40.4669 7.36284Z"
        fill="url(#paint64_linear_519_24313)"
      />
      <path
        d="M40.8653 5.43925C40.8536 6.12752 41.8484 6.69225 43.0772 6.72755C44.306 6.76284 45.3242 6.23341 45.3359 5.56279C45.3476 4.87452 44.3529 4.30979 43.124 4.27449C41.8952 4.2392 40.877 4.76863 40.8653 5.43925Z"
        fill="url(#paint65_linear_519_24313)"
      />
      <path
        d="M47.2324 7.43347C47.2324 8.12174 48.2389 8.66882 49.4677 8.66882C50.6965 8.66882 51.703 8.12174 51.703 7.43347C51.703 6.7452 50.6965 6.19812 49.4677 6.19812C48.2389 6.19812 47.2324 6.7452 47.2324 7.43347Z"
        fill="url(#paint66_linear_519_24313)"
      />
    </g>
    <path
      d="M0.461414 25.8956C0.628613 26.3652 0.868748 26.8081 1.17113 27.203C1.78122 27.9998 2.64923 28.6082 3.68977 28.8945C3.8125 28.9283 3.93702 28.9568 4.05797 28.9799L4.05618 28.9942C6.23154 29.59 7.75591 28.6473 9.34074 27.806C11.043 26.9024 12.6011 26.4417 14.5559 26.6836L37.7937 33.0354C39.5992 33.8234 40.7054 35.0133 41.712 36.6568C42.6494 38.1865 43.4823 39.7749 45.6579 40.369L45.6627 40.3548C45.7799 40.3957 45.9005 40.4348 46.0227 40.4686C48.1162 41.0414 50.2644 40.1235 51.3384 38.3591C51.4076 38.247 51.4703 38.1332 51.5298 38.014C51.6697 37.7365 51.7822 37.4448 51.8675 37.1335C52.5605 34.6007 51.0668 31.9842 48.5326 31.2905C51.0668 31.9842 53.6843 30.4901 54.3773 27.9554C55.0703 25.4207 53.5767 22.8043 51.0426 22.1123C50.9188 22.0785 50.7966 22.0501 50.6728 22.0287L50.6776 22.0127C48.5006 21.4168 46.9763 22.3596 45.3909 23.2009C43.6881 24.1045 42.1318 24.5652 40.1748 24.3233L16.9376 17.9715C15.1322 17.1835 14.0259 15.9936 13.0191 14.35C12.0817 12.8203 11.2511 11.232 9.07393 10.6379L9.0686 10.6539C8.9512 10.6112 8.83202 10.5721 8.70929 10.5383C6.17463 9.84457 3.55636 11.3369 2.86444 13.8733C2.17075 16.4062 3.66486 19.0245 6.19952 19.7182C3.66486 19.0245 1.04838 20.5168 0.356464 23.0515C0.0878792 24.0262 0.144806 25.0116 0.461414 25.8956Z"
      fill="#F6A70D"
    />
    <path
      d="M46.1556 39.4744C46.1857 39.4744 46.2163 39.4673 46.2435 39.4495C46.3179 39.3997 46.3388 39.3001 46.2901 39.2254C44.7426 36.8544 43.1425 34.4015 40.9663 32.4841C37.2744 29.2291 32.4651 27.8897 27.0187 26.5895C26.4228 26.4472 25.8216 26.2995 25.2186 26.1519C20.4588 24.9868 15.5336 23.7827 10.7222 24.4034C9.80434 24.5208 9.00213 24.7254 8.22662 24.921C6.423 25.3799 4.71722 25.8122 1.84105 25.09C1.75567 25.0687 1.66852 25.1202 1.64718 25.2056C1.62405 25.2928 1.67742 25.3799 1.76279 25.4013C4.71901 26.1448 6.46035 25.7019 8.30666 25.2323C9.0715 25.0384 9.86481 24.8374 10.7631 24.7218C15.5158 24.1082 20.4108 25.307 25.1421 26.4649C25.7469 26.6126 26.3463 26.7602 26.944 26.9025C32.3477 28.1921 37.1173 29.519 40.7535 32.7242C42.8971 34.6132 44.4851 37.0483 46.021 39.4015C46.0516 39.4495 46.1032 39.4744 46.1556 39.4744Z"
      fill="#F2E7D1"
    />
    <path
      d="M52.3801 27.7402L52.398 27.7385C52.4863 27.7296 52.55 27.6495 52.54 27.5624C52.4029 26.3404 51.8504 25.3123 51.0247 24.7431C49.5713 23.7399 47.6674 24.2362 45.9874 24.6737L45.7565 24.7324C39.5428 26.3422 32.4014 25.8459 23.2838 23.1725C20.3738 22.3205 17.069 21.2141 14.6819 18.8734C13.9349 18.1423 13.2857 17.2921 12.6578 16.4703C12.3198 16.0274 11.9712 15.5703 11.6101 15.1345C10.2939 13.5479 9.05589 12.6105 7.71651 12.1854C7.63114 12.1587 7.54219 12.2068 7.51374 12.2904C7.48706 12.3758 7.53509 12.4665 7.61869 12.4931C8.89758 12.8987 10.0875 13.8023 11.3629 15.3391C11.7204 15.7695 12.0672 16.2249 12.4034 16.6642C13.0366 17.4966 13.693 18.3558 14.456 19.1028C16.8982 21.4952 20.2457 22.6176 23.1931 23.482C32.3694 26.1714 39.5641 26.6677 45.8371 25.0437L46.0685 24.985C47.6822 24.5635 49.5105 24.0885 50.8418 25.0064C51.591 25.524 52.0935 26.4685 52.2203 27.5979C52.2296 27.6798 52.2993 27.7402 52.3801 27.7402Z"
      fill="#F2E7D1"
    />
    <defs>
      <linearGradient
        id="paint0_linear_519_24313"
        x1="37.9748"
        y1="7.59223"
        x2="37.9748"
        y2="-6.72018"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.29" stop-color="#555A8C" />
        <stop offset="0.58" stop-color="#65699D" />
        <stop offset="1" stop-color="#7B7FB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_519_24313"
        x1="35.132"
        y1="5.84513"
        x2="35.8672"
        y2="1.14393"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_519_24313"
        x1="26.7629"
        y1="10.769"
        x2="27.498"
        y2="6.06776"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_519_24313"
        x1="20.0469"
        y1="9.21834"
        x2="23.1487"
        y2="5.82851"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_519_24313"
        x1="30.6125"
        y1="9.11002"
        x2="31.3477"
        y2="4.40883"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_519_24313"
        x1="25.4406"
        y1="7.87467"
        x2="26.2013"
        y2="3.17799"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_519_24313"
        x1="39.8149"
        y1="10.5296"
        x2="41.6741"
        y2="6.15665"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_519_24313"
        x1="26.2819"
        y1="5.65698"
        x2="26.4509"
        y2="0.923873"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_519_24313"
        x1="32.7075"
        y1="6.81353"
        x2="34.5667"
        y2="2.44057"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_519_24313"
        x1="32.3987"
        y1="11.0482"
        x2="34.2579"
        y2="6.67521"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_519_24313"
        x1="20.9567"
        y1="7.68939"
        x2="18.5433"
        y2="4.0076"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_519_24313"
        x1="28.5661"
        y1="5.81555"
        x2="28.0819"
        y2="1.15197"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_519_24313"
        x1="22.4144"
        y1="6.46073"
        x2="21.9302"
        y2="1.79715"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_519_24313"
        x1="20.5864"
        y1="8.43306"
        x2="23.8251"
        y2="5.20158"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_519_24313"
        x1="25.6666"
        y1="6.55436"
        x2="24.6428"
        y2="2.01241"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_519_24313"
        x1="24.0737"
        y1="8.0509"
        x2="23.0499"
        y2="3.50894"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_519_24313"
        x1="32.6005"
        y1="5.24526"
        x2="29.7501"
        y2="2.05684"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_519_24313"
        x1="36.9332"
        y1="7.59085"
        x2="35.1203"
        y2="3.45116"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_519_24313"
        x1="37.6266"
        y1="7.07359"
        x2="40.0567"
        y2="3.0337"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_519_24313"
        x1="30.4276"
        y1="4.90401"
        x2="32.6293"
        y2="0.725418"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_519_24313"
        x1="36.2974"
        y1="11.4429"
        x2="37.4451"
        y2="6.81754"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_519_24313"
        x1="34.622"
        y1="8.32839"
        x2="35.7696"
        y2="3.70303"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_519_24313"
        x1="19.8366"
        y1="7.43965"
        x2="20.9842"
        y2="2.81429"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_519_24313"
        x1="23.703"
        y1="9.11698"
        x2="24.8507"
        y2="4.49162"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_519_24313"
        x1="29.7184"
        y1="10.3085"
        x2="30.8661"
        y2="5.68312"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_519_24313"
        x1="28.9064"
        y1="8.01949"
        x2="27.3881"
        y2="3.70681"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_519_24313"
        x1="24.3351"
        y1="10.0032"
        x2="22.8168"
        y2="5.69053"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_519_24313"
        x1="36.8446"
        y1="9.61708"
        x2="35.3263"
        y2="5.3044"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_519_24313"
        x1="34.4681"
        y1="4.88047"
        x2="34.2784"
        y2="0.164278"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_519_24313"
        x1="37.2036"
        y1="4.33722"
        x2="39.8144"
        y2="0.457949"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_519_24313"
        x1="32.7144"
        y1="9.81019"
        x2="33.2903"
        y2="5.0742"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_519_24313"
        x1="32.3237"
        y1="7.8818"
        x2="32.8996"
        y2="3.14581"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_519_24313"
        x1="25.9094"
        y1="9.8159"
        x2="26.6446"
        y2="5.09684"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_519_24313"
        x1="40.4206"
        y1="5.91576"
        x2="39.6854"
        y2="1.21456"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_519_24313"
        x1="48.7878"
        y1="10.8395"
        x2="48.0526"
        y2="6.13832"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_519_24313"
        x1="55.4987"
        y1="9.29464"
        x2="52.3876"
        y2="5.89191"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_519_24313"
        x1="44.9499"
        y1="9.18059"
        x2="44.1892"
        y2="4.48391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_519_24313"
        x1="50.112"
        y1="7.94523"
        x2="49.3513"
        y2="3.24855"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_519_24313"
        x1="35.7288"
        y1="10.6193"
        x2="33.8851"
        y2="6.21736"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_519_24313"
        x1="49.2682"
        y1="5.74083"
        x2="49.1013"
        y2="0.990038"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_519_24313"
        x1="42.8477"
        y1="6.88281"
        x2="40.9885"
        y2="2.50985"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_519_24313"
        x1="43.1469"
        y1="11.1204"
        x2="41.3077"
        y2="6.73537"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_519_24313"
        x1="54.5991"
        y1="7.75424"
        x2="57.0126"
        y2="4.07245"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_519_24313"
        x1="48.0318"
        y1="4.34797"
        x2="48.516"
        y2="-0.315607"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_519_24313"
        x1="46.9823"
        y1="5.89779"
        x2="47.471"
        y2="1.21725"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_519_24313"
        x1="53.134"
        y1="6.54285"
        x2="53.6227"
        y2="1.86231"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_519_24313"
        x1="54.9706"
        y1="8.51023"
        x2="51.7319"
        y2="5.27875"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_519_24313"
        x1="49.8848"
        y1="6.6158"
        x2="50.9085"
        y2="2.07384"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_519_24313"
        x1="51.4806"
        y1="8.09531"
        x2="52.498"
        y2="3.56933"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_519_24313"
        x1="52.6227"
        y1="5.24171"
        x2="53.6401"
        y2="0.715727"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_519_24313"
        x1="42.9427"
        y1="5.31715"
        x2="45.8115"
        y2="2.13657"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_519_24313"
        x1="38.6169"
        y1="7.66394"
        x2="40.4297"
        y2="3.52425"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_519_24313"
        x1="37.9238"
        y1="7.13422"
        x2="35.4938"
        y2="3.09433"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_519_24313"
        x1="45.126"
        y1="4.97838"
        x2="42.9243"
        y2="0.799784"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_519_24313"
        x1="39.2591"
        y1="11.527"
        x2="38.1098"
        y2="6.88391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_519_24313"
        x1="40.9319"
        y1="8.39494"
        x2="39.7843"
        y2="3.76958"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_519_24313"
        x1="55.7154"
        y1="7.50633"
        x2="54.5677"
        y2="2.88096"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_519_24313"
        x1="51.8528"
        y1="9.1836"
        x2="50.7052"
        y2="4.55824"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_519_24313"
        x1="45.8374"
        y1="10.3751"
        x2="44.6898"
        y2="5.74974"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_519_24313"
        x1="46.653"
        y1="8.07445"
        x2="48.1713"
        y2="3.76177"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_519_24313"
        x1="51.2224"
        y1="10.0748"
        x2="52.72"
        y2="5.74829"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_519_24313"
        x1="38.7129"
        y1="9.68859"
        x2="40.2312"
        y2="5.37591"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_519_24313"
        x1="41.0878"
        y1="4.94611"
        x2="41.2775"
        y2="0.229921"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint63_linear_519_24313"
        x1="38.3382"
        y1="4.41397"
        x2="35.7414"
        y2="0.519846"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#00B061" />
        <stop offset="1" stop-color="#00FFEA" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_519_24313"
        x1="42.8385"
        y1="9.87086"
        x2="42.2366"
        y2="5.13807"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint65_linear_519_24313"
        x1="43.2311"
        y1="7.94265"
        x2="42.6293"
        y2="3.20985"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B00000" />
        <stop offset="1" stop-color="#FF7300" />
      </linearGradient>
      <linearGradient
        id="paint66_linear_519_24313"
        x1="49.6433"
        y1="9.86887"
        x2="48.9081"
        y2="5.16768"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stop-color="#B06400" />
        <stop offset="1" stop-color="#FFBF00" />
      </linearGradient>
    </defs>
  </svg>
);

export default DogPlate;
