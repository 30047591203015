const WhatsappButton = () => <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1692_986)">
        <path d="M48.0009 21.7778C48.0009 33.8053 38.2506 43.5556 26.2231 43.5556C14.1956 43.5556 4.44531 33.8053 4.44531 21.7778C4.44531 9.75024 14.1956 0 26.2231 0C38.2506 0 48.0009 9.75024 48.0009 21.7778Z" fill="white" />
        <g clip-path="url(#clip0_1692_986)">
            <path d="M18.9077 16.8965C19.0474 15.792 20.4829 14.2908 21.6221 14.4573L21.6203 14.4555C22.729 14.6662 23.6067 16.5744 24.1016 17.4325C24.4522 18.055 24.2245 18.6858 23.8975 18.952C23.4562 19.3076 22.7663 19.7983 22.911 20.291C23.1684 21.1668 26.224 24.2224 27.7268 25.091C28.2956 25.4198 28.7081 24.5526 29.0604 24.1083C29.3163 23.7666 29.9476 23.5624 30.5687 23.8997C31.4964 24.4399 32.3703 25.0678 33.1784 25.7746C33.582 26.1119 33.6767 26.6105 33.3971 27.1374C32.9045 28.0654 31.4799 29.2892 30.4458 29.0405C28.6396 28.6062 21.3351 25.7746 18.9888 17.571C18.8569 17.1829 18.8901 17.0362 18.9077 16.8965Z" fill="#53B62D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2237 35.2224C24.7282 35.2224 23.9008 35.0619 22.5571 34.6113L19.9836 35.898C18.3583 36.7107 16.446 35.5288 16.446 33.7116V30.9446C13.814 28.4904 12.7793 25.6606 12.7793 21.7779C12.7793 14.3528 18.7986 8.3335 26.2237 8.3335C33.6489 8.3335 39.6682 14.3528 39.6682 21.7779C39.6682 29.2031 33.6489 35.2224 26.2237 35.2224ZM18.8904 29.8816L18.113 29.1567C16.0681 27.2501 15.2237 25.1184 15.2237 21.7779C15.2237 15.7028 20.1486 10.7779 26.2237 10.7779C32.2989 10.7779 37.2237 15.7028 37.2237 21.7779C37.2237 27.8531 32.2989 32.7779 26.2237 32.7779C25.019 32.7779 24.454 32.6692 23.3343 32.2937L22.3719 31.9709L18.8904 33.7116V29.8816Z" fill="#53B62D" />
        </g>
    </g>
    <defs>
        <filter id="filter0_d_1692_986" x="0.445312" y="0" width="51.5547" height="51.5557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1692_986" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1692_986" result="shape" />
        </filter>
        <clipPath id="clip0_1692_986">
            <rect width="29.3333" height="29.3333" fill="white" transform="translate(11.5566 7.11133)" />
        </clipPath>
    </defs>
</svg>
    ;

export default WhatsappButton;
