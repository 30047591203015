import React, { useEffect, useState } from "react";
import ForwardButton from "../../assets/svg/ForwardButton";
import { Modal } from "antd";
import "./photo-carousel.css";
import ReactPlayer from "react-player";
import { datadogLogs } from "@datadog/browser-logs";
import { PROFILE_PHOTO_DESKTOP_IMAGE_SIZE, PROFILE_PHOTO_MOBILE_IMAGE_SIZE } from '../../utils';

const PhotoCarousel: React.FC<{ photos: string[]; videos: string[] }> = ({
  photos,
  videos,
}) => {
  const [media, setMedia] = useState<any[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<number>(0);
  const [selectedModalPhoto, setSelectedModalPhoto] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(true);

  const imageSize = window.innerWidth > 768 ? PROFILE_PHOTO_DESKTOP_IMAGE_SIZE : PROFILE_PHOTO_MOBILE_IMAGE_SIZE;


  useEffect(() => {
    const photoMedia = photos.map((photo) => ({ url: `https://cdn.petpair.co/${imageSize}/${photo}`, type: "photo" }));
    const videoMedia = videos.map((video) => ({ url: video.includes('http') ? video : 'https://videos.petpair.co/' + video, type: "video" }));
    setMedia([...photoMedia, ...videoMedia]);
  //eslint-disable-next-line
  }, [photos, videos]);


  const handlePrevious = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (selectedPhoto > 0) {
      setSelectedPhoto((prevState: number) => prevState - 1);
    }
    datadogLogs.logger.info(`Previous photo button clicked.`, {
      url: window.location.href,
    });
  };

  const handleNext = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (selectedPhoto < media.length - 1) {
      setSelectedPhoto((prevState: number) => prevState + 1);
    }
    datadogLogs.logger.info(`Next photo button clicked.`, {
      url: window.location.href,
    });
  };

  const handleImageClick = () => {
    if (window.innerWidth < 1200) {
      return;
    }

    if (media[selectedPhoto].type === "photo") {
      setModalVisible(true);
      setSelectedModalPhoto(selectedPhoto);
    } else {
      setPlaying((prevPlaying) => !prevPlaying);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleThumbnailClick = (index: number) => {
    setSelectedModalPhoto(index);
  };

  return (
    <div>
      <div className="photo-container" onClick={handleImageClick}>
        {media.length > 0 && (
          <>
            {media[selectedPhoto].type === "photo" ? (
              <img src={media[selectedPhoto].url} alt="PetPair" />
            ) : (
              <ReactPlayer
                url={media[selectedPhoto].url}
                playing={playing}
                controls
                width="100%"
                height="100%"
              />
            )}
          </>
        )}
        {selectedPhoto < media.length - 1 && (
          <div className="photo-forward" onClick={handleNext}>
            <ForwardButton />
          </div>
        )}
        {selectedPhoto > 0 && (
          <div className="photo-back" onClick={handlePrevious}>
            <ForwardButton />
          </div>
        )}
      </div>

      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
        width={1120}
      >
        <div className="modal-content">
          <div className="modal-photo">
            {media.length > 0 &&
              (media[selectedModalPhoto].type === "photo" ? (
                <img src={media[selectedModalPhoto].url} alt="PetPair" />
              ) : (
                <ReactPlayer
                  url={media[selectedModalPhoto].url}
                  playing={playing}
                  controls
                  width="100%"
                  height="100%"
                />
              ))}
          </div>
          <div className="modal-thumbnails">
            {media.map((item, index) => {
              if (item.type === "photo") {
                return (
                  <img
                    key={index}
                    src={item.url}
                    className={index === selectedModalPhoto ? "active" : ""}
                    onClick={() => handleThumbnailClick(index)}
                    alt="PetPair"
                  />
                );
              } else {
                return (
                  <div
                    key={index}
                    className={`video-thumbnail ${
                      index === selectedModalPhoto ? "active" : ""
                    }`}
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <ReactPlayer url={item.url} width="100%" height="100%" />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PhotoCarousel;
