const InstagramButton = () => <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1692_974)">
        <circle cx="25.7778" cy="21.7778" r="21.7778" fill="white" />
        <g clip-path="url(#clip0_1692_974)">
            <path d="M31.3074 8.88892H19.3588C15.3012 8.88892 12 12.1902 12 16.2479V28.1964C12 32.2542 15.3012 35.5554 19.3588 35.5554H31.3074C35.3653 35.5554 38.6665 32.2541 38.6665 28.1964V16.2479C38.6667 12.1902 35.3653 8.88892 31.3074 8.88892ZM36.3007 28.1964C36.3007 30.9496 34.0607 33.1894 31.3075 33.1894H19.3588C16.6058 33.1896 14.366 30.9496 14.366 28.1964V16.2479C14.366 13.4949 16.6058 11.2549 19.3588 11.2549H31.3074C34.0606 11.2549 36.3005 13.4949 36.3005 16.2479V28.1964H36.3007Z" fill="#53B62D" />
            <path d="M25.3342 15.3513C21.5453 15.3513 18.4629 18.4337 18.4629 22.2226C18.4629 26.0113 21.5453 29.0936 25.3342 29.0936C29.1231 29.0936 32.2055 26.0113 32.2055 22.2226C32.2055 18.4337 29.1231 15.3513 25.3342 15.3513ZM25.3342 26.7274C22.8501 26.7274 20.8289 24.7066 20.8289 22.2224C20.8289 19.7382 22.8499 17.7171 25.3342 17.7171C27.8185 17.7171 29.8395 19.7382 29.8395 22.2224C29.8395 24.7066 27.8183 26.7274 25.3342 26.7274Z" fill="#53B62D" />
            <path d="M32.4929 13.345C32.037 13.345 31.5892 13.5295 31.2673 13.8529C30.9438 14.1746 30.7578 14.6226 30.7578 15.08C30.7578 15.536 30.9439 15.9838 31.2673 16.3072C31.5891 16.629 32.037 16.8151 32.4929 16.8151C32.9503 16.8151 33.3967 16.629 33.72 16.3072C34.0434 15.9838 34.2279 15.5359 34.2279 15.08C34.2279 14.6226 34.0434 14.1746 33.72 13.8529C33.3982 13.5295 32.9503 13.345 32.4929 13.345Z" fill="#53B62D" />
        </g>
    </g>
    <defs>
        <filter id="filter0_d_1692_974" x="0" y="0" width="51.5547" height="51.5557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1692_974" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1692_974" result="shape" />
        </filter>
        <clipPath id="clip0_1692_974">
            <rect width="26.6667" height="26.6667" fill="white" transform="translate(12 8.88892)" />
        </clipPath>
    </defs>
</svg>
    ;

export default InstagramButton;
