import React, { useState, useEffect } from "react";
import "./facebook-app.css";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import FacebookButton from "../../components/FacebookButton";
import DogAdoptionCard from "../../components/DogAdoptionCard";
import Confetti from "react-confetti";
import Spinner from "../../components/Spinner";
import { getDogById } from "../../service/actions";
import LoginModal from "../../components/LoginModal";
import { useParamsNavigate } from '../../utils';

const FacebookApp = () => {
  const [dog, setDog] = useState<any>();
  const navigate = useParamsNavigate();
  const { dogId } = useParams();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!dogId) {
      navigate("/discovery");
    } else {
      getDogById(dogId)
        .then((res) => {
          if (res?.data.name) setDog((res as any).data);
          else navigate("/discovery");
          console.log(res);
        })
        .catch(() => {
          navigate("/discovery");
        });
    }
    // eslint-disable-next-line
  }, []);

  if (!dog)
    return (
      <div className="fb-loading-root">
        <Spinner isDone={false} />
      </div>
    );

  return (
    <>
      {<Confetti numberOfPieces={1500} recycle={false} />}
      <Navbar />
      <div className="facebook-app-content">
        <DogAdoptionCard
          dogName={dog.name}
          breed={dog.breed.name}
          adoptionDate="2023-07-14T20:27:53.369Z"
          dogPhoto={`https://cdn.petpair.co/100x100/${dog.photos[0]}`}
        />
        <span className="facebook-app-title">
          We’re so excited you want to take {dog.name} home!
        </span>
        <p className="facebook-app-description">
          First, let’s create an account so you can complete your adoption
          application.
        </p>
        <br />
        <FacebookButton dogId={dogId} />
        <span
          className="fb-app-account-text"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Continue with your email
        </span>
        <LoginModal
          showModal={showModal}
          handler={setShowModal}
          createAccount
          applyToAdopt={true}
          dogId={dog.id}
        />
      </div>
    </>
  );
};

export default FacebookApp;
