import React, { useEffect, useState } from "react";
import "./about.css";
import { datadogLogs } from "@datadog/browser-logs";
import Navbar from "../../components/Navbar";
import ArrowDown from "../../assets/svg/ArrowDown";
import Button from "../../components/Button";
import foster from "../../assets/images/foster-about.png";
import process from "../../assets/images/fosters-shelters.png";
import shipping from "../../assets/images/shipping-about.png";
import sage from "../../assets/images/sage.jpg";
import daniel from "../../assets/images/daniel.jpg";
import christie from "../../assets/images/christie.jpg";
import pedro from "../../assets/images/pedro.jpg";
import aanandh from "../../assets/images/aanandh.jpg";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';


const About = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth < 600);
  const { t } = useTranslation();

  useEffect(() => {
    datadogLogs.logger.info("About page loaded", { url: window.location.href });

    const handleResize = () => {
      setIsMediumScreen(window.innerWidth < 540);
      console.log(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="about-page">
      <Helmet>
        <title>{`PetPair | About Us`}</title>
        <meta name="description" content={`We're the content-powered marketplace for pet adoptions – learn more about our journeyand how we can help you find your next furry friend.`} />
      </Helmet>
      <Navbar />
      <div className="about-root">
        <div className="about-section">
          <span className="about-intro-title">
            {t("aboutPage.mainHeaderTagline")}
          </span>
          <div className="about-description-container">
            <p className="about-intro-description">
              {t("aboutPage.mainHeaderDescription")}
            </p>
          </div>
          <div className="about-cta-buttons">
            <Button
              title={t("aboutPage.mainHeaderCTA1")}
              filled
              medium={isMediumScreen}
              location="/shelters"
            />
            <Button
              title={t("aboutPage.mainHeaderCTA2")}
              filled
              medium={isMediumScreen}
              location="/fosters"
            />
          </div>
          <div className="section1-scroll-message">
            <div className="arrow-bounce">
              <ArrowDown />
            </div>
            <span className="section1-scroll-text">
              {t("aboutPage.scrollExplainer")}
            </span>
          </div>
        </div>
        <div className="about-section">
          <span className="about-section-title">
            {t("aboutPage.valuePropositionHeader")}
          </span>
          <div className="about-section-description-container">
            <p className="about-section-description">
              {t("aboutPage.valuePropositionDescription")}
            </p>
          </div>
          <div className="about-benefits-container">
            <div className="about-benefit">
              <img alt='PetPair' src={foster} className="about-benefit-img" />
              <span className="about-benefit-title">
                {t("aboutPage.valuePropHeader1")}
              </span>
              <p className="about-benefit-description">
                {t("aboutPage.valuePropDescription1")}
              </p>
            </div>
            <div className="about-benefit">
              <img alt='PetPair' src={process} className="about-benefit-img" />
              <span className="about-benefit-title">
                {t("aboutPage.valuePropHeader2")}
              </span>
              <p className="about-benefit-description">
                {t("aboutPage.valuePropDescription2")}
              </p>
            </div>
            <div className="about-benefit">
              <img alt='PetPair' src={shipping} className="about-benefit-img" />
              <span className="about-benefit-title">
                {t("aboutPage.valuePropHeader3")}
              </span>
              <p className="about-benefit-description">
                {t("aboutPage.valuePropDescription3")}
              </p>
            </div>
          </div>
          <br />
          <Button
            title={t("aboutPage.valuePropositionCTA")}
            filled
            medium={isMediumScreen}
            location="mailto:adopt@petpair.co"
          />
        </div>
        <div className="about-section">
          <span className="about-section-title">
            {t("aboutPage.teamHeader")}
          </span>
          <div className="about-section-description-container">
            <p className="about-section-description">
              {t("aboutPage.teamDescription")}
            </p>
          </div>
          <div className="about-team-container">
            <div className="about-team-entry">
              <img alt='PetPair' src={daniel} className="about-team-img" />
              <span className="about-team-name">Daniel Miyares</span>
              <span className="about-team-position">Cofounder</span>
            </div>
            <div className="about-team-entry">
              <img alt='PetPair' src={aanandh} className="about-team-img" />
              <span className="about-team-name">Aanandh Chandrasekar</span>
              <span className="about-team-position">Cofounder</span>
            </div>
            <div className="about-team-entry">
              <img alt='PetPair' src={christie} className="about-team-img" />
              <span className="about-team-name">Christie Hamor</span>
              <span className="about-team-position">Operations</span>
            </div>
          </div>
          <div className="about-team-container2">
            <div className="about-team-entry">
              <img alt='PetPair' src={sage} className="about-team-img" />
              <span className="about-team-name">Sage Pollack</span>
              <span className="about-team-position">Operations</span>
            </div>
            <div className="about-team-entry">
              <img alt='PetPair' src={pedro} className="about-team-img" />
              <span className="about-team-name">Pedro Reis</span>
              <span className="about-team-position">Tech</span>
            </div>
          </div>
          <br />
          <Button
            title="Join us"
            filled
            medium={isMediumScreen}
            location="mailto:daniel@petpair.co"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
