// @ts-nocheck
import './review-card.css';
import ReviewStarIcon from '../../assets/svg/ReviewStarIcon';
import { datadogLogs } from "@datadog/browser-logs";
import { useParamsNavigate } from '../../utils';

const ReviewCard = ({ slug, image, title, description, numberOfStars, title2, app }) => {

    const starsArray = Array.from({ length: numberOfStars });

    const navigate = useParamsNavigate();

    const reviewClick = () => {
        datadogLogs.logger.info(`Review card clicked`);
        navigate(`/blog/post/${slug}`);
    }

    return(
        <div className={!app ? 'review-card' : 'review-card-app'} onClick={reviewClick}>
            {!app && <img src={image} className='review-card-image' alt='PetPair Review'/>}
            <div className='review-card-contents'>
                <div className='review-card-title-container'>
                <span className='review-card-title'>{title}</span>
                <span className='review-card-title2'>{title2}</span>
                </div>
                <div className='review-card-stars'>
                    {starsArray.map((_, index) => (
                        <ReviewStarIcon key={index} />
                    ))}
                </div>
                <span className='review-card-description'>{description}</span>
            </div>
        </div>
    )
};

export default ReviewCard;
