import React from "react";

const DogIcon = (props: any) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2646 7.15294C14.8464 7.1458 14.5083 7.46122 14.5 7.85839C14.4692 9.34782 13.1806 10.5596 11.627 10.5602C10.1769 10.5605 8.5114 9.27642 8.90204 7.74867C9.18131 6.65629 10.3945 5.44109 11.0299 4.48702C11.4356 3.87758 10.9795 3.07861 10.2258 3.07861H5.77433C5.02073 3.07861 4.5645 3.87758 4.97025 4.48702C5.61163 5.45044 7.47512 7.31538 7.16039 8.48428C6.83114 9.70661 5.68528 10.5603 4.37398 10.5603C2.81976 10.5603 1.53069 9.34822 1.49993 7.85854C1.49187 7.46588 1.15767 7.15285 0.750293 7.15285C0.745457 7.15285 0.740372 7.15285 0.735412 7.15297C0.320959 7.16082 -0.00828573 7.48934 0.000158878 7.88675C0.0464138 10.1548 2.00877 12.0001 4.3739 12.0001C5.85843 12.0001 7.20221 11.2811 7.99968 10.1481C8.79706 11.281 10.1411 12.0001 11.6261 12.0001C13.9915 12.0001 15.9535 10.155 15.9998 7.88705C16.0083 7.4894 15.679 7.16089 15.2646 7.15303L15.2646 7.15294Z"
      fill="black"
    />
    <path
      d="M0.86932 1.66363C0.587569 2.02976 0.668053 2.54566 1.04963 2.81623C1.22114 2.93807 1.42427 2.98935 1.62193 2.97567C1.86313 2.95901 2.09578 2.84479 2.25089 2.6436C2.77398 1.96455 3.61092 1.59392 4.4884 1.65471C4.96485 1.68827 5.3727 1.34524 5.40632 0.891205C5.44005 0.437169 5.08403 0.0429557 4.61078 0.0104854C3.14139 -0.0893411 1.74415 0.528305 0.86912 1.66364L0.86932 1.66363Z"
      fill="black"
    />
    <path
      d="M13.7493 2.64358C13.9046 2.84491 14.1371 2.959 14.3783 2.97566C14.576 2.98934 14.779 2.93794 14.9506 2.81622C15.3322 2.54565 15.4127 2.0295 15.1309 1.66361C14.2559 0.52828 12.8589 -0.0896311 11.3896 0.0105517C10.9163 0.0429154 10.5602 0.437113 10.594 0.891271C10.6275 1.34531 11.0352 1.68835 11.5119 1.65478C12.3893 1.59386 13.2264 1.9645 13.7494 2.64354L13.7493 2.64358Z"
      fill="black"
    />
  </svg>
);

export default DogIcon;
