const DogBed = () => (
  <svg
    width="56"
    height="30"
    viewBox="0 0 56 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.248428 10.6116C0.248428 11.0889 0.37117 12.0502 0.6073 12.5062C0.668262 12.6253 0.738018 12.7436 0.815002 12.8595C0.963959 13.0863 1.14044 13.3106 1.34461 13.5299C1.40779 13.5981 1.47245 13.6663 1.54072 13.7328C5.06835 17.214 15.4146 19.7338 27.63 19.7338C39.7482 19.7338 50.0291 17.2534 53.6342 13.8158C53.7031 13.7493 53.7701 13.6827 53.8338 13.617C54.1116 13.3294 54.342 13.0345 54.5239 12.7354C54.5975 12.6147 54.6625 12.4931 54.719 12.3706C54.9121 11.9574 54.9613 11.273 54.9994 10.8441C55.1984 8.62167 52.9677 7.3638 49.5195 5.92025C49.3948 5.86685 49.2679 5.81591 49.1393 5.76415C49.1019 5.74854 49.0644 5.73457 49.0263 5.71896C48.935 5.68363 48.8431 5.64748 48.7498 5.61215C45.5309 4.3855 41.254 3.4423 36.3591 2.92469C36.2645 2.91319 36.1694 2.90416 36.0741 2.8943C36.0374 2.89019 36 2.8869 35.9624 2.88279C35.908 2.87704 35.8541 2.87129 35.7996 2.86718C33.2196 2.61248 30.4754 2.4761 27.63 2.4761C27.0351 2.4761 26.444 2.48185 25.8583 2.49335C25.8504 2.49335 25.8425 2.49418 25.8346 2.49418C25.742 2.49582 25.6488 2.49828 25.5563 2.50075C25.5174 2.50075 25.4787 2.50239 25.4407 2.50321C22.4581 2.57798 19.6095 2.80227 16.9722 3.15474C16.9407 3.15803 16.9085 3.16295 16.877 3.16706C16.8178 3.17528 16.7582 3.18267 16.699 3.19171C16.6274 3.20075 16.5559 3.21143 16.4843 3.22047C12.5594 3.77258 9.12237 4.60733 6.43327 5.64173C6.38142 5.66227 6.32951 5.68199 6.27766 5.70253C6.24356 5.71567 6.20955 5.72881 6.17611 5.74278C6.0882 5.77729 6.00177 5.8118 5.91608 5.84713C2.36158 7.303 0.248428 8.63236 0.248428 10.6116Z"
      fill="#3A9119"
    />
    <path
      d="M0.248066 20.2998H55.0117C55.0117 15.0774 42.7524 10.8441 27.6299 10.8441C12.5074 10.8441 0.248066 15.0774 0.248066 20.2998Z"
      fill="#347D19"
    />
    <path
      d="M10.5909 17.7372C10.9016 18.6983 11.413 19.5473 12.0593 20.3001H43.0941C43.7172 18.4193 44.8342 14.4269 43.1032 14.0471C42.0598 13.8185 40.1686 14.9405 39.2394 15.3526C37.938 15.9301 36.6613 16.5664 35.3132 17.0326C31.8178 18.2411 27.9986 18.2426 24.3495 17.6402C20.7003 17.0379 17.1766 15.8557 13.6549 14.7262C12.911 14.487 12.0743 14.2554 11.3743 14.6036C10.3218 15.127 10.2298 16.6182 10.5909 17.7372Z"
      fill="#347D19"
    />
    <path
      d="M47.0447 15.0691L47.2463 15.2528C47.8478 14.5941 48.0757 13.5606 47.9418 12.0939C47.9227 11.8901 47.8997 11.6831 47.8774 11.4833C47.8018 10.8013 47.7244 10.1016 47.8071 9.43407C47.8183 9.34099 47.8327 9.24792 47.8511 9.15564C47.8675 9.0738 47.8866 8.99276 47.9089 8.91172C48.0218 8.50252 48.2084 8.13343 48.4054 7.74188C48.5177 7.51962 48.6339 7.28934 48.7337 7.05345C48.865 6.74213 49.0331 6.24065 49.0272 5.71831C48.936 5.68301 48.844 5.6469 48.7508 5.6116C48.7843 6.1227 48.6129 6.63702 48.4821 6.94673C48.3857 7.1738 48.2721 7.40007 48.1617 7.61911C47.9673 8.00425 47.7657 8.40302 47.6456 8.83951C47.6266 8.90771 47.6101 8.97591 47.5957 9.04492C47.576 9.13639 47.5596 9.22866 47.5464 9.32093C47.4433 10.0423 47.5254 10.7885 47.6062 11.513C47.6285 11.712 47.6515 11.9182 47.6692 12.118C47.7947 13.4844 47.5845 14.4785 47.0447 15.0691Z"
      fill="#396F00"
    />
    <path
      d="M6.23801 16.0747L6.45564 16.2399C6.94625 15.5926 7.0401 14.6252 6.73544 13.3649C6.69011 13.1748 6.63822 12.9831 6.58896 12.7962C6.39532 12.0734 6.19582 11.3258 6.2875 10.591C6.32039 10.3311 6.38737 10.0832 6.46879 9.83135C6.5069 9.71183 6.54829 9.5915 6.59097 9.46877C6.5949 9.45673 6.59947 9.4455 6.6034 9.43347C6.7052 9.14228 6.81092 8.84066 6.87919 8.53022C7.09465 7.55076 6.92323 6.48467 6.43269 5.64239C6.38079 5.66244 6.32897 5.6825 6.27707 5.70255C6.2429 5.71538 6.20897 5.72982 6.17552 5.74346C6.64872 6.532 6.81613 7.54354 6.61262 8.47006C6.54756 8.76606 6.44513 9.05966 6.34598 9.34363C6.31253 9.43989 6.27771 9.53856 6.2449 9.63722C6.20455 9.75594 6.16685 9.87787 6.13196 10.0006C6.08182 10.1819 6.04043 10.3672 6.01717 10.5573C5.91762 11.3451 6.12466 12.1183 6.32504 12.8668C6.37422 13.0521 6.42548 13.2422 6.47008 13.4283C6.66581 14.2377 6.78397 15.3543 6.23801 16.0747Z"
      fill="#396F00"
    />
    <path
      d="M0.248631 10.4106V21.3931C0.248631 21.9913 0.307365 22.5579 0.420212 23.0912C0.80822 24.93 1.83281 26.3723 3.26751 27.3786L4.23003 27.9575C4.40223 28.0464 4.57877 28.1291 4.75894 28.2064C5.13736 28.371 5.53402 28.5109 5.94522 28.6284C6.23939 28.7127 6.54107 28.7846 6.8497 28.8441C7.26994 28.9245 7.70269 28.9832 8.14465 29.018C8.47886 29.045 8.81771 29.0582 9.16181 29.0582H46.0981C47.4666 29.0582 48.7629 28.8464 49.9213 28.4352C50.3021 28.2992 50.6685 28.1423 51.0172 27.9645C52.2005 27.3608 53.1855 26.5129 53.8816 25.4378C54.1069 25.09 54.3019 24.7174 54.4634 24.3217C54.818 23.4553 55.0117 22.4767 55.0117 21.3931V10.4106L46.5512 10.6186C44.7815 10.6619 43.1838 11.6496 42.3078 13.1885C40.3667 16.601 34.529 19.0774 27.63 19.0774C20.7303 19.0774 14.8932 16.601 12.9515 13.1885C12.0754 11.6496 10.4784 10.6619 8.70803 10.6186L0.248631 10.4106Z"
      fill="#53B62D"
    />
    <path
      d="M0.41959 23.0906C0.808037 24.9292 1.83246 26.3719 3.26695 27.3784L4.2295 27.957C4.40203 28.0457 4.5785 28.1285 4.75871 28.206C5.13716 28.3708 5.53381 28.511 5.94493 28.628C6.23913 28.7123 6.54086 28.7839 6.84946 28.8435C7.26967 28.924 7.70248 28.9829 8.14439 29.018C8.47863 29.0448 8.81749 29.0575 9.16165 29.0575H46.0981C47.4665 29.0575 48.7628 28.8458 49.9212 28.4349C50.302 28.2985 50.6685 28.1419 51.0171 27.9637C52.2005 27.3598 53.1855 26.5121 53.8816 25.437C54.1069 25.0895 54.3019 24.7167 54.4633 24.3216C54.818 23.4552 55.0117 22.4763 55.0117 21.3922V14.8676C52.7849 15.2054 50.5576 16.194 48.6853 17.4548C45.0834 19.8802 42.1173 23.2293 38.2732 25.2498C31.7215 28.6937 23.827 27.7341 16.4999 26.6851C13.9776 26.3242 11.4488 25.9604 8.94625 25.4683C8.06558 25.296 7.18833 25.1074 6.31622 24.8972C6.08226 24.8412 5.84904 24.7838 5.61665 24.7242C5.24468 24.6288 4.87346 24.5303 4.50372 24.4267C4.38383 24.3931 4.26305 24.3596 4.14376 24.3246C2.90535 23.9667 1.6401 23.5738 0.41959 23.0906Z"
      fill="#46A522"
    />
    <path
      d="M16.7158 25.5783C16.8748 25.3538 16.9747 25.0945 17.0099 24.8221C17.0518 24.4988 17.2318 24.2169 17.5205 24.0811C17.8092 23.9452 18.1409 23.9867 18.4165 24.1603C18.649 24.307 18.9126 24.3956 19.1867 24.416C19.6877 24.4538 20.0928 24.6652 20.2655 25.032C20.5824 25.7061 19.9969 26.6483 18.9578 27.1372C17.9186 27.6261 16.8194 27.4757 16.5025 26.8023C16.3298 26.4348 16.4252 25.988 16.7158 25.5783Z"
      fill="#90DE6E"
    />
    <path
      d="M19.1567 23.258C19.3822 23.7375 19.8355 23.999 20.1692 23.8421C20.5029 23.6852 20.5906 23.1687 20.3651 22.6892C20.1397 22.2105 19.6863 21.949 19.3526 22.1059C19.0189 22.2628 18.9312 22.7786 19.1567 23.258Z"
      fill="#90DE6E"
    />
    <path
      d="M14.6332 25.385C14.8588 25.8645 15.3121 26.126 15.6458 25.9691C15.9795 25.8122 16.0672 25.2964 15.8417 24.817C15.6162 24.3375 15.1629 24.076 14.8291 24.2329C14.4955 24.3898 14.4077 24.9063 14.6332 25.385Z"
      fill="#90DE6E"
    />
    <path
      d="M17.0141 22.4749C17.2396 22.9543 17.6929 23.2159 18.0267 23.0589C18.3603 22.902 18.4481 22.3863 18.2226 21.9068C17.9971 21.4274 17.5437 21.1658 17.21 21.3228C16.8763 21.4797 16.7886 21.9954 17.0141 22.4749Z"
      fill="#90DE6E"
    />
    <path
      d="M15.3969 23.2362C15.6224 23.7156 16.0758 23.9764 16.4094 23.8195C16.7431 23.6626 16.8309 23.1468 16.6053 22.6674C16.3799 22.1879 15.9266 21.9264 15.5928 22.0833C15.2591 22.2402 15.1714 22.7567 15.3969 23.2362Z"
      fill="#90DE6E"
    />
    <path
      d="M47.3498 15.0111C47.6121 15.0931 47.8899 15.108 48.1601 15.0581C48.4803 14.9984 48.8038 15.0827 49.0219 15.3161C49.2398 15.5487 49.303 15.8768 49.2224 16.1929C49.1544 16.4584 49.1512 16.7372 49.2162 17.0042C49.3347 17.4925 49.2582 17.9429 48.962 18.2203C48.4184 18.7288 47.3417 18.4619 46.5568 17.6238C45.7719 16.7857 45.5762 15.6941 46.1197 15.1848C46.4158 14.9075 46.8704 14.8605 47.3498 15.0111Z"
      fill="#90DE6E"
    />
    <path
      d="M50.3112 16.6193C49.9245 16.9814 49.8152 17.4933 50.0673 17.7625C50.3193 18.0324 50.8371 17.9564 51.2239 17.5943C51.6107 17.2322 51.7199 16.7204 51.4678 16.4512C51.2158 16.182 50.6979 16.2572 50.3112 16.6193Z"
      fill="#90DE6E"
    />
    <path
      d="M46.8932 12.9707C46.5064 13.3332 46.3972 13.8454 46.6494 14.1136C46.9013 14.3834 47.4192 14.3074 47.8059 13.9457C48.1927 13.5832 48.3019 13.0718 48.0499 12.8028C47.7978 12.5338 47.2799 12.6082 46.8932 12.9707Z"
      fill="#90DE6E"
    />
    <path
      d="M50.3972 14.3412C50.0104 14.7026 49.9012 15.2151 50.1532 15.4834C50.4053 15.7532 50.9232 15.6781 51.3099 15.3158C51.6967 14.9536 51.8058 14.4419 51.5538 14.1728C51.3018 13.9038 50.7838 13.9789 50.3972 14.3412Z"
      fill="#90DE6E"
    />
    <path
      d="M49.1725 13.0352C48.7858 13.3975 48.6765 13.9086 48.9286 14.1782C49.1807 14.4471 49.6985 14.3727 50.0853 14.0096C50.472 13.6481 50.5812 13.1362 50.3291 12.8673C50.0771 12.5977 49.5592 12.6736 49.1725 13.0352Z"
      fill="#90DE6E"
    />
    <path
      d="M52.3499 24.9187C52.3065 25.1904 52.3319 25.4672 52.4208 25.7272C52.5263 26.0352 52.4895 26.3679 52.2907 26.6171C52.0919 26.867 51.7763 26.9767 51.4522 26.9426C51.1788 26.9142 50.9032 26.9513 50.6483 27.0544C50.1824 27.2426 49.7255 27.2317 49.4082 26.9789C48.8259 26.5154 48.934 25.4112 49.6496 24.5126C50.3652 23.6147 51.4173 23.2624 51.9997 23.7266C52.317 23.9794 52.4292 24.4225 52.3499 24.9187Z"
      fill="#90DE6E"
    />
    <path
      d="M53.47 25.215C53.6007 25.3182 53.7419 25.393 53.8811 25.4373C54.1064 25.0894 54.3014 24.7167 54.4629 24.3215C54.4149 24.2685 54.3611 24.2176 54.3021 24.1704C53.8877 23.8406 53.3656 23.8065 53.1358 24.0949C52.9059 24.3833 53.0556 24.8852 53.47 25.215Z"
      fill="#90DE6E"
    />
    <path
      d="M5.50486 18.0924C5.51361 17.8179 5.4533 17.5456 5.33274 17.2985C5.18889 17.007 5.18377 16.6724 5.34943 16.3993C5.51503 16.1271 5.81431 15.9779 6.13978 15.9713C6.41503 15.9653 6.68374 15.8941 6.92368 15.7598C7.36207 15.5142 7.81663 15.4668 8.16325 15.6775C8.79943 16.0647 8.83141 17.1739 8.23462 18.1547C7.63791 19.1362 6.63849 19.6177 6.00245 19.2304C5.65599 19.0197 5.48891 18.5946 5.50486 18.0924Z"
      fill="#90DE6E"
    />
    <path
      d="M6.26076 14.8098C6.71346 15.0853 7.23574 15.0528 7.42741 14.7382C7.61901 14.4227 7.40741 13.9443 6.95479 13.6688C6.50209 13.3934 5.97959 13.4251 5.78784 13.7405C5.59677 14.056 5.80814 14.5344 6.26076 14.8098Z"
      fill="#90DE6E"
    />
    <path
      d="M3.66352 19.0804C4.11587 19.3558 4.63815 19.3237 4.82972 19.0083C5.0213 18.6937 4.81005 18.2151 4.35698 17.9397C3.90463 17.6644 3.38235 17.6964 3.19078 18.0119C2.99847 18.3273 3.21044 18.8051 3.66352 19.0804Z"
      fill="#90DE6E"
    />
    <path
      d="M4.04193 15.3391C4.49448 15.6148 5.01676 15.5818 5.20852 15.2677C5.40034 14.9521 5.18891 14.4735 4.73568 14.1977C4.28305 13.9227 3.76077 13.955 3.56917 14.2699C3.37832 14.5854 3.58945 15.0641 4.04193 15.3391Z"
      fill="#90DE6E"
    />
    <path
      d="M3.11221 16.8671C3.56482 17.1423 4.08718 17.1104 4.27862 16.7951C4.4708 16.4805 4.25933 16.0019 3.80671 15.7266C3.35335 15.4513 2.83099 15.4832 2.63955 15.7986C2.44812 16.1139 2.65959 16.5918 3.11221 16.8671Z"
      fill="#90DE6E"
    />
    <path
      d="M41.1681 24.0396C40.9021 23.9699 40.624 23.9684 40.3564 24.0309C40.0392 24.1057 39.7123 24.0367 39.4835 23.8144C39.2548 23.5921 39.1762 23.2667 39.2418 22.9478C39.2972 22.6783 39.2873 22.4008 39.2099 22.1371C39.0684 21.6547 39.1238 21.2014 39.4065 20.9101C39.9255 20.3762 41.0137 20.592 41.8372 21.3925C42.6606 22.193 42.9075 23.2747 42.3885 23.8086C42.1058 24.0992 41.654 24.1675 41.1681 24.0396Z"
      fill="#90DE6E"
    />
    <path
      d="M38.1349 22.5722C38.5041 22.1923 38.5891 21.6758 38.3247 21.4194C38.0602 21.1622 37.5465 21.2618 37.1772 21.6417C36.808 22.0216 36.723 22.5381 36.9874 22.7953C37.2519 23.0517 37.7656 22.9522 38.1349 22.5722Z"
      fill="#90DE6E"
    />
    <path
      d="M41.7208 26.0562C42.0901 25.6763 42.1751 25.1598 41.9106 24.9027C41.6462 24.6462 41.1324 24.7457 40.7632 25.1257C40.3939 25.5056 40.3089 26.0221 40.5734 26.2785C40.8378 26.5357 41.3515 26.4362 41.7208 26.0562Z"
      fill="#90DE6E"
    />
    <path
      d="M38.1583 24.8532C38.5276 24.4733 38.6126 23.9568 38.3481 23.6997C38.0836 23.4425 37.5699 23.5427 37.2007 23.9227C36.8314 24.3026 36.7464 24.8184 37.0108 25.0755C37.2753 25.3327 37.7891 25.2332 38.1583 24.8532Z"
      fill="#90DE6E"
    />
    <path
      d="M39.4396 26.0991C39.8089 25.7192 39.8938 25.2027 39.6294 24.9462C39.365 24.6891 38.8512 24.7886 38.482 25.1685C38.1126 25.5485 38.0277 26.065 38.2921 26.3221C38.5566 26.5785 39.0703 26.479 39.4396 26.0991Z"
      fill="#90DE6E"
    />
    <path
      d="M7.6056 27.8549C7.36777 27.7169 7.10022 27.6414 6.82548 27.6312C6.49989 27.6189 6.203 27.4656 6.04173 27.1903C5.87973 26.9149 5.89063 26.5808 6.03809 26.2909C6.16304 26.0461 6.22769 25.7752 6.22261 25.5006C6.21461 24.9979 6.38816 24.5751 6.73801 24.3702C7.38004 23.9932 8.37191 24.4901 8.95343 25.4802C9.53494 26.4704 9.48584 27.5789 8.84381 27.9559C8.49396 28.1615 8.04023 28.107 7.6056 27.8549Z"
      fill="#90DE6E"
    />
    <path
      d="M5.07075 25.6356C5.52768 25.3668 5.74706 24.8917 5.56037 24.5736C5.37367 24.2561 4.85137 24.2154 4.39444 24.4842C3.93751 24.7523 3.71885 25.2274 3.90554 25.5455C4.09224 25.8637 4.61382 25.9037 5.07075 25.6356Z"
      fill="#90DE6E"
    />
    <path
      d="M3.26545 27.3783L4.22798 27.9573C4.31442 27.9289 4.40086 27.8897 4.48513 27.8403C4.94206 27.5723 5.16144 27.0964 4.97402 26.779C4.78733 26.4601 4.26575 26.4201 3.80882 26.6889C3.51025 26.864 3.31339 27.1284 3.26545 27.3783Z"
      fill="#90DE6E"
    />
    <path
      d="M27.686 20.1114C31.4132 20.1114 34.7613 19.4881 37.6374 18.2592C39.0166 17.6704 40.1362 17.0057 41.0601 16.2291C41.6201 15.7584 42.0536 15.1696 42.4729 14.6007C42.8984 14.0234 43.3381 13.4262 43.9034 12.9685C44.7704 12.2663 45.8696 11.863 46.9984 11.8347C46.9988 11.8347 47.0931 11.8316 55.0137 11.6308L55.0088 11.4337C54.9288 11.436 47.0199 11.6369 46.9936 11.6376C45.8214 11.6675 44.68 12.0854 43.7795 12.8152C43.1946 13.289 42.7471 13.8962 42.3144 14.4842C41.9019 15.0431 41.4753 15.6227 40.9333 16.0781C40.0246 16.8424 38.9212 17.4964 37.5598 18.0775C34.7084 19.2964 31.3864 19.9144 27.686 19.9144C24.3744 19.9144 21.3351 19.4091 18.6524 18.4125C16.7994 17.7248 14.7374 16.5373 13.3992 15.3873C12.8775 14.9388 12.4442 14.419 12.0252 13.9169C11.0656 12.7662 10.1593 11.679 8.37777 11.6376L0.249718 11.4337L0.245121 11.6308L8.37294 11.8347C10.0652 11.8738 10.9438 12.9279 11.8739 14.0434C12.2981 14.5517 12.7367 15.0776 13.2707 15.5368C14.6252 16.7006 16.7108 17.9019 18.5838 18.5973C21.2885 19.6016 24.3509 20.1114 27.686 20.1114Z"
      fill="#328E16"
    />
  </svg>
);

export default DogBed;
