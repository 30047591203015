import React from "react";
import "./tag.css";
import XIcon from "../../assets/svg/XIcon";

const Filter: React.FC<{ title: string; remove: any }> = ({
  title,
  remove,
}) => {
  return (
    <div className="filter-tag">
      <span>{title}</span>
      <div className="filter-tag-icon" onClick={remove}>
        <XIcon />
      </div>
    </div>
  );
};

export default Filter;
