const FacebookButton = () => <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1692_982)">
        <circle cx="26.0004" cy="21.7778" r="21.7778" fill="white" />
        <path d="M26.2773 7C18.0273 7 11.2773 13.762 11.2773 22.0904C11.2773 29.6205 16.7673 35.8705 23.9373 37V26.4578H20.1273V22.0904H23.9373V18.762C23.9373 14.9819 26.1723 12.9036 29.6073 12.9036C31.2423 12.9036 32.9523 13.1898 32.9523 13.1898V16.9096H31.0623C29.2023 16.9096 28.6173 18.0693 28.6173 19.2591V22.0904H32.7873L32.1123 26.4578H28.6173V37C32.1519 36.4395 35.3706 34.6288 37.6922 31.8946C40.0137 29.1606 41.2854 25.6831 41.2773 22.0904C41.2773 13.762 34.5273 7 26.2773 7Z" fill="#53B62D" />
    </g>
    <defs>
        <filter id="filter0_d_1692_982" x="0.222656" y="0" width="51.5547" height="51.5557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1692_982" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1692_982" result="shape" />
        </filter>
    </defs>
</svg>
    ;

export default FacebookButton;