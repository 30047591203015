// @ts-nocheck
import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import logo from "../../assets/images/logo.png";
import "./contact-form.css";
import { useParamsNavigate } from '../../utils';
import Input from "../../components/Input";
import { Toaster } from 'react-hot-toast';
import axios from 'axios';

const ContactForm = (props) => {
  // const { t, i18n } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [shelter, setShelter] = useState('');
  const [message, setMessage] = useState('');
  // const [selectedStep, setSelectedStep] = useState(1);

  const sendToSlack = () => {
    // Replace 'YOUR_SLACK_WEBHOOK_URL' with your actual webhook URL
    const webhookUrl = 'https://api.petpair.co/contact-form-response';

    // Send the message to Slack
    axios.post(webhookUrl, {name, shelter, email, message})
      .then(response => {
        console.log('Message sent to Slack:', response.data);
        // You can show a success toast or perform any other actions here
      })
      .catch(error => {
        console.error('Error sending message to Slack:', error);
        // Handle error, show error toast, etc.
      });
  };

  //eslint-disable-next-line
  const handleScroll = () => {
    const card: any = document.getElementById(`card-${currentIndex}`);
    const container: any = document.getElementById(`review-cards-container`); // Replace 'yourDivId' with your actual div id

    const cardWidth = card.clientWidth;
    const scrollPosition = container.scrollLeft;
    const newIndex = Math.round(scrollPosition / cardWidth);
    setCurrentIndex(newIndex);
  };

  

  useEffect(() => {

    const targetDiv = document.getElementById('review-cards-container');

    if (targetDiv) {
      targetDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetDiv) {
        targetDiv.removeEventListener('scroll', handleScroll);
      }
    };
    //eslint-disable-next-line
  }, [handleScroll]);



  const navigate = useParamsNavigate();





  useEffect(() => {
    // eslint-disable-next-line
  }, []);




  return (
    <div className="adoption">
      <Toaster />
      <div className="short-email-left">
        <div className="adoption-left-email-header">
          <div className="short-app-steps-container">

            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />

          </div>
          <span className="adoption-contact-left-title">Let's get sheltering</span>
          <span className="adoption-left-description">A member of our team will personally reach out faster than a tail wag.</span>
          <br />
          <br/>
          <div className='app-email-form-container'>
          <Input placeholder='Johnny Doe' label="What's you name?" required value={name} onChange={(e) => { setName(e.target.value) }} />
          </div>
          <br />
          <div className='app-email-form-container'>
          <Input placeholder='john@shelter.org' label="What's you email?" required value={email} onChange={(e) => { setEmail(e.target.value) }} />
          </div>
          <br />
          <div className='app-email-form-container'>
          <Input placeholder='Type here...' label="Which shelter do you represent?" value={shelter} onChange={(e) => { setShelter(e.target.value) }} />
          </div>
          <br />
          <div className='app-email-form-container'>
          <Input placeholder='Type here...' label="Anything else you'd like to share?" value={message} onChange={(e) => { setMessage(e.target.value) }} />
          </div>
          

        </div>
        <br />
        <br />
        <br />
        <div className='contact-form-button'>
              <Button
                title={'Submit'}
                filled
                // location={`/application/step3/${dog.id}?email=${email}`}
                onClick={sendToSlack}
              />
        </div>

        <br />
        <br />
        <br />
      
      </div>

    </div>
  );
};

export default ContactForm;
