import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Button from "../Button";
import OptionsButton from "../OptionsButton";
import LoginModal from "../LoginModal";
import Spinner from "../Spinner";
import Account from "../../assets/svg/Account";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { useParamsNavigate } from "../../utils";

import "./navbar.css";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({ name: "" });
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const params = location.search;

  const getToken = async () => {
    try {
      const token = await localStorage.getItem("token");
      const user = await JSON.parse(localStorage.getItem("user") || "");
      if (user) {
        setUser(user);
      }
      if (token) setLoggedIn(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    getToken();
  }, [showModal]);

  const navigate = useParamsNavigate();

  const handleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleMenuToggle = () => {
    datadogLogs.logger.info(`Hamburger toggle clicked on the navbar.`, {
      user,
      url: window.location.href,
    });
    setMenuOpen((prevState) => !prevState);
  };

  const options = [
    {
      label: "Logout",
      value: (event: any) => {
        event.stopPropagation();
        datadogLogs.logger.info(`User logged out.`, {
          user,
          url: window.location.href,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      },
    },
  ];

  return (
    <div className="navbar-wrapper">
      <nav className={`navigation ${menuOpen ? "open" : ""}`}>
        <a className="brand" href={`/${params}`}>
          <img
            src={logo}
            className="logo"
            alt="Logo"
          />
        </a>
        <div className="navigation-menu">
          <ul>
            <li>
              <a href={`/about${params}`} onClick={() => {
                      datadogLogs.logger.info(`About link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>{t("navigation.about")}</a>
            </li>
            <li>
              <a href={`/shelters${params}`} onClick={() => {
                      datadogLogs.logger.info(`Shelters link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>{t("navigation.shelters")}</a>
            </li>
            <li>
              <a href={`/fosters${params}`} onClick={() => {
                      datadogLogs.logger.info(`Fosters link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>{t("navigation.fosters")}</a>
            </li>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  datadogLogs.logger.info(`Adopter link clicked on the navbar.`, {
                    user,
                    url: window.location.href,
                  });
                  if (loggedIn) navigate("/discovery");
                  else setShowModal(true);
                }}
              >
                {t("navigation.adopters")}
              </span>
            </li>
            <li>
              <a href={`/blog${params}`} onClick={() => {
                      datadogLogs.logger.info(`Blog link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>{t("navigation.news")}</a>
            </li>
          </ul>
        </div>
        {loading ? (
          <Spinner isDone={false} />
        ) : loggedIn ? (
          <div className="navigation-buttons">
            <OptionsButton
              small
              location="/profile"
              title={
                <>
                  <Account className="navbar-account-icon" />
                  {user.name
                    ? user.name.split(" ")[0]
                    : t("navigation.profile")}
                </>
              }
              options={options}
            />
            <div className="available-dogs-button">
              <Button
                small
                filled
                location="/discovery"
                title={t("navigation.availableDogs")}
              />
            </div>
          </div>
        ) : (
          <div className="navigation-buttons">
            <Button small title={t("navigation.login")} onClick={handleModal} />
            <div className="available-dogs-button">
              <Button
                small
                filled
                location="/discovery"
                title={t("navigation.availableDogs")}
              />
            </div>
          </div>
        )}

        <div className="menu-toggle" onClick={handleMenuToggle}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <LoginModal showModal={showModal} handler={setShowModal} />
      </nav>
    </div>
  );
};

export default Navbar;
