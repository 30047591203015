import React from "react";
import "./steps.css";
import { useTranslation } from "react-i18next";

const Steps: React.FC<{
  current: number;
  setCurrent: Function;
  application?: boolean;
  short?: boolean;
}> = ({ current, setCurrent, application, short }) => {
  const { t } = useTranslation();
  return (
    <div className="step-component">
      <div className="step-item" onClick={() => setCurrent(1)}>
        <div className={`step filled`}>1</div>
        <span className="step-label">
          {application || short ? "Your info" : t("user.step1")}
        </span>
      </div>
      <div className="step-separator"></div>
      <div className="step-item" onClick={() => setCurrent(2)}>
        <div className={`step ${current >= 2 && "filled"}`}>2</div>
        <span className="step-label">
          {application ? "Application" : short ? "Password" : t("user.step2")}
        </span>
      </div>
      {!short && <><div className="step-separator"></div>
      <div className="step-item" onClick={() => setCurrent(3)}>
        <div className={`step ${current === 3 && "filled"}`}>3</div>
        <span className="step-label">
          {application || short ? "Finish up" : t("user.step3")}
        </span>
      </div></>}
    </div>
  );
};

export default Steps;
