// @ts-nocheck
import './review-badge.css';
import Quotes from '../../assets/svg/Quotes';

const ReviewBadge = ({ title, description, title2, app }) => {




    return(
        <div className={!app ? 'review-badge' : 'review-badge-app'}>
            <div className='review-badge-contents'>
                <div className='review-badge-title-container'>
                <span className='review-badge-title'>{`${title}`}</span>
                <span className='review-badge-title2'>PetPair Adopter</span>
                </div>
                <div className='review-badge-description-container'>
                <div>
                    <Quotes />
                </div>
                <span className='review-badge-description'>{description}</span>
                </div>
            </div>
        </div>
    )
};

export default ReviewBadge;
