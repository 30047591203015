import React from "react";

const HealthFilled = (props: any) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_562_47498)">
      <rect x="9" y="9" width="32" height="32" rx="16" fill="white" />
      <g filter="url(#filter1_b_562_47498)">
        <path
          d="M29.4419 17C26.4666 17 25 19.9091 25 19.9091C25 19.9091 23.5334 17 20.5581 17C18.14 17 16.2252 19.0064 16.2005 21.4004C16.1501 26.37 20.1754 29.904 24.5875 32.874C24.7092 32.9561 24.8529 33 25 33C25.1471 33 25.2909 32.9561 25.4125 32.874C29.8242 29.904 33.8495 26.37 33.7996 21.4004C33.7748 19.0064 31.86 17 29.4419 17Z"
          fill="#53B62D"
        />
        <path
          d="M29.4419 17C26.4666 17 25 19.9091 25 19.9091C25 19.9091 23.5334 17 20.5581 17C18.14 17 16.2252 19.0064 16.2005 21.4004C16.1501 26.37 20.1754 29.904 24.5875 32.874C24.7092 32.9561 24.8529 33 25 33C25.1471 33 25.2909 32.9561 25.4125 32.874C29.8242 29.904 33.8495 26.37 33.7996 21.4004C33.7748 19.0064 31.86 17 29.4419 17Z"
          stroke="#46A522"
          strokeWidth="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_562_47498"
        x="0"
        y="0"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_562_47498"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_562_47498"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_562_47498"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_562_47498"
        x="11.45"
        y="12.25"
        width="27.1"
        height="25.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_562_47498"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_562_47498"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default HealthFilled;
