import React from 'react';
import './badge.css'


const Badge: React.FC<{ Icon: any, text: any }> = ({Icon, text}) => {
    return(
        <div className='badge'>
            <Icon />
            <span className='badge-text'>{text}</span>
        </div>
    )
}

export default Badge;
