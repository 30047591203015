const WeightGreenIcon = () => <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6995 6.29841C21.3123 6.29841 20.9502 6.40322 20.6243 6.57455C20.3022 5.54833 19.3968 4.80078 18.3195 4.80078C16.975 4.80078 15.8812 5.95759 15.8812 7.37968L15.8809 10.9991H12.2257H8.25713V7.37968C8.25713 5.95759 7.16334 4.80078 5.81862 4.80078C4.74124 4.80078 3.83519 5.54805 3.51348 6.57392C3.18755 6.40329 2.8261 6.29849 2.4385 6.29849C1.09405 6.29849 0 7.4553 0 8.87739V15.2842C0 16.7063 1.09378 17.8634 2.4385 17.8634C2.82572 17.8634 3.18786 17.7586 3.51348 17.5873C3.83592 18.6135 4.7416 19.3611 5.81862 19.3611C7.16307 19.3611 8.25713 18.204 8.25713 16.7818V13.5009H12.069H15.8812L15.8809 16.7818C15.8809 18.2039 16.9743 19.3611 18.3191 19.3611C19.3965 19.3611 20.3026 18.6138 20.6239 17.5873C20.9495 17.7586 21.312 17.8634 21.6992 17.8634C23.044 17.8634 24.1384 16.7064 24.1384 15.2842L24.138 8.87739C24.1384 7.4553 23.0442 6.29841 21.6995 6.29841ZM2.43841 16.3662C1.91958 16.3662 1.49751 15.8811 1.49751 15.2843L1.49716 8.87749C1.49716 8.28135 1.91955 7.79628 2.43807 7.79628C2.9569 7.79628 3.37897 8.28134 3.37897 8.87749V15.2843C3.37932 15.8808 2.95692 16.3662 2.43841 16.3662ZM6.75944 16.782C6.75944 17.3785 6.33705 17.8639 5.81853 17.8639C5.299 17.8639 4.87665 17.3788 4.87665 16.782L4.8763 7.38008C4.8763 6.78393 5.29869 6.29886 5.81818 6.29886C6.33702 6.29886 6.75909 6.78392 6.75909 7.38008L6.75944 16.782ZM19.26 16.782C19.26 17.3785 18.8376 17.8639 18.3185 17.8639C17.7997 17.8639 17.378 17.3788 17.378 16.782V7.38008C17.378 6.78393 17.7996 6.29886 18.3185 6.29886C18.8377 6.29886 19.26 6.78392 19.26 7.38008V16.782ZM22.6405 15.2843C22.6405 15.8808 22.2181 16.3662 21.699 16.3662C21.1798 16.3662 20.7575 15.8811 20.7575 15.2843L20.7578 8.8775C20.7578 8.28136 21.1802 7.79628 21.6993 7.79628C22.2185 7.79628 22.6409 8.28135 22.6409 8.8775L22.6405 15.2843Z" fill="#53B62D"/>
<rect x="4.49902" y="6" width="3" height="12" rx="1.5" fill="#53B62D"/>
<rect width="4.5" height="9" rx="2.25" transform="matrix(-1 0 0 1 4.49902 7.5)" fill="#53B62D"/>
<rect width="3" height="12" rx="1.5" transform="matrix(-1 0 0 1 19.499 6)" fill="#53B62D"/>
<rect x="19.499" y="7.5" width="4.5" height="9" rx="2.25" fill="#53B62D"/>
</svg>;



export default WeightGreenIcon;
