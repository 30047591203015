// @ts-nocheck 
import React, { useState, useEffect, useRef } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import InputMask from "react-input-mask";
import { MapContainer, TileLayer, Circle } from "react-leaflet";
// eslint-disable-next-line
import { datadogLogs } from "@datadog/browser-logs";
import "./checkout.css";
// eslint-disable-next-line
import PaymentTiles from "../../components/PaymentTile";
import creditCardType from "credit-card-type";
import { Divider } from "antd";
import toast, { Toaster } from 'react-hot-toast';
import { useParamsNavigate, states } from '../../utils';
// eslint-disable-next-line
import CreditCard from "../../assets/svg/CreditCard";
import logo from "../../assets/images/deals-logo.png";
import { getFirstProduct } from '../../service/actions';
import DogIcon from "../../assets/svg/DogIcon";
import { makeProductPayment, checkPurchaseLimit } from '../../service/actions';
import { useLocation } from "react-router-dom";


const Checkout = (props) => {
  const [creditCardNumber, setCreditCardNumber] = useState<any>("");
  const [creditCardBrand, setCreditCardBrand] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // eslint-disable-next-line
  const [center, setCenter] = useState<any>();
  const radius = 5000;
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [birthdate, setBirthdate] = useState('');
  // eslint-disable-next-line
  const [selectedPayment, setSelectedPayment] = useState('card');  
  const [email, setEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [product, setProduct] = useState();
  const [productCount, setProductCount] = useState(1);
  const [salesTax, setSalesTax] = useState(0);
  const [maxUnits, setMaxUnits] = useState(3);
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const routerLocation = useLocation();

  const inputRef = useRef();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressLine1Change = (e) => {
    setAddressLine1(e.target.value);
  };

  const handleAddressLine2Change = (e) => {
    setAddressLine2(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleZipChange = (e) => {
    setZip(e.target.value);
  };


  const navigate = useParamsNavigate();

  const fetchFirstProduct = async () => {
    try {
        const product = await getFirstProduct();
        setProduct(product);
        console.log('First product:', product);
    } catch (err) {
        console.error('Error fetching first product:', err);
    }
  };

  useEffect(() => {
    datadogLogs.logger.info(`Deals checkout page loaded!`, {
        url: window.location.href,
      });
}, []);

  const handleConfirmPayment = async () => {
    try {
      // Validate email format
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || !emailPattern.test(email)) {
        toast.error("Please enter a valid email address", { style: { fontWeight: 500 } });
        return;
      }
  
      // Validate all other fields
      if (!addressLine1 || !city || !state || !zip || !creditCardNumber || !expiry || !cvc || !postalCode) {
        toast.error("Please fill in all required fields", { style: { fontWeight: 500 } });
        return;
      }
  
      // Perform additional validation if needed
  
      setLoading(true);
      // Prepare payment data
      const paymentData = {
        amount: parseInt(((product.price * productCount) + product.flatShippingFee + parseFloat(salesTax)).toFixed(2) * 100), // Just a placeholder, replace it with your actual amount calculation
        productName: product.name,
        buyerName: email, // Assuming email as buyer's name for simplicity
        cardNumber: creditCardNumber,
        cvc,
        expiry: { month: expiry.slice(0, 2), year: expiry.slice(3) },
        quantity: productCount,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        email,
        productId: product.id
      };
  
      // Make the payment
      await makeProductPayment(paymentData);
      toast.success("Payment successful", { style: { fontWeight: 500 } });

      navigate(`/deals/confirm?email=${email}&productId=${product.id}&quantity=${productCount}`);
  
      // Handle payment success
      // You can add your logic here, such as showing a success message to the user
  
      setIsDone(true);
      setLoading(false);
    } catch (error) {
      // Handle payment failure
      // You can add your logic here, such as showing an error message to the user
      console.error('Payment failed:', error);
      toast.error("Payment failed", { style: { fontWeight: 500 } });
      setLoading(false);
    }
  };

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    fetchFirstProduct();
    const email = queryParams.get('email');
    if(email)
      setEmail(email);
  
  // eslint-disable-next-line
  }, []);

useEffect(() => {
  const checkUserPurchaseLimit = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailPattern.test(email)) {
      return;
    }
    try {
      if (product && email && !alreadyLoaded) {
        // Assuming you have access to the user's email and product ID
        const { canPurchaseMore, purchasedUnits } = await checkPurchaseLimit(email, product.id);
        const maxPerProduct =  product.maxPurchasableAmount ? product.maxPurchasableAmount : 3;
        const max = maxPerProduct - purchasedUnits;
        if(!canPurchaseMore) {
          navigate('/deals?email=' + email)
        } else {
          setMaxUnits(max);
          console.log('Can purchase more:', canPurchaseMore.canPurchaseMore);
        }

        setAlreadyLoaded(true);
        // Do something based on the value of canPurchaseMore
      }
    } catch (error) {
      console.error('Error checking purchase limit:', error);
    }
  };

  checkUserPurchaseLimit();

// eslint-disable-next-line
}, [product, email]);


useEffect(() => {
  if(product){
    setSalesTax((parseFloat((product.price * productCount)*0.0825).toFixed(2)));
    setMaxUnits(product.maxPurchasableAmount || 3);
  }
}, [product, productCount])

  const handleCreditCardChange = (e) => {
    const creditCardNumber = e.target.value;
    setCreditCardNumber(creditCardNumber);

    const unmaskedNumber = creditCardNumber
      .replaceAll("_", "")
      .replaceAll(" ", "");

    // Use the credit-card-type library to detect the brand from the credit card number
    const cardTypeInfo = creditCardType(creditCardNumber.replaceAll(" ", ""));
    if (cardTypeInfo.length > 0 && unmaskedNumber.length > 3) {
      const brand = cardTypeInfo[0].type;
      console.log(brand);
      setCreditCardBrand(brand);
    } else {
      setCreditCardBrand(""); // If no brand detected, set it as an empty string
    }
  };

  const handleExpiryChange = (e) => {
    setExpiry(e.target.value);
  };

  const handleBirthdateChange = (e) => {
    setBirthdate(e.target.value);
  };

  const handleCvcChange = (e) => {
    if (e.target.value.length < 5) setCvc(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    if (e.target.value.length < 6) setPostalCode(e.target.value);
  };


  const handleIncrement = () => {
    if (productCount < maxUnits) {
      setProductCount(prevCount => prevCount + 1);
    }
  };

  const handleDecrement = () => {
    if (productCount > 1) {
      setProductCount(prevCount => prevCount - 1);
    }
  };

  if(!product){
    return;
  }

  return (
    <div className="checkout">
      <Toaster />
      <div className="checkout-left">
        <div className="checkout-left-header">
          <div className='deals-header'>
            <div className='deals-logo-container'>
              <img className='deals-logo' src={logo} alt='PetPair' />
            </div>
          </div>
          <br />
          <span className="checkout-left-title">
            Checkout
          </span>
          <br/>
          <div className='deals-checkout-cart'>
            <img className='deals-checkout-cart-image' src={product.images[0]} alt='PetPair Product'/ >
            <div className='deals-checkout-cart-data'>
              <span className='deals-checkout-cart-title'><span className='deals-checkout-cart-title-green'>DEAL </span>{product.name}</span>
              <div className='deals-checkout-cart-line2'>
                <div className='deals-checkout-cart-quantity-selector'>
                <div className='deals-checkout-cart-selector-button' onClick={handleDecrement}>-</div>
      <div className='deals-checkout-cart-counter'>{productCount}</div>
      <div className='deals-checkout-cart-selector-button2' onClick={handleIncrement}>+</div>
                </div>
                <span className='deals-checkout-max-indicator'>Max {maxUnits}</span>
              </div>
              <div className='deals-checkout-cart-line3'>
                <span>${product.price * productCount}</span>
                <span className='deals-checkout-og-price'>${(product.ogPrice * productCount).toFixed(2)}</span>
              </div>
            </div>
          </div>
          <br/>
          <div className='deals-checkout-input-container'>
        <Input
          label='Your email'
          required
          placeholder='your@email.com'
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <br />
      <div className='deals-checkout-input-container'>
        <Input
          label='Your shipping address'
          required
          placeholder='Address line 1'
          value={addressLine1}
          onChange={handleAddressLine1Change}
        />
      </div>
      <div className='deals-checkout-input-container'>
        <Input
          required
          placeholder='Address line 2'
          value={addressLine2}
          onChange={handleAddressLine2Change}
        />
      </div>
      <div className="checkout-card-details">
        <Input
          placeholder='City'
          value={city}
          onChange={handleCityChange}
        />
        <Input
          placeholder='State'
          value={state}
          onChange={handleStateChange}
          autoComplete
          suggestions={states}
          setInputValue={setState}
          ref={inputRef}
        />
        <Input
          placeholder='Zip'
          value={zip}
          onChange={handleZipChange}
        />
      </div>
          <br/>
          <div className='checkout-left-title'><span>Payment details </span><span className='required-star'>*</span></div>
          <br />
          <div className='payment-menu'>
            {/* <PaymentTiles icon={<CreditCard />} title={'Card'} isSelected={selectedPayment === 'card'} onClick={() => { setSelectedPayment('card') }} /> */}
            {/* <PaymentTiles icon={<AffirmIcon />} title={'Buy now, pay later'} isSelected={selectedPayment === 'affirm'} onClick={() => { setSelectedPayment('affirm') }} /> */}
          </div>

          {selectedPayment === 'card' && <div>
            <div className="checkout-form-container">
              <InputMask
                mask="9999 9999 9999 9999"
                value={creditCardNumber}
                onChange={handleCreditCardChange}
              >
                {(inputProps) => (
                  <Input
                    label="Card number"
                    {...inputProps}
                    placeholder="1234 1234 1234 1234"
                    creditCard={true}
                    brand={creditCardBrand}
                  />
                )}
              </InputMask>
              <br />
            </div>
            <div className="checkout-card-details">
              <InputMask
                mask="99/99"
                value={expiry}
                onChange={handleExpiryChange}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    label="Expiry"
                    placeholder="MM/YY"
                    value={expiry}
                    onChange={handleExpiryChange}
                  />
                )}
              </InputMask>
              <Input
                label="CVC"
                placeholder="CVC"
                value={cvc}
                onChange={handleCvcChange}
              />
              <Input
                label="Postal code"
                placeholder="90210"
                value={postalCode}
                onChange={handlePostalCodeChange}
              />
            </div>
          </div>}
          {selectedPayment === 'affirm' && <div>
            <div className="checkout-form-container">
              <InputMask
                mask="99/99/9999"
                value={birthdate}
                onChange={handleBirthdateChange}
              >
                {(inputProps) => (
                  <Input
                    label="Birthdate"
                    {...inputProps}
                    placeholder="MM/DD/YYYY"
                    brand={birthdate}
                  />
                )}
              </InputMask>
              <br />
            </div>
          </div>}
          <br />
          <span className="checkout-left-title">
            Confirmation
          </span>
          <br />
          <div className="checkout-payment-breakdown">
            <div className="checkout-price-entry">
              <span className="product-name">{product.name} x{productCount}</span>
              <span className="product-price">{`$${product.price * productCount}`}</span>
            </div>
            <div className="checkout-price-entry">
              <span className="product-name">Flat shipping</span>
              <span className="product-price">{`$${product.flatShippingFee}`}</span>
            </div>
            <div className="checkout-price-entry">
              <span className="product-name">Sales tax (8.25%)</span>
              <span className="product-price">{`$${salesTax}`}</span>
            </div>
          </div>
          <Divider><DogIcon /></Divider>
          <div className="checkout-price-entry">
            <span className="product-name">Total</span>
            <span className="product-price">{`$${((product.price * productCount) + product.flatShippingFee + parseFloat(salesTax)).toFixed(2)}`}</span>
          </div>
          <br />
          {selectedPayment === 'affirm' && <span className='affirm-disclaimer'>The Klarna website will open in a new browser window to complete your purchase. You will then be redirected back to PetPair to confirm pickup and/or transport details. <a className='affirm-link' href='/blog/post/paying-with-affirm'>Learn more</a></span>}

          <br />
          <div className="checkout-button-wrapper">
            {loading ? (
              <Spinner isDone={isDone} />
            ) : (
              <div className="checkout-button">
                <Button
                  title={selectedPayment === 'card' ? "Purchase!" : 'Continue with Klarna'}
                  filled
                  medium
                  onClick={handleConfirmPayment}
                />
              </div>
            )}
            <div className="checkout-button">
              <Button title="Back" onClick={() => navigate("/deals")} medium />
            </div>
          </div>
        </div>
      </div>
      <div className="checkout-right">
        {center ? ( // <-- Check if 'center' is available
          <MapContainer
            center={center as any}
            zoom={12}
            minZoom={5}
            maxZoom={16}
            className="dogprofile-map-checkout"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={center as any} radius={radius} color="#53B62D" />
          </MapContainer>
        ) : (
          <div className="map-loader">
            {/* <Spinner /> */}
          </div> // <-- You can show a loading message while 'center' is null
        )}
      </div>
    </div>
  );
};

export default Checkout;
