import React from "react";

const DropdownDownArrow = () => (
  <svg
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.10488 0.628984L5.49988 4.02398L8.89488 0.628984C9.23613 0.287734 9.78738 0.287734 10.1286 0.628984C10.4699 0.970234 10.4699 1.52148 10.1286 1.86273L6.11238 5.87898C5.77113 6.22023 5.21988 6.22023 4.87863 5.87898L0.862383 1.86273C0.521133 1.52148 0.521133 0.970234 0.862383 0.628984C1.20363 0.296484 1.76363 0.287734 2.10488 0.628984Z"
      fill="#787878"
    />
  </svg>
);

export default DropdownDownArrow;
