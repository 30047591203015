const AffirmIcon = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	width="45px" height="34px" viewBox="0 0 1460 340" enable-background="new 0 0 1460 340" >
	<g id="UI">
		<g id="Product---business-_x28_desktop_x29_" transform="translate(-676.000000, -1589.000000)">
			<path id="Page-1" d="M987.655,1921.352h70.457v-330.572h-70.457V1921.352z M1433.89,1730.521v-29.354h-1.174l0,0h-61.652l0,0
			h-3.521v220.77h66.347v-115.671c0-33.469,37.58-48.146,64.002-41.099h0.585v-64
			C1471.47,1700.576,1446.809,1712.322,1433.89,1730.521L1433.89,1730.521z M1207.25,1870.266c-34.643,0-62.239-26.42-62.239-59.303
			c0-32.878,28.182-59.303,62.239-59.303c34.642,0,62.237,26.425,62.237,59.303
			C1269.487,1843.846,1241.303,1870.266,1207.25,1870.266L1207.25,1870.266z M1268.9,1715.845
			c-18.789-13.504-42.275-21.138-67.523-21.138c-64.585,0-116.255,52.259-116.255,116.259c0,63.998,52.256,116.255,116.255,116.255
			c25.248,0,48.146-8.22,67.523-21.138v15.269h57.542v-220.772H1268.9V1715.845z M1664.055,1694.707
			c-26.424,0-51.672,8.219-68.111,30.534v-24.665h-1.173l0,0h-61.652l0,0h-3.522v220.775h66.348v-120.958
			c0-33.466,22.313-49.907,49.91-49.907c28.77,0,45.797,17.618,45.797,49.321v120.951h66.351v-140.327
			C1758.001,1729.937,1716.896,1694.707,1664.055,1694.707L1664.055,1694.707z M2092.677,1843.258
			c-22.9,0-41.688,18.788-41.688,41.687c0,22.899,18.788,41.688,41.688,41.688c22.897,0,41.687-18.789,41.687-41.688
			C2134.363,1861.458,2115.574,1843.258,2092.677,1843.258L2092.677,1843.258z M1903.026,1870.266
			c-34.643,0-62.238-26.42-62.238-59.303c0-32.878,28.181-59.303,62.238-59.303c34.642,0,62.239,26.425,62.239,59.303
			C1965.852,1843.846,1937.665,1870.266,1903.026,1870.266L1903.026,1870.266z M1965.266,1715.845
			c-18.79-13.504-42.275-21.138-67.521-21.138c-64.592,0-116.26,52.259-116.26,116.259c0,63.998,52.256,116.255,116.26,116.255
			c25.245,0,48.143-8.22,67.521-21.138v15.269h57.538v-220.772h-57.538V1715.845z M2064.494,1704.688h2.936
			c1.759,0,2.935,1.177,2.935,2.35c0,1.762-0.588,2.351-2.935,2.351h-2.936V1704.688z M2064.494,1712.322h2.936l2.935,6.457h2.938
			l-2.938-6.457c1.761-0.588,2.938-2.35,2.938-4.696c0-2.938-2.351-5.288-5.873-5.288h-5.872v16.441h2.937V1712.322z
			 M677.637,1921.352h73.395v-330.572h-73.395V1921.352z M939.507,1590.779h-71.044c-1.175,74.57-44.624,139.157-106.863,170.864
			l109.797,159.708h89.248l-99.817-145.031C910.149,1727.587,938.334,1661.827,939.507,1590.779L939.507,1590.779z
			 M2066.842,1697.646c7.046,0,12.915,5.869,12.915,12.915s-5.869,12.919-12.915,12.919c-7.044,0-12.919-5.873-12.919-12.919
			S2059.798,1697.646,2066.842,1697.646L2066.842,1697.646z M2066.842,1726.414c8.807,0,15.853-7.046,15.853-15.854
			s-7.046-15.854-15.853-15.854c-8.808,0-15.854,7.046-15.854,15.854S2058.034,1726.414,2066.842,1726.414L2066.842,1726.414z"/>
		</g>
	</g>
</svg>

export default AffirmIcon;
