// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import Input from "../../components/Input";
import logo from "../../assets/images/logo.png";
import {
  getUserByEmail,
  registerSocial,
  createFosterApplication,
  updateFosterApplication,
  updateUser
} from "../../service/actions";
import "./quizxp.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";
import toast, { Toaster } from 'react-hot-toast';
import { usePlacesWidget } from "react-google-autocomplete";


import InputMask from "react-input-mask";

const ApplicationEntryPoint = (props) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modifier, setModifier] = useState('');
  const [preferredSize, setPreferredSize] = useState([]);
  const [dogMustBeGoodWith, setDogMustBeGoodWith] = useState([]);
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(true);
  const [carAccess, setCarAccess] = useState('');
  const [homeType, sethomeType] = useState('');
  const [preferredAge, setPreferredAge] = useState([]);
  const [energyLevels, setEnergyLevels] = useState([]);
  const [comfortableWithIssues, setComfortableWithIssues] = useState([]);
  const [interestedInFosterToAdopt, setInterestedToAdopt] = useState('');
  const [motivation, setMotivation] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [fosterApp, setFosterApp] = useState();
  const [token, setToken] = useState('');

  const { ref: locationRef2 } = usePlacesWidget({
    apiKey: `AIzaSyDMfA1xyYe0k6Ajf9uqkYNyjB3n813mzd0`,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
    options: {
      types: 'locality'
    }
  });

  useEffect(() => {

    datadogLogs.logger.info(
      `Foster application page loaded.`,
      { url: window.location.href }
    );

    // eslint-disable-next-line
  }, []);



  const navigate = useParamsNavigate();


  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (token && (name || phone || location)) handleUserUpdate();
    }, 500);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [name, phone, location]);

  
  const handleUserUpdate = async () => {
    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateUser(email, phone, name, location, token);

      // Handle the result as per your requirement
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data));
        console.log("Adoption updated successfully", result);
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };



  const handleDogMustBeGoodWithChange = (value, isChecked) => {
    if (isChecked) {
      setDogMustBeGoodWith((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setDogMustBeGoodWith((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };


  const handlePreferredSizeChange = (value, isChecked) => {
    if (isChecked) {
      setPreferredSize((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setPreferredSize((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handleEnergyLevels = (value, isChecked) => {
    if (isChecked) {
      setEnergyLevels((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setEnergyLevels((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };


  const handlePreferredAge = (value, isChecked) => {
    if (isChecked) {
      setPreferredAge((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setPreferredAge((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handleIssues = (value, isChecked) => {
    if (isChecked) {
      setComfortableWithIssues((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setComfortableWithIssues((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const routerLocation = useLocation();

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    if (modifierParam) setModifier(modifierParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);

  const handleEmailRequest = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email.", { style: { fontWeight: 500 } });
      return;
    }

    try {
      // Check if the user exists
      const userData = await getUserByEmail(email);
      console.log(userData);
      // User exists, create foster application
      const app = await createFosterApplication(userData.data.user.id);

      datadogLogs.logger.info(
        `Foster application successfully created. Email: ${email}`,
        { url: window.location.href, email }
      );

      setShowEmail(false);
      setName(userData.data.user.name);
      setPhone(userData.data.user.phone);
      setLocation(userData.data.user.address);
      setToken(userData?.data.token);

      console.log(app);

      setFosterApp(app.data);



    } catch (err) {
      if (err.response && err.response.status === 404) {
        try {
          // User doesn't exist, register a new user
          const data = await registerSocial({ email, type: 'FOSTER' });
          console.log(data);
          // New user registered, create foster application
          const app = await createFosterApplication(data?.data.user.id);

          setName(data?.data.user.name);
          setPhone(data?.data.user.phone);
          setLocation(data?.data.user.address);
          
          setToken(data.data.token);

          setShowEmail(false);

          setFosterApp(app.data);

        } catch (err) {
          console.log(err);
        }
      } else {
        toast.error(
          "We could not process your request at this time, please try again later.", { style: { fontWeight: 500 } }
        );
      }
    }
  };

  useEffect(() => {
    const debounceUpdate = setTimeout(() => {
      if (fosterApp) {
        console.log('Trying to update application', fosterApp)
        updateFosterApplication(fosterApp.id, {
          preferredSize: preferredSize.join(', '),
          dogMustBeGoodWith: dogMustBeGoodWith.join(', '), carAccess, homeType, preferredAge: preferredAge.join(', '), energyLevels: energyLevels.join(', '), comfortableWithIssues: comfortableWithIssues.join(', '), interestedInFosterToAdopt: interestedInFosterToAdopt, motivation
        });
      }
    }, 500);

    return () => clearTimeout(debounceUpdate);
    // eslint-disable-next-line
  }, [preferredSize, dogMustBeGoodWith, carAccess, homeType, preferredAge, energyLevels, comfortableWithIssues, interestedInFosterToAdopt, motivation]);


  return (
    <div className="adoption">
      <Toaster />
      <div className="short-app-left">
        <div className="adoption-left-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
          </div>
          <div>
            <div className="short-adoption-header">
              <span className="adoption-left-title">{t('fosterApplication.fosterFormTitle')}</span>
              <span className="adoption-left-description">
                {t('fosterApplication.fosterFormDescription')}
              </span>
            </div>

            <div className="adoption-quiz-form-container">
              <br />
              <Input disabled={!showEmail} value={email} label={'Email'} onChange={(e) => {
                setEmail(e.target.value)
              }} placeholder={t('user.email')} onBlur={handleEmailRequest} required />
              <br />
              <Input value={name} onChange={(e) => {
                setName(e.target.value)
              }} placeholder='Enter your name' required label={t('user.nameLabel')} />
              <br />
              <InputMask
                mask="(999) 999-9999"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    label={t("user.phoneLabel")}
                    {...inputProps}
                    placeholder="(440) 555-1234"
                    value={phone}
                    // onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                )}
              </InputMask>
              <br />
              <Input value={location} onChange={(e) => {
                setLocation(e.target.value)
              }} placeholder={t('user.location')} required label={t('user.locationLabel')} ref={locationRef2} />
              <br />
              <Input value={motivation} onChange={(e) => {
                setMotivation(e.target.value)
              }} placeholder='Tell us' required label='Why do you want to foster?' />
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>Are you interested in foster to adopt?</p>
              </div>
              <div className="app-radio-options-vertical">
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="fosterToAdopt"
                    value="Yes"
                    id="foster-adopt-yes"
                    checked={interestedInFosterToAdopt === "Yes"}
                    onChange={(e) => setInterestedToAdopt(e.target.value)}
                  />
                  <label htmlFor="foster-adopt-yes">Yes</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="fosterToAdopt"
                    value="Maybe"
                    id="foster-adopt-maybe"
                    checked={interestedInFosterToAdopt === "Maybe"}
                    onChange={(e) => setInterestedToAdopt(e.target.value)}
                  />
                  <label htmlFor="foster-adopt-maybe">{'Maybe'}</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="fosterToAdopt"
                    value="No"
                    id="foster-adopt-no"
                    checked={interestedInFosterToAdopt === "No"}
                    onChange={(e) => setInterestedToAdopt(e.target.value)}
                  />
                  <label htmlFor="foster-adopt-no">{'No'}</label>
                </div>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>What kind of building do you live in?</p>
              </div>
              <div className="app-radio-options-vertical">
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="homeType"
                    value="Apartment"
                    id="kind-building-apartment"
                    checked={homeType === "Apartment"}
                    onChange={(e) => sethomeType(e.target.value)}
                  />
                  <label htmlFor="kind-building-apartment">Apartment</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="homeType"
                    value="Condo"
                    id="kind-building-condo"
                    checked={homeType === "Condo"}
                    onChange={(e) => sethomeType(e.target.value)}
                  />
                  <label htmlFor="kind-building-condo">{'Condo'}</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="homeType"
                    value="Duplex"
                    id="kind-building-duplex"
                    checked={homeType === "Duplex"}
                    onChange={(e) => sethomeType(e.target.value)}
                  />
                  <label htmlFor="kind-building-duplex">{'Duplex'}</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="homeType"
                    value="House"
                    id="kind-building-house"
                    checked={homeType === "House"}
                    onChange={(e) => sethomeType(e.target.value)}
                  />
                  <label htmlFor="kind-building-house">{'House'}</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="homeType"
                    value="Other"
                    id="kind-building-other"
                    checked={homeType === "Other"}
                    onChange={(e) => sethomeType(e.target.value)}
                  />
                  <label htmlFor="kind-building-other">{'Other'}</label>
                </div>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>Are there any children or animals at home?</p>
              </div>
              <div className="checkbox-grid">
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={dogMustBeGoodWith.includes("Cats")}
                    onChange={(e) =>
                      handleDogMustBeGoodWithChange(
                        "Cats",
                        e.target.checked
                      )
                    }
                  />
                  Cats
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={dogMustBeGoodWith.includes("Kids")}
                    onChange={(e) =>
                      handleDogMustBeGoodWithChange(
                        "Kids",
                        e.target.checked
                      )
                    }
                  />
                  Kids
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={dogMustBeGoodWith.includes("Dogs")}
                    onChange={(e) =>
                      handleDogMustBeGoodWithChange(
                        "Dogs",
                        e.target.checked
                      )
                    }
                  />
                  Dogs
                </label>
                {/* Add more checkboxes and labels as needed */}
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>{t('fosterApplication.carAccessQuestion')}</p>
              </div>
              <div className="app-radio-options-vertical">
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="carAccess"
                    value={t('fosterApplication.carAccessAnswer1')}
                    id="car-access-yes"
                    checked={carAccess === t('fosterApplication.carAccessAnswer1')}
                    onChange={(e) => setCarAccess(e.target.value)}
                  />
                  <label htmlFor="car-access-yes">{t('fosterApplication.carAccessAnswer1')}</label>
                </div>
                <div className="app-radio-option-vertical">
                  <input
                    type="radio"
                    name="carAccess"
                    value={t('fosterApplication.carAccessAnswer2')}
                    id="car-access-no"
                    checked={carAccess === t('fosterApplication.carAccessAnswer2')}
                    onChange={(e) => setCarAccess(e.target.value)}
                  />
                  <label htmlFor="car-access-no">{t('fosterApplication.carAccessAnswer2')}</label>
                </div>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>{t('fosterApplication.sizePreferenceQuestion')}</p>
              </div>
              <div className="checkbox-list">
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredSize.includes(t('fosterApplication.sizePreferenceAnswer1'))}
                    onChange={(e) =>
                      handlePreferredSizeChange(
                        t('fosterApplication.sizePreferenceAnswer1'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.sizePreferenceAnswer1')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredSize.includes(t('fosterApplication.sizePreferenceAnswer2'))}
                    onChange={(e) =>
                      handlePreferredSizeChange(
                        t('fosterApplication.sizePreferenceAnswer2'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.sizePreferenceAnswer2')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredSize.includes(t('fosterApplication.sizePreferenceAnswer3'))}
                    onChange={(e) =>
                      handlePreferredSizeChange(
                        t('fosterApplication.sizePreferenceAnswer3'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.sizePreferenceAnswer3')}
                </label>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>{t('fosterApplication.agePreferenceQuestion')}</p>
              </div>
              <div className="checkbox-list">
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredAge.includes(t('fosterApplication.agePreferenceAnswer1'))}
                    onChange={(e) =>
                      handlePreferredAge(
                        t('fosterApplication.agePreferenceAnswer1'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.agePreferenceAnswer1')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredAge.includes(t('fosterApplication.agePreferenceAnswer2'))}
                    onChange={(e) =>
                      handlePreferredAge(
                        t('fosterApplication.agePreferenceAnswer2'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.agePreferenceAnswer2')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={preferredAge.includes(t('fosterApplication.agePreferenceAnswer3'))}
                    onChange={(e) =>
                      handlePreferredAge(
                        t('fosterApplication.agePreferenceAnswer3'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.agePreferenceAnswer3')}
                </label>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>{t('fosterApplication.energyPreferenceQuestion')}</p>
              </div>
              <div className="checkbox-list">
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={energyLevels.includes(t('fosterApplication.energyPreferenceAnswer1'))}
                    onChange={(e) =>
                      handleEnergyLevels(
                        t('fosterApplication.energyPreferenceAnswer1'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.energyPreferenceAnswer1')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={energyLevels.includes(t('fosterApplication.energyPreferenceAnswer2'))}
                    onChange={(e) =>
                      handleEnergyLevels(
                        t('fosterApplication.energyPreferenceAnswer2'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.energyPreferenceAnswer2')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={energyLevels.includes(t('fosterApplication.energyPreferenceAnswer3'))}
                    onChange={(e) =>
                      handleEnergyLevels(
                        t('fosterApplication.energyPreferenceAnswer3'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.energyPreferenceAnswer3')}
                </label>
              </div>
              <br />
              <div className='adoption-quiz-title'>
                <p className='adoption-quiz-title-text'>{t('fosterApplication.behaviorPreferenceQuestion')}</p>
              </div>
              <div className="checkbox-list">
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={comfortableWithIssues.includes(t('fosterApplication.behaviorPreferenceAnswer1'))}
                    onChange={(e) =>
                      handleIssues(
                        t('fosterApplication.behaviorPreferenceAnswer1'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.behaviorPreferenceAnswer1')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={comfortableWithIssues.includes(t('fosterApplication.behaviorPreferenceAnswer2'))}
                    onChange={(e) =>
                      handleIssues(
                        t('fosterApplication.behaviorPreferenceAnswer2'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.behaviorPreferenceAnswer2')}
                </label>
                <label className='checkbox-input-container'>
                  <input
                    type="checkbox"
                    className="adoption-checkbox"
                    checked={comfortableWithIssues.includes(t('fosterApplication.behaviorPreferenceAnswer3'))}
                    onChange={(e) =>
                      handleIssues(
                        t('fosterApplication.behaviorPreferenceAnswer3'),
                        e.target.checked
                      )
                    }
                  />
                  {t('fosterApplication.behaviorPreferenceAnswer3')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="adoption-email-mobile-button-wrapper">
        {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
        {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
        <div className='app-entry-mobile-bar-wrapper'>
          <Button
            title={t('adoptionCheckout.screen-8-payment-back')}
            medium
            location={'/fosters'}
          />
          <Button
            title={t('application_checkout.newApplicationQuizButton')}
            filled
            medium
            onClick={() => {
              if (email && phone && location && name && motivation) {
                navigate(`/discovery`)
              } else {
                toast.error("Please complete the form", { style: { fontWeight: 500 } });
              }
            }}
          />
        </div>
      </div>



      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >

      </Modal>

    </div>
  );
};

export default ApplicationEntryPoint;
