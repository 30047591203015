const ExpandIcon = () => 
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1880_3666)">
<circle cx="19.5" cy="18.5" r="15.5" fill="white"/>
<path d="M24.7431 19.3197C25.0602 19.3233 25.2985 19.0712 25.277 18.7548L24.9122 13.3885C24.8951 13.1366 24.6774 12.9308 24.425 12.9279L19.0466 12.8653C18.7294 12.8616 18.4911 13.1137 18.5126 13.4301C18.5342 13.7465 18.8072 14.0046 19.1243 14.0083L23.8136 14.0661L24.1319 18.7479C24.153 19.0579 24.4294 19.3192 24.7431 19.3197V19.3197Z" fill="#3A9119"/>
<path d="M14.3116 17.987C13.9945 17.9833 13.7561 18.2354 13.7777 18.5518L14.1425 23.9182C14.1596 24.17 14.3773 24.3758 14.6297 24.3788L20.0081 24.4414C20.3252 24.4451 20.5636 24.193 20.542 23.8765C20.5205 23.5601 20.2475 23.3021 19.9304 23.2984L15.2411 23.2405L14.9228 18.5587C14.9017 18.2487 14.6253 17.9874 14.3116 17.987V17.987Z" fill="#3A9119"/>
</g>
<defs>
<filter id="filter0_d_1880_3666" x="0" y="0" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1880_3666"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1880_3666" result="shape"/>
</filter>
</defs>
</svg>


export default ExpandIcon;
