const HeartFixedCta = (props: any) => (
  <svg
    {...props}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_1042_620)">
      <path
        d="M14.3627 1.34814C11.3023 1.34814 9.79381 4.36519 9.79381 4.36519C9.79381 4.36519 8.28528 1.34814 5.22487 1.34814C2.73769 1.34814 0.768126 3.42896 0.742669 5.9119C0.690814 11.0659 4.83124 14.7311 9.36953 17.8113C9.49465 17.8964 9.64248 17.942 9.79381 17.942C9.94513 17.942 10.093 17.8964 10.2181 17.8113C14.7559 14.7311 18.8963 11.0659 18.8449 5.9119C18.8195 3.42896 16.8499 1.34814 14.3627 1.34814Z"
        fill="#53B62D"
        fill-opacity="0.1"
      />
      <path
        d="M14.3627 1.34814C11.3023 1.34814 9.79381 4.36519 9.79381 4.36519C9.79381 4.36519 8.28528 1.34814 5.22487 1.34814C2.73769 1.34814 0.768126 3.42896 0.742669 5.9119C0.690814 11.0659 4.83124 14.7311 9.36953 17.8113C9.49465 17.8964 9.64248 17.942 9.79381 17.942C9.94513 17.942 10.093 17.8964 10.2181 17.8113C14.7559 14.7311 18.8963 11.0659 18.8449 5.9119C18.8195 3.42896 16.8499 1.34814 14.3627 1.34814Z"
        stroke="#53B62D"
        stroke-width="1.34091"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1042_620"
        x="-3.37579"
        y="-2.77032"
        width="26.3395"
        height="24.8308"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.72403" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1042_620"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1042_620"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default HeartFixedCta;
