import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
} from "react-leaflet";
import { useParams } from "react-router-dom";
import { showToast } from "../../utils";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./dog_profile.css";
import Navbar from "../../components/Navbar";
import ReviewCard from "../../components/ReviewCard";
import PhotoCarousel from "../../components/PhotoCarousel";
import Button from "../../components/Button";
import { Divider } from "antd";
import Tag from "../../components/Tag";
import {
  addFavorite,
  getDogBySlug,
  getDogs,
  removeFavorite,
  getBlogPosts
} from "../../service/actions";
import BackArrow from "../../assets/svg/BackArrow";
import SmallHeart from "../../assets/svg/SmallHeart";
import ShareSmallIcon from "../../assets/svg/ShareSmallIcon";
import Footer from "../../components/Footer";
import { formatDescription } from "../../utils";
import LoginModal from "../../components/LoginModal";
import DogCarousel from "../../components/DogCarousel";
import DogCard from "../../components/DogCard";
import HeartFixedCta from "../../assets/svg/HeartFixedCta";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { Helmet } from 'react-helmet-async';
import { useParamsNavigate } from '../../utils';
import LocationIcon from "../../assets/svg/LocationIcon";
import TextSms from "../../assets/svg/TextSms";
import CircleCheck from "../../assets/svg/CircleCheck";

// Fix Leaflet icon issue
delete (L.Icon.Default.prototype as any)._getIconUrl;

const DogProfile: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [dog, setDog] = useState<any>();
  // const [statusAdoption, setStatusAdoption] = useState("NOT_APPLIED");
  const [moreDogs, setMoreDogs] = useState([]);
  const [center, setCenter] = useState<any>();
  const radius = 2000;
  const [applyToAdoptClicked] = useState(false);
  const [reviews, setReviews] = useState([]);

  if (
    (document as any).getElementsByClassName("leaflet-control-attribution")[0]
  )
    (document as any).getElementsByClassName(
      "leaflet-control-attribution"
    )[0].style.display = "none";

  const { slug } = useParams();

  const navigate = useParamsNavigate();

  const [user, setUser] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleDotClick = (index: number) => {
    scrollToElement(index);
    setCurrentIndex(index);
  };

  const scrollToElement = (index: number) => {
    const element = document.getElementById(`card-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };
  //eslint-disable-next-line
  const handleScroll = () => {
    const card: any = document.getElementById(`card-${currentIndex}`);
    const container: any = document.getElementById(`review-cards-container`); // Replace 'yourDivId' with your actual div id

    const cardWidth = card.clientWidth;
    const scrollPosition = container.scrollLeft;
    const newIndex = Math.round(scrollPosition / cardWidth);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {

    const targetDiv = document.getElementById('review-cards-container');

    if (targetDiv) {
      targetDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetDiv) {
        targetDiv.removeEventListener('scroll', handleScroll);
      }
    };
    //eslint-disable-next-line
  }, [handleScroll]);

  const handleFavorite = (event: any) => {
    event.stopPropagation();

    if (!user) {
      setShowModal(true);
      return false;
    }

    const favorite = async () => {
      datadogLogs.logger.info(`Add to favorites clicked. Dog: ${dog.name}`, {
        dog,
        user,
        url: window.location.href,
      });
      await addFavorite((user as any).id, dog.id);
    };
    const remove = async () => {
      datadogLogs.logger.info(`Remove from favorites clicked. Dog: ${dog.name}`, {
        dog,
        user,
        url: window.location.href,
      });
      await removeFavorite((user as any).id, dog.id);
    };
    if ((((user as any).favorite_dogs) || ([] as string[])).includes(dog.id)) {
      setUser((prevState: any) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...prevState,
            favorite_dogs: prevState.favorite_dogs.filter(
              (element: any) => element !== dog.id
            ),
          })
        );
        return {
          ...prevState,
          favorite_dogs: prevState.favorite_dogs.filter(
            (element: any) => element !== dog.id
          ),
        };
      });
      remove();
      showToast("Removed from favorites");
    } else {
      setUser((prevState: any) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...prevState,
            favorite_dogs: [...prevState.favorite_dogs, dog.id],
          })
        );
        return {
          ...prevState,
          favorite_dogs: [...prevState.favorite_dogs, dog.id],
        };
      });

      favorite();
      showToast("Added to favorites");
    }
  };

  const handleShare = (event: any) => {
    const currentPageURL = window.location.href;
    const message = `${currentPageURL.includes('?') ? currentPageURL + '&source=share-button' : currentPageURL + '?source=share-button'}`;

    navigator.clipboard.writeText(message).then(() => {
      datadogLogs.logger.info(`URL copied to clipboard using the share button. Dog: ${dog.name}`, {
        dog,
        url: window.location.href,
      });
      showToast('Copied to clipboard');
    }).catch((error) => {
      datadogLogs.logger.info(`Error copying the URL to clipboard using the share button. Dog: ${dog.name}`, {
        dog,
        error,
        url: window.location.href,
      });
      showToast('Error copying text to clipboard.');
    });
  };

  const handleText = () => {
    const currentPageURL = window.location.href;

    // Get the browser language
    const browserLanguage = navigator.language || (navigator as any).userLanguage;

    const body = `${t("dogProfile.textMessage")} ${dog.name}. ${currentPageURL}`;

    if (browserLanguage.startsWith('es') || browserLanguage.startsWith('pt')) {
      window.open('https://wa.me/+18325294282?text=' + body, '_blank');
    } else {
      const subject = 'PetPair';

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isAndroid = /Android/.test(navigator.userAgent);

      if (isIOS) {
        // Open iMessage or default mail app on iOS
        window.location.href = `sms:+15186852262&body=${body}`;
      } else if (isAndroid) {
        // Open WhatsApp or other messaging apps on Android
        window.location.href = `sms:+15186852262?body=${body}`;
      } else {
        // Default to the "mailto" link for other platforms
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
      }
    }
  };

  useEffect(() => {
    const dogBySlug = async () => {
      const request: any = await getDogBySlug(slug);
      if (request.data.name) setDog(request.data);
      else navigate("/discovery");
    };

    const dogsMore = async () => {
      const request: any = await getDogs("", "", "", "age", [], [], []);
      setMoreDogs(request.dogs);
    };
    try {
      let user = JSON.parse(localStorage.getItem("user") as string);
      console.log(user);

      setUser(user);
    } catch {
      console.log("No data to parse.");
    }

    const getReviews = async () => {
      const posts: any = await getBlogPosts();
      const filteredReviews = posts.data.posts.filter((post: any) => {
        const titleWords = post.title.split(' ');
        return (titleWords.length === 1 || titleWords.length === 2) && !post.title.includes('Privacy');
      });
      setReviews(filteredReviews);
    }

    dogBySlug();
    dogsMore();
    getReviews();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dog && user) {
      (window as any).fbq("track", `${dog.name}'s profile visit`);
      // document.title = `${dog.name}, ${dog.breed.name}${
      //   dog.address && " in " + dog.address
      // } | Available to Adopt on PetPair!`;
      let adoptionStatus: any, adoptions;
      adoptions = (user as any).adoptionsAsAdopter.filter(
        (adoption: any) => adoption.dogId === dog.id
      );
      if (adoptions.length > 0) {
        adoptionStatus = adoptions[0].status;
        console.log(adoptionStatus);
      }
    }
  }, [dog, user]);

  useEffect(() => {
    if (dog) {
      const geocodeURL = `https://nominatim.openstreetmap.org/search?format=jsonv2&q=${dog.zipCode}&countrycodes=us`;
      datadogLogs.logger.info(`Dog profile page loaded. Dog: ${dog.name}`, {
        dog,
        url: window.location.href,
      });

      fetch(geocodeURL)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            const usLocation = data.filter((place: any) =>
              place.display_name.includes("United States")
            );

            const { lat, lon } = usLocation[0];
            setCenter([parseFloat(lat), parseFloat(lon)]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dog]);

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const footerRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as per your needs
    };

    const handleIntersection = (entries: any) => {
      const headerEntry = entries[0];
      setIsHeaderVisible(headerEntry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        // eslint-disable-next-line
        observer.unobserve(headerRef.current);
      }
    };
  }, [dog]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as per your needs
    };

    const handleIntersection = (entries: any) => {
      const footerEntry = entries[0];
      setIsFooterVisible(footerEntry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        // eslint-disable-next-line
        observer.unobserve(footerRef.current);
      }
    };
  }, [dog]);

  function formatAge(age: any) {
    const years = Math.floor(age);
    const months = Math.round((age - years) * 12);

    let formattedAge = "";

    if (years > 0) {
      formattedAge += `${years} ${years > 1 ? t("dogProfile.ageTextPlural") : t("dogProfile.ageText")}`;
    }

    if (months > 0) {
      if (years > 0) {
        formattedAge += ` ${t("dogProfile.complementConnector")} `;
      }
      formattedAge += `${months} ${months > 1 ? t("dogProfile.monthTextPlural") : t("dogProfile.monthText")}`;
    }

    return formattedAge;
  }


  if (dog) {
    return (
      <>
        <Helmet>
          <title>{`${dog.name}, ${dog.breed.name}${dog.shelter && dog.shelter.city && dog.shelter.state? ` in ${dog.shelter.city}, ${dog.shelter.state}` : ""} | Available to Adopt on PetPair!`}</title>
          <meta name="description" content={dog.description} />
        </Helmet>

        <Navbar />
        <div className="dog-profile-wrapper">
          <div
            className="dog-profile-back-container"
            onClick={() => {
              datadogLogs.logger.info(`Back button clicked at the dog profile. Dog: ${dog.name}`, {
                dog,
              });
              navigate("/discovery");
            }}
          >
            <div className="back-icon">
              <BackArrow />
            </div>
            <span className="dog-profile-back">
              {t("dogProfile.backToAll")}
            </span>
          </div>
          <div className="dog-profile-root">
            <div className="dog-profile-left">
              <div className="dog-profile-photo-wrapper">
                <PhotoCarousel photos={dog.photos} videos={dog.videos} />
                <div className="dog-buttons">
                  <Button
                    title={t("dogProfile.adoptButton")}
                    filled
                    dogSlug={dog.slug}
                    onClick={() => {
                      // if (!user) {
                      //   navigate(`/application/${dog.id}`);
                      // } else if (statusAdoption === "NOT_APPLIED")
                      //   navigate(`/application/${dog.id}`);
                      // else if (
                      //   statusAdoption === "REQUESTED" ||
                      //   statusAdoption === "DENIED" ||
                      //   statusAdoption === "FINISHED"
                      // )
                      //   showToast(
                      //     "You already have an application for this dog"
                      //   );
                      // else if (statusAdoption === "INCOMPLETE")
                      //   navigate(
                      //     `/adopt/create/${(user as any).id}/${dog.id
                      //     }/?adoptionId=${adoptionId}`
                      //   );
                      navigate(`/application/step2/${dog.id}`);
                    }}
                  />
                  <div className="dog-profile-sep" />
                  {/* <Button title='Chat with his foster' /> */}
                </div>
              </div>
            </div>
            <div className="dog-profile-right">
              <div className="dog-profile-header" ref={headerRef}>
                <span className="dog-profile-name">{dog.name}</span>
                <div className="options">
                  <div className="options-wrapper-share" onClick={handleShare}>
                    <div className="dog-profile-icon">
                      <ShareSmallIcon />
                    </div>
                    <span>{t("dogProfile.share")}</span>
                  </div>
                  <div className="options-wrapper">
                    <div className="dog-profile-icon">
                      <SmallHeart />
                    </div>
                    <span
                      className="dog-profile-favorite"
                      onClick={handleFavorite}
                    >
                      {((user && (user as any).favorite_dogs) || ([] as string[])).includes(dog.id)
                        ? t("dogProfile.removeFavorite")
                        : t("dogProfile.favorite")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="dog-profile-info-container">
                <div className="dog-info">
                  <span className="title">{t("dogProfile.age")}</span>
                  <span className="data">
                    {formatAge(dog.age)}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.gender")}</span>
                  <span className="data">
                    {dog.gender === "FEMALE"
                      ? t("dogProfile.genderFemale")
                      : t("dogProfile.genderMale")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.weight")}</span>
                  <span className="data">
                    {dog.weight} {t("dogProfile.weightUnit")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.breed")}</span>
                  <span className="data">{dog.breed.name}</span>
                </div>
              </div>
              <div className="traits-tags">
                {dog.personality.map((trait: any) => (
                  <Tag
                    title={trait.name}
                    description={formatDescription(
                      trait.description,
                      dog.name,
                      dog.gender
                    )}
                  />
                ))}
              </div>
              <div className="dog-profile-description">
                <span className="dog-profile-heavy-text">
                  {formatDescription(
                    t("dogProfile.moreAboutHeader"),
                    dog.name,
                    dog.gender
                  )}
                </span>
                <span className="dog-profile-description-text">
                  {dog.description}
                </span>
              </div>
              <div className="dog-profile-info-container2">
                <div className="dog-info">
                  <span className="title">{t("dogProfile.fixed")}</span>
                  <span className="data">
                    {dog.fixed ? t("dogProfile.yes") : t("dogProfile.no")}
                  </span>
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.training")}</span>

                  {dog.trainedFor.length > 0 ? (<span className='data'>
                    {dog.trainedFor.map((training: any, index: number) => (
                      <>
                        {training.name}
                        {index < dog.trainedFor.length - 1 && ', '}
                      </>

                    ))}
                  </span>
                  ) : (
                    <span className="data">
                      Needs some help learning the basics
                    </span>
                  )}

                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.commands")}</span>
                  {dog.commands.length > 0 ? (
                    dog.commands.map((command: any) => (
                      <span className="data">{command.name}</span>
                    ))
                  ) : (
                    <span className="data">None</span>
                  )}
                </div>

                <div className="dog-info">
                  <span className="title">{t("dogProfile.sleepStyle")}</span>
                  <span className="data">
                    {dog.sleepStyle.name || "Unknown"}
                  </span>
                </div>
              </div>
              <Divider />
              <span className="adoption-title">
                {t("dogProfile.adoptionInfo")}
              </span>
              <div className="lower-section">
                <div className="dog-profile-adoption">
                  <div className="adoption-info-low">
                    <span className="title">{t("dogProfile.fee")}</span>
                    <div>
                      <span className="price">{`$${dog.adoptionFee}`}</span>
                      {dog.fullFee > 0 && <span className="actual-price">{`$${dog.fullFee}`}</span>}
                    </div>
                    {dog.promoExpiry && <span className='expiry'>{dog.promoExpiry}</span>}
                    <Divider />
                    <span className="title">
                      {t("dogProfile.includedTitle")}
                    </span>
                    <Tag
                      title={t("dogProfile.food")}
                      description={formatDescription(
                        t("dogProfile.foodTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={t("dogProfile.goodies")}
                      description={formatDescription(
                        t("dogProfile.goodiesTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={t("dogProfile.bed")}
                      description={formatDescription(
                        t("dogProfile.bedTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                    <Tag
                      title={formatDescription(
                        t("dogProfile.insurance"),
                        dog.name,
                        dog.gender
                      )}
                      description={formatDescription(
                        t("dogProfile.insuranceTooltip"),
                        dog.name,
                        dog.gender
                      )}
                    />
                  </div>
                </div>
                <div className="other-info">
                  <div className="other-info-low">
                    <span className="title">
                      {formatDescription(
                        t("dogProfile.location"),
                        dog.name,
                        dog.gender
                      )}
                    </span>
                    <div className='location-info'>
                      <div className='location-info-row'>
                        <LocationIcon fill='#757575' />
                        <span className='dog-bold-info'>{dog.status === 'IN_SHELTER' ? dog.shelter.city + ', ' + dog.shelter.state : dog.address}</span>
                      </div>
                    </div>
                    {center && (
                      <MapContainer
                        center={center as any}
                        zoom={12}
                        minZoom={5}
                        maxZoom={16}
                        className="dogprofile-map"
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Circle
                          center={center as any}
                          radius={radius}
                          color="#53B62D"
                        />
                      </MapContainer>
                    )}

                    <Divider />
                    <span className="title2">{t('dogProfile.transportationTitle')}</span>
                    <div className='more-info-list'>
                      <div className='more-info-row'>
                        <CircleCheck />
                        <span className='more-info-list-text'>{t('dogProfile.transportationItem1')}</span>
                      </div>
                      <div className='more-info-row'>
                        <CircleCheck />
                        <span className='more-info-list-text'>{t('dogProfile.transportationItem2')}</span>
                      </div>
                    </div>
                    {/* <span className="heavy-value">{t('dogProfile.pickupDescription')}</span> */}
                    <span className="title2">Foster</span>
                    <div className='more-info-list'>
                      <div className='more-info-row'>
                        <CircleCheck />
                        <div className='more-info-list-text'>{t('dogProfile.fosterItem1')}<a
                          className="heavy-green-value"
                          href="/fosters" onClick={() => {
                            datadogLogs.logger.info(`"${t("dogProfile.fosterCta")}" CTA clicked.`, {
                              user,
                              url: window.location.href,
                            });
                          }}
                        >{t('home.secondaryButton3')}</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className='carousel-title-container'>
            <span>{t('dogProfile.reviewsTitle')}</span>
          </div>
          <div className='carousel-container'>
            <div className='review-card-carousel' id='review-cards-container'>
              {reviews.map((review: any, index) => (
                <div
                  key={index}
                  id={`card-${index}`}
                  className='review-card-2'
                >
                  <ReviewCard app={false} slug={review.slug} title2={'Adopted: ' + review.html.match(/<p>Adopted: <strong>(.*?)<\/strong>/)?.[1] || ''} image={review.feature_image} title={review.title} description={review.excerpt} numberOfStars={5} />
                </div>
              ))}
            </div>
            <div className='carousel-dots'>
              {reviews.map((_, index) => (
                <div
                  key={index}
                  className={`carousel-dot ${currentIndex === index ? 'selected' : ''}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </div>
          <Divider />
          <div className="dog-profile-dogs">
            <div className="more-dogs-title-wrapper">
              <span className="title">
                {formatDescription(
                  t("dogProfile.moreLikeHeader"),
                  dog.name,
                  dog.gender
                )}
              </span>
            </div>
            <DogCarousel>
              {moreDogs.map((dog: any, index: number) => (
                <DogCard
                  dog={dog}
                  slug={dog.slug}
                  key={dog.slug + dog.name + dog.index}
                  name={dog.name}
                  age={dog.age}
                  weight={dog.weight}
                  breed={dog.breed.name}
                  img={(dog.photos[0] || '')}
                  location={"/dog/" + dog.slug}
                  id={dog.id}
                />
              ))}
            </DogCarousel>
          </div>
          <LoginModal
            showModal={showModal}
            handler={setShowModal}
            createAccount
            applyToAdopt={applyToAdoptClicked}
            dogId={dog.id}
          />
          <div className={isFooterVisible ? "mobile-cta-bottom" : "mobile-cta"}>
            <div
              className={
                !isHeaderVisible ? "mobile-cta-left" : "mobile-cta-left-none"
              }
            >
              <span className="mobile-cta-title">{dog.name}</span>
              <div className="mobile-options-container">
                <TextSms
                  className="mobile-share"
                  onClick={handleText}
                />
                <HeartFixedCta
                  onClick={handleFavorite}
                  className={
                    ((user && (user as any).favorite_dogs) || ([] as string[])).includes(dog.id)
                      ? "mobile-heart-filled"
                      : "mobile-heart"
                  }
                />
              </div>
            </div>
            <div
              className={
                !isHeaderVisible ? "mobile-cta-right" : "mobile-cta-right-full"
              }
            >
              {isHeaderVisible && <Button
                title={dog.gender === 'MALE' ? t('dogProfile.askAboutHim') : t('dogProfile.askAboutHer')}
                small
                onClick={() => {
                  handleText();
                }}
              />}
              <Button
                title={t('dogProfile.adoptButton')}
                small
                filled
                onClick={() => {
                  // if (!user) {
                  //   navigate(`/application/${dog.id}`);
                  // } else if (statusAdoption === "NOT_APPLIED")
                  //   navigate(`/application/${dog.id}`);
                  // else if (
                  //   statusAdoption === "REQUESTED" ||
                  //   statusAdoption === "DENIED" ||
                  //   statusAdoption === "FINISHED"
                  // )
                  //   showToast("You already have an application for this dog");
                  // else if (statusAdoption === "INCOMPLETE")
                  //   navigate(
                  //     `/adopt/create/${(user as any).id}/${dog.id
                  //     }/?adoptionId=${adoptionId}`
                  //   );
                  // console.log(statusAdoption);

                  navigate(`/application/step2/${dog.id}`);
                }}
              />
            </div>
          </div>
        </div>
        <div ref={footerRef}>
          <Footer />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};




export default DogProfile;
