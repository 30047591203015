const PhoneIcon = (props: any) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.23 12.2598L13.69 11.9698C13.08 11.8998 12.48 12.1098 12.05 12.5398L10.21 14.3798C7.38004 12.9398 5.06004 10.6298 3.62004 7.78977L5.47004 5.93976C5.90004 5.50977 6.11004 4.90977 6.04004 4.29977L5.75004 1.77977C5.63004 0.769766 4.78004 0.00976562 3.76004 0.00976562H2.03004C0.900041 0.00976562 -0.0399593 0.949766 0.0300407 2.07977C0.560041 10.6198 7.39004 17.4398 15.92 17.9698C17.05 18.0398 17.99 17.0998 17.99 15.9698V14.2398C18 13.2298 17.24 12.3798 16.23 12.2598Z"
      fill="#53B62D"
    />
  </svg>
);

export default PhoneIcon;
