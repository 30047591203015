const XIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0124 0.996621C11.6711 0.655371 11.1199 0.655371 10.7786 0.996621L6.49988 5.26662L2.22113 0.987871C1.87988 0.646621 1.32863 0.646621 0.987383 0.987871C0.646133 1.32912 0.646133 1.88037 0.987383 2.22162L5.26613 6.50037L0.987383 10.7791C0.646133 11.1204 0.646133 11.6716 0.987383 12.0129C1.32863 12.3541 1.87988 12.3541 2.22113 12.0129L6.49988 7.73412L10.7786 12.0129C11.1199 12.3541 11.6711 12.3541 12.0124 12.0129C12.3536 11.6716 12.3536 11.1204 12.0124 10.7791L7.73363 6.50037L12.0124 2.22162C12.3449 1.88912 12.3449 1.32912 12.0124 0.996621Z"
      fill="#DCDCDC"
    />
  </svg>
);

export default XIcon;
