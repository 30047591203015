// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import "./DogCard.css";
import DogIcon from "../../assets/svg/DogIcon";
import CalendarIcon from "../../assets/svg/CalendarIcon";
import WeightIcon from "../../assets/svg/WeightIcon";
import ArrowForward from "../../assets/svg/ArrowForward";
import LoginModal from "../../components/LoginModal";
import Badge from "../../components/Badge";
import LocationIcon from "../../assets/svg/LocationIcon";
import LocationIconBadge from "../../assets/svg/LocationIconBadge";
import HealthFilled from "../../assets/svg/ExpandIcon";
import BlackRoundCheckIcon from "../../assets/svg/BlackRoundCheckIcon";
import { stateAbbreviations } from '../../utils';

const DogCard: React.FC<{
  allDogs?: boolean;
  img?: String;
  name: String;
  age?: Number;
  breed?: String;
  weight?: Number;
  location?: string;
  id?: any;
  type?: any;
  setFavoriteParent?: any;
  slug?: string;
  application?: boolean;
  applicationStatus?: string;
  applicationId?: string;
  descripton?: string;
  onClickEvent?: any;
  modal?: boolean;
  app?: boolean;
  dog?: any;
}> = ({
  allDogs,
  img,
  name,
  age,
  address,
  breed,
  weight,
  id,
  setFavoriteParent,
  slug,
  application,
  applicationStatus,
  applicationId,
  description,
  onClickEvent,
  modal,
  app,
  dog
}) => {
  // const [applicationStatusLinkMap] = useState({
  //   INCOMPLETE: "Finish",
  //   REQUESTED: "Waiting",
  //   APPROVED: "Make payment",
  //   DENIED: "See other dogs",
  //   FINISHED: "Thank you",
  // });
  const [showModal, setShowModal] = useState(false);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [noGoodWithMale, setNoGoodWithMale] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const getGoodWithString = () => {
    const goodWithArray = dog.personality.filter(item => item.name.startsWith('Good with'));
    
    if (goodWithArray.length === 0) {
      return dog.gender === 'FEMALE' ? 'Spayed and up to date on vaccines' : 'Neutered and up to date on vaccines';
    }

    const goodWithString = goodWithArray.map(item => item.name.replace('Good with', '').trim());
    
    if (goodWithString.length === 1) {
      return `Good with ${goodWithString[0]}`;
    } else {
      const lastItem = goodWithString.pop();
      return `Good with ${goodWithString.join(', ')} and ${lastItem}`;
    }
  };


  const applicationStatusMap = {
    INCOMPLETE: "Application incomplete. Finish now.",
    REQUESTED: "Waiting on PetPair's approval.",
    APPROVED: "Application approved — submit payment now!",
    DENIED: "We could not approve your application.",
    FINISHED: "Adoption finished.",
  };

  const applicationStatusColorMap = {
    INCOMPLETE: "yellow",
    REQUESTED: "green",
    APPROVED: "green",
    DENIED: "red",
    FINISHED: "green",
  };

  const applicationStatusButtonTextMap = {
    INCOMPLETE: "Finish",
    REQUESTED: "See dog",
    APPROVED: "Make payment",
    DENIED: "See other dogs",
    FINISHED: "Thank you",
  };

  useEffect(() => {
    if(dog){
    const goodWithArray = dog.personality.filter(item => item.name.startsWith('Good with'));
    if (goodWithArray.length === 0) 
      setNoGoodWithMale(dog.gender === 'FEMALE' ? false : true);
    }
  }, [dog])


  useEffect(() => {
    
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.offsetWidth < textRef.current.scrollWidth
      );
    }
  }, []);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };


  return (
    <div className={modal ? 'application-dogcard-modal' : app ? 'application-dogcard-app' : 'application-dogcard'} onClick={onClickEvent}>
      <a
        href={
          !onClickEvent && ("/dog/" + slug)
        }
        rel="noopener noreferrer"
        className="application-dogcard-link"
      >
        <div className="application-dogcard-image-wrapper">

          {application && (
            <div
              className={`application-dogcard-application-container ${applicationStatusColorMap[applicationStatus]}`}
            >
              <div className="application-dogcard-application-status">
                <span className="application-status-text">
                  {applicationStatusMap[applicationStatus]}
                </span>
              </div>
            </div>
          )}
          {app && (
            <div
              className={`favorite-icon`}
            >
                <HealthFilled />
            </div>
          )}
          <img
            src={img ? img.includes('http') ? img : 'https://cdn.petpair.co/305x192/' + img : ""}
            className={modal ? `application-dogcard-image-modal` : `application-dogcard-image`}
            alt="Dog"
          />
          <div className='dogcard-badge-container'>
            <Badge Icon={LocationIconBadge} text={dog && dog.status === 'IN_SHELTER' ? `${dog.shelter.city}, ${stateAbbreviations[dog.shelter.state]}` : dog && dog.address} />
          </div>
        </div>
        <div className={noGoodWithMale ? "application-dogcard-info-male" : "application-dogcard-info"}>
          <div className="application-dogcard-name-heart">
            <span className="application-dogcard-name">{name}</span>
          </div>
          <div className="application-dogcard-info-container">
            <CalendarIcon />
            <span className="application-dogcard-info-age">
              {age > 1 ? `${age} years old` : `${age} year old`}
            </span>
          </div>
          <div className="application-dogcard-info-container">
            <DogIcon />
            <span
              className={`application-dogcard-info-text${
                isTextOverflowing ? " application-dogcard-info-overflow" : ""
              }`}
              ref={textRef}
            >
              {" "}
              {truncateText(breed || "", 20)}
            </span>
          </div>
          <div className="application-dogcard-info-container weight">
            <WeightIcon className="weight-icon" />
            <span className="application-dogcard-info-text">{weight + " "}lbs.</span>
          </div>
          {app ? <div className="application-dogcard-info-container">
            <BlackRoundCheckIcon />
            <span className="application-dogcard-info-age">
              {dog && getGoodWithString()}
            </span>
          </div> : <div className="application-dogcard-info-container">
            <LocationIcon />
            <span className="application-dogcard-info-age">
              {address}
            </span>
          </div>}
        </div>
        {!app && (
          <div className="application-dogcard-cta">
            <span className='application-dogcard-description'>{description.length <= 140 ? description : description.slice(0, 140) + '...'}</span>
            <div className='application-dog-card-know-more' >
            <div>
              <span className="application-dogcard-cta-text">More info</span>
            </div>
            <div className="application-dogcard-cta-icon">
              <ArrowForward />
            </div>
            </div>
          </div>
        )}

        {application && (
          <div className="application-dogcard-cta">
            <div>
              <span className="application-dogcard-cta-text">
                {applicationStatusButtonTextMap[applicationStatus]}
              </span>
            </div>
            <div className="application-dogcard-cta-icon">
              <ArrowForward />
            </div>
          </div>
        )}

        <LoginModal
          showModal={showModal}
          handler={setShowModal}
          createAccount
        />
      </a>
    </div>
  );
};

export default DogCard;
