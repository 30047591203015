import React from "react";

const DogHeart = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.22847 10.7548H6.03325C5.75479 10.7545 5.48784 10.865 5.291 11.0616L2.14369 14.2085C1.87841 14.4737 1.77486 14.86 1.87201 15.2222C1.96897 15.5844 2.252 15.8674 2.61426 15.9644C2.9765 16.0615 3.36288 15.958 3.62816 15.6927L6.46864 12.8526H8.22844C9.03396 12.8532 9.81663 12.5843 10.4517 12.0886C11.0867 11.5928 11.5375 10.899 11.7325 10.1175L12.2964 7.87013C12.3511 7.65208 12.3341 7.42214 12.2481 7.21452C12.1623 7.00672 12.0117 6.83202 11.8191 6.71621L7.08212 3.86826V1.31407C7.08212 1.03584 6.97161 0.769117 6.77494 0.572295C6.57809 0.375651 6.31135 0.265163 6.03307 0.265163C4.40993 0.260407 2.85247 0.905409 1.70809 2.05618L0.307422 3.45665C0.0423233 3.72171 -0.0612253 4.10823 0.0357384 4.47041C0.132885 4.8326 0.41573 5.11538 0.777988 5.21255C1.14023 5.3095 1.52679 5.20597 1.79188 4.94091L3.19255 3.54044C3.68671 3.03904 4.3047 2.67719 4.98382 2.49156V4.46096C4.98382 4.82937 5.17683 5.1707 5.49278 5.3604L10.072 8.10884L9.70485 9.60882C9.62271 9.93754 9.43244 10.2291 9.1646 10.4369C8.89695 10.6447 8.56726 10.7567 8.22827 10.7549L8.22847 10.7548Z"
      fill="black"
    />
    <path
      d="M9.70512 1.57615C9.70512 2.69073 11.2789 4.06488 12.202 4.77551C12.389 4.92221 12.6199 5.00197 12.8577 5.00197C13.0956 5.00197 13.3264 4.92221 13.5134 4.77551C14.4261 4.06464 15.9999 2.69054 15.9999 1.57615C16.0006 1.08609 15.7731 0.623649 15.3843 0.325301C14.9955 0.0269538 14.49 -0.0732931 14.0167 0.0542036C13.5436 0.181522 13.1567 0.522311 12.9706 0.975611C12.9503 1.02262 12.9038 1.05317 12.8524 1.05317C12.8012 1.05317 12.7547 1.02262 12.7344 0.975611C12.5482 0.52232 12.1614 0.181532 11.6883 0.0542036C11.215 -0.0732977 10.7093 0.0269477 10.3205 0.325301C9.93177 0.623654 9.70442 1.08609 9.70512 1.57615Z"
      fill="black"
    />
  </svg>
);

export default DogHeart;
