// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Divider, Modal } from 'antd';
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import AppDogCard from "../../components/AppDogCard";
import {
  getDogById
} from "../../service/actions";
import "./app-email-input.css";
import { useParamsNavigate } from '../../utils';
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";
import {
  getBlogPosts
} from "../../service/actions";
import Input from "../../components/Input";
import ReviewBadge from "../../components/ReviewBadge";
import DropdownUpArrow from "../../assets/svg/UpArrow";
import RoundCheckIcon from "../../assets/svg/RoundCheckIcon";
import Spinner from '../../components/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import FacebookButton from "../../components/FacebookButton";

const ApplicationEmailInput = (props) => {
  // const { t, i18n } = useTranslation();

  const [dog, setDog] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modifier, setModifier] = useState('');
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  // const [selectedStep, setSelectedStep] = useState(1);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (dog && dog.photos && dog.photos[0]) {
      const img = new Image();
      img.src = `https://cdn.petpair.co/55x55/${dog.photos[0]}`;
      img.onload = () => {
        setImageLoading(false);
      };
    }
  }, [dog]);


  //eslint-disable-next-line
  const handleScroll = () => {
    const card: any = document.getElementById(`card-${currentIndex}`);
    const container: any = document.getElementById(`review-cards-container`); // Replace 'yourDivId' with your actual div id

    const cardWidth = card.clientWidth;
    const scrollPosition = container.scrollLeft;
    const newIndex = Math.round(scrollPosition / cardWidth);
    setCurrentIndex(newIndex);
  };

  
  const validateEmail =  (email) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {

    const targetDiv = document.getElementById('review-cards-container');

    if (targetDiv) {
      targetDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (targetDiv) {
        targetDiv.removeEventListener('scroll', handleScroll);
      }
    };
    //eslint-disable-next-line
  }, [handleScroll]);



  const navigate = useParamsNavigate();
  const { dogId } = useParams();


  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {

    const getReviews = async () => {
      const posts: any = await getBlogPosts();
      const filteredReviews = posts.data.posts.filter((post: any) => {
        const titleWords = post.title.split(' ');
        return (titleWords.length === 1 || titleWords.length === 2) && !post.title.includes('Privacy');
      });
      setReviews(filteredReviews);
    }

    datadogLogs.logger.info(
      `Email page loaded.`,
      { url: window.location.href }
    );

    getReviews();
    //eslint-disable-next-line
  }, []);

  const handleMobileButtonWrapperClick = () => {
    setIsModalVisible(true);
  };


  useEffect(() => {


    if (!dogId) {
      navigate("/");
      console.log();
    } else {
      getDogById(dogId).then((res) => {
        setDog(res.data);
        setLoading(false);
        console.log(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  const routerLocation = useLocation();

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    if (modifierParam) setModifier(modifierParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);


  return (
    <div className="adoption">
      <Toaster />
      <div className="short-email-left">
        <div className="adoption-left-email-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            <Counter max={5} current={2} />
          </div>
          {reviews.length > 0 && 
          <ReviewBadge title={reviews[1].title} description={reviews[1].excerpt}/>
          }<Divider />
          <span className="adoption-email-left-title">We need an email to get started.</span>
          <span className="adoption-left-description">We will only email you about your application. Check out our <a href='/privacy' className='inner-description-link'>privacy policy.</a></span>
          <br />
          <div className='app-email-form-container'>
          <Input placeholder='Enter your email' label='' value={email} onChange={(e) => { setEmail(e.target.value) }} />
          </div>
          <Divider>or</Divider>
          <div className='email-app-facebook-button'>
            <FacebookButton dogId={dogId} />
          </div>
          {/* <div className='carousel-container'>
            <div className='review-email-card-carousel' id='review-cards-container'>
              {reviews.map((review: any, index) => (
                <div
                  key={index}
                  id={`card-${index}`}
                  className='review-card-2'
                >
                  <ReviewCard app slug={review.slug} title2={'Adopted: ' + review.html.match(/<p>Adopted: <strong>(.*?)<\/strong>/)?.[1] || ''} image={review.feature_image} title={review.title} description={review.excerpt} numberOfStars={5} />
                </div>
              ))}
            </div>
            <div className='carousel-dots'>
              {reviews.map((_, index) => (
                <div
                  key={index}
                  className={`carousel-dot ${currentIndex === index ? 'selected' : ''}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </div> */}


          

        </div>
        <div className="adoption-email-mobile-button-wrapper">
            {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
            {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
            <div className='app-email-mobile-bar-wrapper'>
              <div className="adoption-mobile-dog-info" onClick={handleMobileButtonWrapperClick}>
                {loading ? <div className='app-spinner-container'><Spinner /></div> : !imageLoading && <><img src={`https://cdn.petpair.co/55x55/${dog && dog.photos[0]}`} className='mobile-bar-dog-photo' alt='PetPair' />
                  <div className='app-bar-text-container'>
                    <div className='app-dog-name-container'>
                      <span className='mobile-bar-dog-name'>{dog.name}</span> <DropdownUpArrow />
                    </div>
                    {modifier === 'dog' && <div className='app-modifier-text'>
                      <RoundCheckIcon />
                      <span className='app-modifier-span-obj'>Ready to adopt</span>
                    </div>}
                    {modifier === 'dog-transport' && <div className='app-modifier-text'>
                      <RoundCheckIcon />
                      <span className='app-modifier-span-obj'>Transport available</span>
                    </div>}
                    {modifier === 'dog-friendly' && <div className='app-modifier-text'>
                      <RoundCheckIcon />
                      <span className='app-modifier-span-obj'>Dog, cat and kid friendly</span>
                    </div>}
                  </div></>}
              </div>
              <Button
                title={'Next'}
                filled
                // location={`/application/step3/${dog.id}?email=${email}`}
                onClick={() => {
                  // Assuming you have a function for email validation, replace validateEmail with your actual validation logic
                  if (validateEmail(email)) {
                    navigate(`/application/step3/${dog.id}?email=${email}`);
                  } else {
                    toast.error('Invalid email address. Please enter a valid email.');
                    datadogLogs.logger.info(
                      `Invalid email alert at email page.`,
                      { url: window.location.href }
                    );
                  }
                }}
              />
            </div>
          </div>
      </div>



      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >
        {dog && (
          <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
            modal
          />
        )}
      </Modal>

    </div>
  );
};

export default ApplicationEmailInput;
