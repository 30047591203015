import { useEffect } from 'react';

const Sitemap = () => {
    useEffect(() => {
        window.location.href = 'https://api.petpair.co/sitemap.xml';
    }, []);

    return null;
}

export default Sitemap;
