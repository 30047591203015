const ShareSmallIcon = (props: any) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33333 5.33333L1.33333 2C1.33333 1.63333 1.63333 1.33333 2 1.33333L10 1.33333C10.3667 1.33333 10.6667 1.63333 10.6667 2L10.6667 5.33333C10.6667 5.7 10.9667 6 11.3333 6C11.7 6 12 5.7 12 5.33333L12 1.33333C12 0.599999 11.4 -5.24537e-08 10.6667 -1.16564e-07L1.33333 -9.3251e-07C0.6 -9.9662e-07 9.9662e-07 0.599998 9.3251e-07 1.33333L5.82819e-07 5.33333C5.50764e-07 5.7 0.3 6 0.666667 6C1.03333 6 1.33333 5.7 1.33333 5.33333ZM5.33333 5.55333L4.08 6.80667C3.82 7.06667 3.4 7.06667 3.14 6.80667C2.88 6.54667 2.88 6.12667 3.14 5.86667L5.53333 3.47333C5.79333 3.21333 6.21333 3.21333 6.47333 3.47333L8.86667 5.86667C9.12667 6.12667 9.12667 6.54667 8.86667 6.80667C8.60667 7.06667 8.18667 7.06667 7.92667 6.80667L6.66667 5.55333L6.66667 11.3333C6.66667 11.7 6.36667 12 6 12C5.63333 12 5.33333 11.7 5.33333 11.3333L5.33333 5.55333Z"
      fill="#53B62D"
    />
  </svg>
);

export default ShareSmallIcon;
