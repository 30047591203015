const SmallHeart = (props: any) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_928_15040)">
      <path
        d="M10.0281 1.5C7.99934 1.5 6.99934 3.5 6.99934 3.5C6.99934 3.5 5.99934 1.5 3.97059 1.5C2.32184 1.5 1.01622 2.87938 0.999343 4.52531C0.964968 7.94188 3.70966 10.3716 6.71809 12.4134C6.80103 12.4699 6.89903 12.5 6.99934 12.5C7.09966 12.5 7.19765 12.4699 7.28059 12.4134C10.2887 10.3716 13.0334 7.94188 12.9993 4.52531C12.9825 2.87938 11.6768 1.5 10.0281 1.5Z"
        fill="#53B62D"
        fill-opacity="0.1"
      />
      <path
        d="M10.0281 1.5C7.99934 1.5 6.99934 3.5 6.99934 3.5C6.99934 3.5 5.99934 1.5 3.97059 1.5C2.32184 1.5 1.01622 2.87938 0.999343 4.52531C0.964968 7.94188 3.70966 10.3716 6.71809 12.4134C6.80103 12.4699 6.89903 12.5 6.99934 12.5C7.09966 12.5 7.19765 12.4699 7.28059 12.4134C10.2887 10.3716 13.0334 7.94188 12.9993 4.52531C12.9825 2.87938 11.6768 1.5 10.0281 1.5Z"
        stroke="#53B62D"
        strokeWidth="1.14286"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_928_15040"
        x="-1.85798"
        y="-1.35713"
        width="17.715"
        height="16.7143"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.14286" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_928_15040"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_928_15040"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SmallHeart;
