import React from "react";
import "./congrats.css";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import DogAdoptionCard from "../../components/DogAdoptionCard";

const CongratsAdoption = () => {
  return (
    <>
      <Navbar />
      <div className="congrats-content">
        <DogAdoptionCard
          dogName="Parker"
          adoptionDate="2023-07-14T20:27:53.369Z"
          dogPhoto="https://res.cloudinary.com/petpair/image/upload/q_auto:good,g_face/slack_uploads/lilly_3_gyoiuy.webp"
        />
        <span className="congrats-title">Congratulations Daniel!</span>
        <p className="congrats-description">
          You have been approved to adopt Chester. Let's proceed to the adoption
          fee and get Chester off to their forever home
        </p>
        <Button filled title="Let's proceed" />
      </div>
    </>
  );
};

export default CongratsAdoption;
