import React from "react";
import PhoneIcon from "../../assets/svg/PhoneIcon";
import "./phone-button.css";

const PhoneButton: React.FC<{ phoneNumber: string; fn: any }> = ({
  phoneNumber,
  fn,
}) => {
  return (
    <div className="phone-button" onClick={fn}>
      <PhoneIcon className="phone-icon" />
      <span>{phoneNumber}</span>
    </div>
  );
};

export default PhoneButton;
