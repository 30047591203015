import React from "react";
import "./counter.css";

const Counter: React.FC<{ max: number; current: number }> = ({
  current,
  max,
}) => {
  return <div className="counter">{`${current}/${max}`}</div>;
};

export default Counter;
