//@ts-nocheck
import React, { useState } from "react";
import "./input.css";
import Visa from "../../assets/svg/cards/visa.svg";
import Mastercard from "../../assets/svg/cards/mastercard.svg";
import Amex from "../../assets/svg/cards/amex.svg";
import Discover from "../../assets/svg/cards/discover.svg";
import Maestro from "../../assets/svg/cards/maestro.svg";
import VisaElectron from "../../assets/svg/cards/visa-electron.svg";
import JCB from "../../assets/svg/cards/jcb.svg";
import Cirrus from "../../assets/svg/cards/cirrus.svg";

// Replace 'string' with the type of your logo URLs or import types
interface BrandLogoMap {
  [key: string]: string;
}

const Input: React.FC<any> = React.forwardRef((props, ref) => {
  const {
    textArea,
    creditCard,
    brand,
    suggestions,
    autoComplete,
    setInputValue,
    blur,
    focus,
    ...restProps
  } = props;

  const [selected, setSelected] = useState(-1);
  const [isFocused, setIsFocused] = useState(false);

  const brandLogoMap: BrandLogoMap = {
    visa: Visa,
    mastercard: Mastercard,
    "american-express": Amex,
    discover: Discover,
    maestro: Maestro,
    "visa-electron": VisaElectron,
    jcb: JCB,
    cirrus: Cirrus,
    // Add more credit card brands as needed
  };

  const handleLiClick = (e, suggestion) => {
    e.preventDefault();
    setInputValue(suggestion);

    setIsFocused(false); // Remove focus from the input
    ref.current.blur(); // Blur the input element
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSelected(-1); // Reset selected suggestion index when input value changes
  };

  // eslint-disable-next-line
  const inputRef = React.createRef();

  const matchedIndex = autoComplete ? suggestions.findIndex((suggestion) =>
    suggestion.toLowerCase().startsWith(restProps.value.toLowerCase())
  ) : -1;

  let reorderedSuggestions = suggestions;
  if (matchedIndex !== -1) {
    reorderedSuggestions = [
      suggestions[matchedIndex],
      ...suggestions.slice(0, matchedIndex),
      ...suggestions.slice(matchedIndex + 1),
    ];
  }

  if (textArea) {
    return (
      <div className="input-container">
        <span
          className={`input-label ${props.uneditable && "label-uneditable"}`}
        >
          {`${props.label} `}
          {props.required && <span className="input-required">*</span>}
        </span>
        <textarea
          className={`input-area ${props.error && "input-error"} ${
            props.profileSettings && "input-profile-settings"
          } ${props.disabled && "input-disabled"} ${
            props.uneditable && "input-uneditable"
          }`}
          {...restProps}
        />
      </div>
    );
  }

  return (
    <div className="input-container">
      {props.label && (
        <span className={`input-label ${props.uneditable && "label-uneditable"}`}>
          {`${props.label} `}
          {props.required && <span className="input-required">*</span>}
        </span>
      )}
      <div className="input-wrapper">
        {autoComplete ? (
          <input
            ref={ref}
            className={`input ${props.error && "input-error"} ${
              props.profileSettings && "input-profile-settings"
            } ${props.disabled && "input-disabled"} ${
              props.uneditable && "input-uneditable"
            }`}
            {...restProps}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
          />
        ) : (
          <input
            ref={ref}
            className={`input ${props.error && "input-error"} ${
              props.profileSettings && "input-profile-settings"
            } ${props.disabled && "input-disabled"} ${
              props.uneditable && "input-uneditable"
            }`}
            {...restProps}
          />
        )}
        {creditCard && brand && brandLogoMap[brand] && (
          <img
            src={brandLogoMap[brand]}
            alt={brand}
            className="credit-card-logo"
          />
        )}
      </div>
      {autoComplete &&
        isFocused &&
        suggestions.length > 0 && (
          <div className="input-suggestions">
            <ul className="autocomplete-list">
              {reorderedSuggestions.map((suggestion, index) => (
                <li
                  onMouseDown={(e) => handleLiClick(e, suggestion)}
                  onMouseOver={() => {
                    setSelected(index);
                  }}
                  onMouseLeave={() => {
                    setSelected(-1);
                  }}
                  className={`autocomplete-list-item ${
                    selected === index || matchedIndex === index
                      ? "autocomplete-selected"
                      : ""
                  }`}
                  key={index}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
});



export default Input;