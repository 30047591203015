// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import PhoneButton from "../../components/PhoneButton";
import Bubble from "../../components/Bubble";
import AppDogCard from "../../components/AppDogCard";
import Spinner from '../../components/Spinner';
import InputMask from "react-input-mask";
import Confetti from "react-confetti";
import {
  updateAdoption,
  updateUser,
  getDogById,
  getDogDimensions,
  requestAdoption,
  getAdoption,
  getUserByEmail,
  registerSocial,
  updatePassword,
  savePreference
} from "../../service/actions";
import "./application.css";
import { useTranslation } from "react-i18next";
import { usePlacesWidget } from "react-google-autocomplete";
import { useParamsNavigate } from '../../utils';
import CarIcon from "../../assets/svg/CarIcon";
import BabyStrollerIcon from "../../assets/svg/BabyStrollerIcon";
import WeightGreenIcon from "../../assets/svg/WeightGreenIcon";
import QuizStarIcon from "../../assets/svg/QuizStarIcon";
import toast, { Toaster } from 'react-hot-toast';

const AdoptionApp = (props) => {
  const { t, i18n } = useTranslation();

  const [selectedStep, setSelectedStep] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [location, setLocation] = useState("");
  const [pets, setPets] = useState([]);
  const [over18, setOver18] = useState("");
  const [homeType, setHomeType] = useState("");
  const [children, setChildren] = useState("");
  const [homeOwnership, setHomeOwnership] = useState("");
  const [forbiddenHouseholdBreeds, setForbiddenHouseholdBreeds] = useState("");
  const [motivation, setMotivation] = useState("");
  const [contactType, setContactType] = useState("");
  const [estimatedSpend, setEstimatedSpend] = useState("");
  const [previousDog, setPreviousDog] = useState("");
  const [dealbreakers, setDealbreakers] = useState([]);
  const [token, setToken] = useState("");
  const [adoptionId, setAdoptionId] = useState("");
  const [user, setUser] = useState();
  const [dog, setDog] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [adopterId, setAdopterId] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [password, setPassword] = useState("");
  const [emailFromParam, setEmailFromParam] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [traits, setTraits] = useState<any>([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [preferredSize, setPreferredSize] = useState([]);
  const [dogMustBeGoodWith, setDogMustBeGoodWith] = useState([]);
  const [pickupAvailability, setPickupAvailability] = useState('');
  const [modifier, setModifier] = useState('');
  const [language, setLanguage] = useState('unknown');
  const [campaign, setCampaign] = useState('N/A');
  const [hideEmail, setHideEmail] = useState(false);
  const [existingApp, setExistingApp] = useState(true);

  
  const { ref: locationRef2 } = usePlacesWidget({
    apiKey: `AIzaSyDMfA1xyYe0k6Ajf9uqkYNyjB3n813mzd0`,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
    options: {
      types: 'locality'
    }
  });

  console.log(loading, imageLoading, modifier);

  useEffect(() => {
    const sendPreference = async () => {
      const result = await savePreference(email, [], selectedTraits, preferredSize, pickupAvailability, dogMustBeGoodWith);
      datadogLogs.logger.info(
        `Successfully saved preference for user: ${email}`,
        {
          selectedTraits,
          preferredSize,
          dogMustBeGoodWith,
          pickupAvailability,
          result
        }
      );
    }
    sendPreference();
    //eslint-disable-next-line
  }, [preferredSize, selectedTraits, pickupAvailability, dogMustBeGoodWith])


  const step1 = [
    email,
    name,
    phone,
    location,
    motivation
  ];
  const step2 = [password];

  const routerLocation = useLocation();
  const navigate = useParamsNavigate();
  const { dogId } = useParams();

  useEffect(() => {
    const dogDimensions = async () => {
      const dimensions = await getDogDimensions();
      setTraits(dimensions.traits);
    };
    dogDimensions();

    const shortAppLeftDiv = document.querySelector('.short-app-left-full-page');
    if (shortAppLeftDiv) {
      shortAppLeftDiv.scrollTop = 0;
    }
  }, [selectedStep]);

  // const windowWidth = window.innerWidth;

  // const handleMobileButtonWrapperClick = () => {
  //   setIsModalVisible(true);
  // };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useState(() => {
    if (emailFromParam)
      handleEmailRequest();
  }, [emailFromParam]);

  useState(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);


  const handleEmailRequest = async (emailParam = null) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailValue = typeof emailParam === 'string' ? emailParam : email;


    setEmailFromParam('');

    if (!emailPattern.test(emailValue)) {
      toast.error("Please enter a valid email.", { style: { fontWeight: 500 } });
      return;
    }

    try {
      const { data } = await getUserByEmail(emailValue);
      const { user, token } = data;

      setShowEmail(false);
      setAdopterId(user.id);
      setToken(token);
      setUser(user);

      if (user) {
        setName(user.name);
        setPhone(user.phone);
        setLocation(user.address);
      }

      if (!adoptionId && user) {
        const adoptionRequest = {
          name,
          language,
          campaign,
          phone,
          location,
          pets: pets.join(", "),
          over18,
          homeType,
          children,
          homeOwnership,
          forbiddenHouseholdBreeds,
          motivation,
          contactType,
          estimatedSpend,
          previousDog,
          adopterId: user.id,
          dogId,
          dealbreakers,
          status: "INCOMPLETE",
          completed: false
        };
        const adoptRequest = await requestAdoption(adoptionRequest, token);
        setAdoptionId(adoptRequest.data.adoption.id);
        if (adoptRequest?.data.message === 'Adoption requested.') {
          datadogLogs.logger.info(
            `Adoption successfully requested using short app. AdoptionID: ${adoptRequest.data.adoption.id}`,
            {
              dog: adoptRequest.data.adoption.dog,
              user: adoptRequest.data.adoption.user,
              url: window.location.href,
            }
          );

          (window as any).fbq(
            "track",
            `Adoption successfully requested using short app.`,
            {
              dog: adoptRequest.data.adoption.dog,
              user: adoptRequest.data.adoption.user,
              url: window.location.href
            }
          );
        } else {
          setExistingApp(true);
        }
      }

      setLoadingUserData(false);
    } catch (err) {
      if (err.response.status === 404) {
        setExistingApp(false);
        try {
          const { data } = await registerSocial({ email: emailValue });
          console.log('successfully got the user', data);

          const { user, token } = data;

          if (user) {
            setName(user.name);
            setPhone(user.phone);
            setLocation(user.address);
          }

          if (!adoptionId && user) {
            const adoptionRequest = {
              name,
              phone,
              location,
              pets: pets.join(", "),
              over18,
              homeType,
              children,
              homeOwnership,
              forbiddenHouseholdBreeds,
              motivation,
              contactType,
              estimatedSpend,
              previousDog,
              adopterId: user.id,
              dogId,
              dealbreakers,
              status: "INCOMPLETE",
              completed: false,
              language,
              campaign
            };

            const adoptRequest = await requestAdoption(adoptionRequest, token);
            setAdoptionId(adoptRequest.data.adoption.id);
            if (adoptRequest?.data.message === 'Adoption requested.') {
              datadogLogs.logger.info(
                `Adoption successfully requested using short app. AdoptionID: ${adoptRequest.data.adoption.id}`,
                {
                  dog: adoptRequest.data.adoption.dog,
                  user: adoptRequest.data.adoption.user,
                  url: window.location.href,
                }
              );

              (window as any).fbq(
                "track",
                `Adoption successfully requested using short app.`,
                {
                  dog: adoptRequest.data.adoption.dog,
                  user: adoptRequest.data.adoption.user,
                  url: window.location.href
                }
              );
            }
          }

          setShowEmail(false);
          setAdopterId(user.id);
          setToken(token);
          setIsNewAccount(true);
          setUser(user);

          setLoadingUserData(false);
        } catch (err) {
          console.log(err);
        }
      } else {
        toast.error(
          "We could not process your request at this time, please try again later.", { style: { fontWeight: 500 } }
        );

      }
    }
  };

  const handleBubbleClick = (type, value) => {
    if (type === "age") {
      if (selectedAge.includes(value))
        setSelectedAge((prevState) => prevState.filter((e) => e !== value));
      else setSelectedAge((prevState) => [...prevState, value]);
    }
    if (type === "traits") {
      let name = value.name;
      if (selectedTraits.includes(name)) {
        setSelectedTraits((prevState) => prevState.filter((e) => e !== name));
      } else {
        setSelectedTraits((prevState) => [...prevState, name]);
      }
    }
    if (type === "weight") {
      if (selectedWeight.includes(value))
        setSelectedWeight((prevState) => prevState.filter((e) => e !== value));
      else {
        setSelectedWeight((prevState) => [...prevState, value]);
      }
    }
  };

  useEffect(() => {
    let filledCount;
    if (selectedStep === 1) {
      filledCount = step1.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    } else if (selectedStep === 2) {
      filledCount = step2.reduce((count, variable) => {
        if (Array.isArray(variable) && variable.length > 0) {
          return count + 1;
        }
        if (variable) {
          return count + 1;
        }
        return count;
      }, 0);
    }
    console.log(filledCount);
    // eslint-disable-next-line
  }, [
    email,
    password,
    name,
    phone,
    location,
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    forbiddenHouseholdBreeds,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers,
    selectedStep
  ]);

  useEffect(() => {

    datadogLogs.logger.info(
      `Short application page loaded. DogID: ${dogId}`,
      { url: window.location.href }
    );

    (window as any).fbq(
      "track",
      `Short application page loaded.`,
      {}
    );

    if (!dogId) {
      navigate("/");
      console.log();
    } else {
      getDogById(dogId).then((res) => {
        setDog(res.data);
        console.log(res);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, []);

  const queryParams = new URLSearchParams(routerLocation.search);
  useEffect(() => {
    const emailParam = queryParams.get('email');
    const nameParam = queryParams.get('name');
    const phoneParam = queryParams.get('phone');
    const modifierParam = queryParams.get('modifier');
    const campaignParam = queryParams.get('utm_campaign')
    const stepParam = queryParams.get('step');

    if (emailParam) {
      setEmailFromParam(emailParam);
      setEmail(emailParam);
      setHideEmail(true);
      handleEmailRequest(emailParam)
    } else {
      setLoadingUserData(false);
      setExistingApp(false);
    }

    if (nameParam) {
      setName(nameParam);
    };
    if (phoneParam) setPhone(phoneParam);
    if (campaignParam) setCampaign(campaignParam);
    if (modifierParam) setModifier(modifierParam);
    if (stepParam) setSelectedStep(parseInt(stepParam));

    // eslint-disable-next-line
  }, [routerLocation.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const adoptionIdParam = queryParams.get("adoptionId");
    if (token && adopterId) {
      if (!adoptionIdParam) {
        const adoptionRequest = {
          name,
          phone,
          location,
          pets: pets.join(", "),
          over18,
          homeType,
          children,
          homeOwnership,
          forbiddenHouseholdBreeds,
          motivation,
          contactType,
          estimatedSpend,
          previousDog,
          adopterId,
          dogId,
          dealbreakers,
          status: "INCOMPLETE",
          completed: false,
          language,
          campaign
        };
        requestAdoption(adoptionRequest, token)
          .then((res) => {
            setAdoptionId(res?.data.adoption.id);

            datadogLogs.logger.info(
              `Adoption successfully requested using short app. AdoptionID: ${res?.data.adoption.id}`,
              {
                dog: res?.data.adoption.dog,
                user: res?.data.adoption.user,
                url: window.location.href,
              }
            );

            (window as any).fbq(
              "track",
              `Adoption successfully requested using short app.`,
              {
                user: res?.data.adoption.user,
                url: window.location.href
              }
            );
            queryParams.set("adoptionId", res?.data.adoption.id);
            const newSearch = queryParams.toString();
            navigate({ search: `?${newSearch}` });
          })
          .catch(() => navigate("/"));
      } else {
        setAdoptionId(adoptionIdParam);
        getAdoption(adoptionIdParam, '')
          .then((adoptionData) => {
            setPets(adoptionData.pets ? adoptionData.pets.split(", ") : []);
            setOver18(adoptionData.over18);
            setHomeType(adoptionData.homeType);
            setChildren(adoptionData.children);
            setHomeOwnership(adoptionData.homeOwnership);
            setForbiddenHouseholdBreeds(adoptionData.forbiddenHouseholdBreeds);
            setMotivation(adoptionData.motivation);
            setContactType(adoptionData.contactType);
            setEstimatedSpend(adoptionData.estimatedSpend);
            setPreviousDog(adoptionData.previousDog);
            setDealbreakers(adoptionData.dealbreakers);
          })
          .catch(() => navigate("/"));
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleNext = (step) => {
    const nextStep = step !== 0 ? step : selectedStep + 1;
    if (
      nextStep === 2 &&
      (!name || !email ||
        !/^\d{10}$/.test(phone.replace(/[^0-9]/g, "")) ||
        !location)
    ) {
      console.log(name, phone, location);
      let errorMessage = "You need to fill in all the information before continuing.";

      if (!email) {
        errorMessage = "Please provide your email address.";
      } else if (!name) {
        errorMessage = "Please enter your name.";
      } else if (!phone) {
        errorMessage = "Please provide your phone number.";
      } else if (!location) {
        errorMessage = "Please provide your address.";
      }

      toast.error(errorMessage, { style: { fontWeight: 500 } });
    }
    else {
      navigate(`/schedule-call/${dog.id}/?adoptionId=${adoptionId}&phone=${phone}&email=${email}&adoptionId=${adoptionId}`);
    }
  };


  useEffect(() => {
    if (selectedStep === 2) {
      setShowConfetti(true);
    }
  }, [selectedStep]);

  useEffect(() => {
    if (dog && dog.photos && dog.photos[0]) {
      const img = new Image();
      img.src = `https://cdn.petpair.co/55x55/${dog.photos[0]}`;
      img.onload = () => {
        setImageLoading(false);
      };
    }
  }, [dog]);

  const handleDogMustBeGoodWithChange = (value, isChecked) => {
    if (isChecked) {
      setDogMustBeGoodWith((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setDogMustBeGoodWith((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handlePreferredSizeChange = (value, isChecked) => {
    if (isChecked) {
      setPreferredSize((prevDealbreakers) => [...prevDealbreakers, value]);
    } else {
      setPreferredSize((prevDealbreakers) =>
        prevDealbreakers.filter((dealbreaker) => dealbreaker !== value)
      );
    }
  };

  const handleAdoptionUpdate = async (isFinished, isLastStep, route) => {
    // Perform validation or any other necessary checks

    // Create an adoption request object
    const adoptionRequest = {
      name,
      phone,
      location,
      pets: pets.join(", "),
      over18,
      homeType,
      children,
      homeOwnership,
      forbiddenHouseholdBreeds,
      motivation,
      contactType,
      estimatedSpend,
      previousDog,
      adopterId,
      dogId,
      dealbreakers,
      status: isFinished ? "REQUESTED" : "INCOMPLETE",
      language,
      campaign,
      completed: isFinished ? true : false
    };

    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateAdoption(adoptionId, adoptionRequest, token);

      if (isFinished) {
        datadogLogs.logger.info(`Application completed using short app. Application ID: ${adoptionId}`, {result});
      }

      console.log("Adoption updated successfully");

      // Handle the result as per your requirement
      if (result.status === 200) {

        if (isLastStep) {
          try {
            if (isNewAccount) {
              await updatePassword('', password, adopterId, token);
              toast.success("Password set! Updates to your application will be shown here and by email.", { style: { fontWeight: 500 } });

              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
            else {
              await localStorage.setItem("user", JSON.stringify(user));
              await localStorage.setItem("token", JSON.stringify(token));
              navigate(route);
            }
          } catch (err) {
            console.log(err);
          }

        }
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  useEffect(() => {
    console.log(pets);
  }, [pets]);


  const handleUserUpdate = async () => {
    try {
      // Call the updateAdoption function from your service/actions file
      const result = await updateUser(email, phone, name, location, token);

      // Handle the result as per your requirement
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data));
        console.log("Adoption updated successfully", result);
      } else {
        // Adoption update failed, handle the error
        console.error("Adoption update failed");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleAdoptionUpdate(false);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [
    pets,
    over18,
    homeType,
    children,
    homeOwnership,
    motivation,
    contactType,
    estimatedSpend,
    previousDog,
    dealbreakers,
    language,
    campaign
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (token && (name || phone || location)) handleUserUpdate();
    }, 500);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [name, phone, location]);

  if (loadingUserData) {
    return <div className='adoption-loading-screen'><Spinner /></div>
  }

  return (
    <div className="adoption">
      <Toaster />
      {showConfetti && <Confetti numberOfPieces={250} recycle={false} />}
      <div className="short-app-left-full-page">
        <div className="adoption-left-header">
          <div className="app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            {(selectedStep !== 2 && selectedStep !== 3) && <Counter max={5} current={selectedStep === 1 ? 3 : 5} />}
          </div>
          <div></div>
          {selectedStep === 1 && (
            <div className="adoption-short-form-scrollable-container">
              <div className="short-adoption-header">
                <span className="adoption-left-title">{existingApp ? `Welcome back, ${name}! We prefilled your info from a previous application.` : "Last step: we just need a few more details..."}</span>

                <span className="adoption-left-description">
                  {
                    "We need your phone so we can call make sure you and " + dog.name + " are a good fit."
                  }
                </span>
              </div>
              <br />
              <div className="adoption-form-container">
                {!hideEmail && <><Input
                  label={"Email"}
                  value={email}
                  placeholder={"Enter your email"}
                  onChange={handleEmailChange}
                  required
                  disabled={!showEmail}
                  onBlur={handleEmailRequest}
                />
                  <br />
                </>}

                <>
                  <Input
                    label={t("user.nameLabel")}
                    value={name}
                    placeholder={t("user.name")}
                    onChange={handleNameChange}
                    required
                  />
                  <br />
                </>


                <>
                  <InputMask
                    mask="(999) 999-9999"
                    value={phone}
                    onChange={handlePhoneChange}
                  >
                    {(inputProps) => (
                      <Input
                        label={t("user.phoneLabel")}
                        {...inputProps}
                        placeholder="(440) 555-1234"
                        value={phone}
                        onChange={handlePhoneChange}
                        required
                      />
                    )}
                  </InputMask>
                  <br />
                </>



                <>
                  <Input
                    value={location}
                    onChange={handleLocationChange}
                    label={t("user.locationLabel")}
                    placeholder={t("user.location")}
                    required
                    ref={locationRef2}
                  />

                  <br />
                </>


                <Input
                  label={t("Why do you want to adopt?")}
                  value={motivation}
                  placeholder={"Tell us"}
                  onChange={(e) => setMotivation(e.target.value)}
                  textArea
                />
                <br />
              </div>
              <div className='form-button-container'>
                <Button
                  title={selectedStep === 2 ? "Complete" : selectedStep === 1 ? 'Submit' : t("user.next")}
                  filled
                  onClick={() => {
                    if (selectedStep === 1) {
                      (window as any).fbq(
                        "track",
                        `Application completed using short app.`,
                        user
                          ? {
                            name: user.name,
                            email: user.email,
                            address: user.address,
                            applicationId: adoptionId
                          }
                          : { status: "logged out" }
                      );
                      (window as any).fbq(
                        "track",
                        `Purchase completed (Adoption payment).`,
                          {
                            applicationId: adoptionId
                          }
                      );
                      (window as any).fbq(
                        "track",
                        `Purchase`, { value: 199.00, currency: 'USD' }
                      );
                      handleAdoptionUpdate(true, false);
                    } else if (selectedStep === 2) {
                      handleAdoptionUpdate(true, true, '/profile?tab=3')
                    }
                    else {
                      handleAdoptionUpdate(true, false);
                    }
                    handleNext(0);
                  }}
                />
              </div>
            </div>
          )}
          {selectedStep === 4 && (
            <>{isNewAccount ? <div className="adoption-short-form-scrollable-container">
              <Spinner /> : <div className="short-adoption-header">
                <span className="adoption-left-title">
                  {"Application submitted! Now, finish creating your account."}
                </span>
                <span className="adoption-left-description">
                  {
                    "Adding a password will let you access your application and communicate with our adoption team 24/7!"
                  }
                </span>
              </div>
              <br />

              <>
                <br />
                <div className='adoption-form-container' >
                  <Input
                    label={"Email"}
                    value={email}
                    placeholder={"Enter your email"}
                    onChange={handleEmailChange}
                    required
                    onBlur={handleEmailRequest}
                    disabled
                  />
                  <br />


                  <Input
                    label='Password'
                    placeholder='Enter a password'
                    type='password'
                    onChange={(e) => { setPassword(e.target.value) }}
                  />
                </div>
              </>


            </div> : (<div className="adoption-short-form-scrollable-container">
              <div className="short-adoption-header">
                <span className="adoption-left-title">
                  {"Application submitted!"}
                </span>
                <span className="adoption-left-description">
                  {
                    "Now, all that's left is a quick call with our team. Please confirm the phone number below is correct."
                  }
                </span>
              </div>
              <br />

              <div className="adoption-contact-container">
                <span className="adoption-mid-title">
                  The best number to reach you is:
                </span>
                <PhoneButton
                  phoneNumber={phone}
                  fn={() => { setSelectedStep(1); }}
                />
                <br />
                <br />

              </div>


            </div>)}</>
          )}
          {selectedStep === 2 && (
            <div className="adoption-short-form-scrollable-container">
              <div className="short-adoption-header">
                <span className="adoption-left-title">{"Submitted! We’ll chat shortly –let’s get some final details."}</span>
                {/* <Counter max={max} current={current} /> */}
                <span className="adoption-left-description">
                  {"These questions will help us make sure we can find your perfect match."}
                </span>
              </div>

              <div className="adoption-short-form-scrollable-container">
                <div className='adoption-quiz-title'>
                  <CarIcon />
                  <p className='adoption-quiz-title-text'>How far would you drive?</p>
                </div>
                <div className="app-radio-options-vertical">
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="Interested in transport"
                      id="interested-transport"
                      checked={pickupAvailability === "Interested in transport"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="interested-transport">Interested in transport</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="<30 min"
                      id="30min-pickup"
                      checked={pickupAvailability === "<30 min"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="30min-pickup">{'<30 min'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="30-60 min"
                      id="30-60min-pickup"
                      checked={pickupAvailability === "30-60 min"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="30-60min-pickup">{'30-60 min'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="1-2 hrs"
                      id="1-2hrs-pickup"
                      checked={pickupAvailability === "1-2 hrs"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="1-2hrs-pickup">{'1-2 hrs'}</label>
                  </div>
                  <div className="app-radio-option-vertical">
                    <input
                      type="radio"
                      name="previousDog"
                      value="2+ hrs"
                      id="2hrs-pickup"
                      checked={pickupAvailability === "2+ hrs"}
                      onChange={(e) => setPickupAvailability(e.target.value)}
                    />
                    <label htmlFor="2hrs-pickup">{'2+ hrs'}</label>
                  </div>
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <BabyStrollerIcon />
                  <p className='adoption-quiz-title-text'>Should your dog be friendly with...</p>
                </div>
                <div className="checkbox-grid">
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Cats")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Cats",
                          e.target.checked
                        )
                      }
                    />
                    Cats
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Kids")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Kids",
                          e.target.checked
                        )
                      }
                    />
                    Kids
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={dogMustBeGoodWith.includes("Dogs")}
                      onChange={(e) =>
                        handleDogMustBeGoodWithChange(
                          "Dogs",
                          e.target.checked
                        )
                      }
                    />
                    Dogs
                  </label>
                  {/* Add more checkboxes and labels as needed */}
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <WeightGreenIcon />
                  <p className='adoption-quiz-title-text'>How big should they be?</p>
                </div>
                <div className="checkbox-list">
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Small (<20 lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Small (<20 lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Small (<20 lbs)'}
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Medium (20-50 lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Medium (20-50 lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Medium (20-50 lbs)'}
                  </label>
                  <label className='checkbox-input-container'>
                    <input
                      type="checkbox"
                      className="adoption-checkbox"
                      checked={preferredSize.includes("Large (50+ lbs)")}
                      onChange={(e) =>
                        handlePreferredSizeChange(
                          "Large (50+ lbs)",
                          e.target.checked
                        )
                      }
                    />
                    {'Large (50+ lbs)'}
                  </label>
                  {/* Add more checkboxes and labels as needed */}
                </div>
                <br />
                <div className='adoption-quiz-title'>
                  <QuizStarIcon />
                  <p className='adoption-quiz-title-text'>Almost done! Finally, which traits matter the most to you?</p>
                </div>
                <div className="onboarding-bubbles">
                  {traits.map((element, index) => (
                    <Bubble
                      title={element.name}
                      index={element.name + index}
                      selected={selectedTraits.includes(element.name)}
                      onClick={() =>
                        handleBubbleClick("traits", {
                          name: element.name,
                          id: element.id,
                        })
                      }
                    />
                  ))}
                </div>
              </div>
                <div className='form-button-container'>
                  <Button
                    title={selectedStep === 2 ? "Complete" : selectedStep === 1 ? 'Submit' : t("user.next")}
                    filled
                    onClick={() => {
                      if (selectedStep === 1) {
                        (window as any).fbq(
                          "track",
                          `Application completed using short app.`,
                          user
                            ? {
                              name: user.name,
                              email: user.email,
                              address: user.address,
                              applicationId: adoptionId
                            }
                            : { status: "logged out" }
                        );
                        handleAdoptionUpdate(true, false);
                      } else if (selectedStep === 2) {
                        handleAdoptionUpdate(true, true, '/profile?tab=3')
                      }
                      else {
                        handleAdoptionUpdate(true, false);
                      }
                      handleNext(0);
                    }}
                  />
                </div>

            </div>
          )}

          {/* {windowWidth > 900 ? selectedStep < 2 ? (
            <div className="adoption-button-wrapper">
              <Button
                title={"Back"}
                onClick={() => {
                  if (selectedStep === 1) navigate("/dog/" + dog.slug);
                  else setSelectedStep((previous) => previous - 1);
                }}
              />
              <Button
                title={selectedStep === 1 ? "Send" : t("user.next")}
                filled
                onClick={() => {
                  if (selectedStep === 1) {
                    (window as any).fbq(
                      "track",
                      `Application completed using short app.`,
                      user
                        ? {
                          name: user.name,
                          email: user.email,
                          address: user.address,
                          applicationId: adoptionId
                        }
                        : { status: "logged out" }
                    );
                    handleAdoptionUpdate(true, false);
                  }
                  handleNext(0);
                }}
              />
            </div>
          ) : (
            <div className="adoption-button-wrapper">
              <Button
                title={"More dogs"}
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/discovery');
                }}
              />
              <Button
                title={"Complete"}
                filled
                onClick={() => {
                  handleAdoptionUpdate(true, true, '/profile?tab=3');
                }}
              />
            </div>
          ) :
            <div className="adoption-email-mobile-button-wrapper">
              {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
              {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
              <div className='app-email-mobile-bar-wrapper'>
                <div className="adoption-mobile-dog-info" onClick={handleMobileButtonWrapperClick}>
                  {loading ? <div className='app-spinner-container'><Spinner /></div> : !imageLoading && <><img src={`https://cdn.petpair.co/55x55/${dog && dog.photos[0]}`} className='mobile-bar-dog-photo' alt='PetPair' />
                    <div className='app-bar-text-container'>
                      <div className='app-dog-name-container'>
                        <span className='mobile-bar-dog-name'>{dog.name}</span> <DropdownUpArrow />
                      </div>
                      {modifier === 'dog' && <div className='app-modifier-text'>
                        <RoundCheckIcon />
                        <span className='app-modifier-span-obj'>Ready to adopt</span>
                      </div>}
                      {modifier === 'dog-transport' && <div className='app-modifier-text'>
                        <RoundCheckIcon />
                        <span className='app-modifier-span-obj'>Transport available</span>
                      </div>}
                      {modifier === 'dog-friendly' && <div className='app-modifier-text'>
                        <RoundCheckIcon />
                        <span className='app-modifier-span-obj'>Dog, cat and kid friendly</span>
                      </div>}
                    </div></>}
                </div>
                <Button
                  title={selectedStep === 2 ? "Complete" : selectedStep === 1 ? 'Submit' : t("user.next")}
                  filled
                  onClick={() => {
                    if (selectedStep === 1) {
                      datadogLogs.logger.info(`Application completed using short app. Application ID: ${adoptionId}`);
                      (window as any).fbq(
                        "track",
                        `Application completed using short app.`,
                        user
                          ? {
                            name: user.name,
                            email: user.email,
                            address: user.address,
                            applicationId: adoptionId
                          }
                          : { status: "logged out" }
                      );
                      handleAdoptionUpdate(true, false);
                    } else if (selectedStep === 2) {
                      handleAdoptionUpdate(true, true, '/profile?tab=3')
                    }
                    else {
                      handleAdoptionUpdate(true, false);
                    }
                    handleNext(0);
                  }}
                />
              </div>
            </div>
          } */}
        </div>
      </div>


      {selectedStep === 2 ? (
        <div className="right-short-adoption-summary">
          <div className="short-adoption-summary">
            {dog && (
              <img alt='PetPair' src={'https://cdn.petpair.co/150x150/' + dog.photos[0]} className="adoption-img-summary" />
            )}
            <div className="ribbon">
              <span className="ribbon-text">complete!</span>
            </div>
            <div className="adoption-summary-info">
              <span className="adoption-summary-title">{name ? `${name}'s application for ${dog.name}` : `Application for ${dog.name}`}</span>
            </div>
            <div className="adoption-summary-adopter-info">
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Name</span>
                <span className="adoption-summary-value">{name}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Phone number</span>
                <span className="adoption-summary-value">{phone}</span>
              </div>
              <div className="adoption-summary-entry">
                <span className="adoption-summary-label">Location</span>
                <span className="adoption-summary-value">{location}</span>
              </div>
            </div>
            <div className="adoption-summary-entry-full-row">
              <span className="adoption-summary-label">
                Why do you want to adopt?
              </span>
              <span className="adoption-summary-value">{motivation}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className='right-short-adoption'>
          {dog &&
            <AppDogCard slug={dog.slug} address={dog.address} img={dog.photos[0]} name={dog.name} age={dog.age} breed={dog.breed.name} weight={dog.weight} description={dog.description} />
          }
        </div>
      )}

      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >
        {dog && (
          <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
            dog={dog}
          />
        )}
      </Modal>

    </div>
  );
};

export default AdoptionApp;
