import React from 'react';
import Navbar from '../../components/Navbar';
import Button from '../../components/Button';
import illustration from '../../assets/images/notfound.png'
import './not-found.css';

const NotFound = () => {

    return(
        <div className='not-found-wrapper'>
            <Navbar />
            <div className='not-found-root'>
                <div>
                <h1 className='not-found-h1'>Seems like you're lost. We'll get right on fixing that.</h1>
                <div className='not-found-button-wrapper'><Button title='Return home' filled location='/'/></div>
                </div>
                <img src={illustration} className='not-found-img' alt='PetPair Not Found'/>
            </div>
        </div>
    );

}

export default NotFound;