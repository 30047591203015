// @ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from "../../assets/images/deals-logo.png";
import Button from '../../components/Button';
import Input from '../../components/Input';
import Confetti from "react-confetti";
import { getProductById, sendSuggestionToSlack } from '../../service/actions'; // Import getProductById
import { useParamsNavigate } from '../../utils';
import { datadogLogs } from "@datadog/browser-logs";
import './checkout-confirm.css';


const CheckoutConfirm = () => {

    const [productId, setProductId] = useState();
    const [product, setProduct] = useState();
    const [quantity, setQuantity] = useState();
    const [suggestions, setSuggestions] = useState('');
    const [email, setEmail] = useState('');

    const routerLocation = useLocation();
    const queryParams = new URLSearchParams(routerLocation.search);

    const navigate = useParamsNavigate();

    useEffect(() => {
        const productIdFromQuery = queryParams.get('productId');
        const quantityFromQuery = queryParams.get('quantity');
        const email = queryParams.get('email');

        if(email) 
            setEmail(email);

        if(productIdFromQuery) {
            setProductId(productIdFromQuery);
            // Fetch product data based on productId
            getProductById(productIdFromQuery)
                .then((data) => {
                    setProduct(data);
                })
                .catch((error) => {
                    console.error("Error fetching product:", error);
                });
        }

        if(quantityFromQuery)
            setQuantity(quantityFromQuery);

    // eslint-disable-next-line
    }, [routerLocation]);

    const handleSendSuggestion = async () => {
        try {
            // Send suggestion to Slack using the sendSuggestionToSlack function
            await sendSuggestionToSlack(suggestions, email || 'Unknown');

            navigate('/deals');

            // Clear the suggestions input after sending
            setSuggestions('');
        } catch (error) {
            console.error('Error sending suggestion:', error);
            // Optionally handle error, display message to user, etc.
        }
    };

    useEffect(() => {
        datadogLogs.logger.info(`Deals checkout confirmation page loaded!`, {
            url: window.location.href,
          });
    }, []);


    return (
        <div className='deals-page-root-confirm'>
            {product && productId && <Confetti numberOfPieces={1500} recycle={false} />}
            <div className='deals-root-confirm'>
                <div className='deals-header'>
                    <div className='deals-logo-container'>
                        <img className='deals-logo' src={logo} alt='PetPair' />
                    </div>
                </div>
                <br />
                <div>
                    <span className='deals-confirm-title'>{productId && product ? 'You purchased...' : 'Help us improve...'}</span>
                </div>
                <br/>
                {productId && product && <div className='deals-checkout-cart'>
                    <img className='deals-checkout-cart-image' src={product.images[0]} alt={product.name}/> {/* Add alt text */}
                    <div className='deals-checkout-cart-data'>
                        <span className='deals-checkout-cart-title'><span className='deals-checkout-cart-title-green'>DEAL </span>{product.name}</span>
                        <div className='deals-checkout-cart-line2'>
                            x{quantity}
                        </div>
                        <div className='deals-checkout-cart-line3'>
                            <span>${product.price * quantity}</span>
                            <span className='deals-checkout-og-price'>${product.ogPrice * quantity}</span>
                        </div>
                    </div>
                </div>}
                <br/>
                <br/>
                <div className='deals-checkout-confirm-textarea-conåtainer'>
                    <Input textArea label='What deals should we add in the future?' value={suggestions} onChange={(e) => setSuggestions(e.target.value)} />
                </div>
                <br/>
                <br />
                <br />
                <div className='deals-checkout-confirm-buttons-container'>
                    <Button title='Not right now' medium onClick={() => navigate('/deals')}/>
                    <Button title='Let us know' filled medium onClick={handleSendSuggestion}/>
                </div>
            </div>
        </div>
    );
};

export default CheckoutConfirm;
