import React from "react";

const WeightIcon = (props: any) => (
  <svg
    {...props}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4663 4.19837C14.2082 4.19837 13.9668 4.26825 13.7495 4.38246C13.5348 3.69832 12.9312 3.19995 12.213 3.19995C11.3167 3.19995 10.5875 3.97116 10.5875 4.91922L10.5873 7.33216H8.15047H5.50475V4.91922C5.50475 3.97116 4.77556 3.19995 3.87908 3.19995C3.16083 3.19995 2.5568 3.69813 2.34232 4.38205C2.12504 4.26829 1.88407 4.19842 1.62567 4.19842C0.729367 4.19842 0 4.96963 0 5.91769V10.1889C0 11.1369 0.729188 11.9084 1.62567 11.9084C1.88381 11.9084 2.12524 11.8385 2.34232 11.7243C2.55728 12.4084 3.16107 12.9068 3.87908 12.9068C4.77538 12.9068 5.50475 12.1354 5.50475 11.1873V9H8.04601H10.5875L10.5873 11.1873C10.5873 12.1354 11.3162 12.9068 12.2128 12.9068C12.931 12.9068 13.535 12.4086 13.7493 11.7243C13.9663 11.8385 14.208 11.9084 14.4661 11.9084C15.3626 11.9084 16.0922 11.137 16.0922 10.1889L16.092 5.91769C16.0923 4.96963 15.3628 4.19837 14.4663 4.19837ZM1.62561 10.9102C1.27972 10.9102 0.99834 10.5869 0.99834 10.1889L0.998108 5.91776C0.998108 5.52033 1.2797 5.19695 1.62538 5.19695C1.97127 5.19695 2.25265 5.52032 2.25265 5.91776V10.1889C2.25288 10.5866 1.97128 10.9102 1.62561 10.9102ZM4.50629 11.1874C4.50629 11.5851 4.2247 11.9087 3.87902 11.9087C3.53267 11.9087 3.2511 11.5853 3.2511 11.1874L3.25087 4.91948C3.25087 4.52205 3.53246 4.19867 3.87879 4.19867C4.22468 4.19867 4.50606 4.52205 4.50606 4.91948L4.50629 11.1874ZM12.84 11.1874C12.84 11.5851 12.5584 11.9087 12.2123 11.9087C11.8664 11.9087 11.5853 11.5853 11.5853 11.1874V4.91948C11.5853 4.52205 11.8664 4.19867 12.2123 4.19867C12.5585 4.19867 12.84 4.52205 12.84 4.91948V11.1874ZM15.0937 10.189C15.0937 10.5866 14.8121 10.9102 14.466 10.9102C14.1199 10.9102 13.8383 10.5869 13.8383 10.189L13.8385 5.91776C13.8385 5.52033 14.1201 5.19695 14.4662 5.19695C14.8123 5.19695 15.0939 5.52033 15.0939 5.91776L15.0937 10.189Z"
      fill="black"
    />
    <rect x="3" y="4" width="2" height="8" rx="1" fill="black" />
    <rect
      width="3"
      height="6"
      rx="1.5"
      transform="matrix(-1 0 0 1 3 5)"
      fill="black"
    />
    <rect
      width="2"
      height="8"
      rx="1"
      transform="matrix(-1 0 0 1 13 4)"
      fill="black"
    />
    <rect x="13" y="5" width="3" height="6" rx="1.5" fill="black" />
  </svg>
);

export default WeightIcon;
