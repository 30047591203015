// @ts-nocheck

const DogToy = () => (
  <svg
    width="71"
    height="65"
    viewBox="0 0 71 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.5316 13.1834L48.5781 16.23L49.6816 15.1265C50.523 14.2851 50.523 12.9211 49.6818 12.0799C48.8404 11.2385 47.4765 11.2385 46.6351 12.0799L45.5316 13.1834Z"
      fill="#46A522"
    />
    <path
      d="M25.763 19.8764C25.763 19.8764 27.7289 23.8369 32.8839 24.7719C33.3512 24.8568 33.7951 24.5407 33.8778 24.0729C34.1282 22.654 35.2568 19.6688 40.1879 17.1324C40.1879 17.1324 42.4551 16.061 40.6667 13.7958C40.6667 13.7958 37.5675 10.6952 35.1823 13.7958C35.1823 13.7958 31.8236 17.3642 29.0453 17.2142C28.2906 17.1736 27.5315 17.3422 26.8658 17.7005C26.0908 18.1177 25.4053 18.8032 25.763 19.8764ZM25.6498 14.0629C30.7601 9.81887 37.9215 8.828 44.022 11.4574C49.7842 13.9412 54.0799 18.1478 54.0799 18.1478C59.1152 23.1831 58.9819 31.4296 53.6806 36.2952C49.0627 40.5333 41.9155 40.6909 37.1214 36.6531C34.8088 34.705 33.4072 32.1436 32.8828 29.4444C32.8195 29.1192 32.5789 28.8594 32.261 28.7661C27.0286 27.2342 23.1401 21.5456 23.1401 21.5456C21.4794 18.6672 23.2625 16.0457 25.6498 14.0629Z"
      fill="#53B62D"
    />
    <path
      d="M38.4606 20.2554C34.5447 24.1713 34.5447 30.5203 38.4606 34.4362C42.3764 38.352 48.7253 38.3518 52.6412 34.436C56.557 30.5201 56.557 24.1714 52.6412 20.2556C48.7253 16.3397 42.3764 16.3396 38.4606 20.2554Z"
      fill="#90DE6E"
    />
    <path
      d="M17.6499 40.9116C17.6566 40.9658 17.6684 41.0203 17.6916 41.0734L18.577 43.1076C18.7028 43.3968 19.0338 43.5422 19.3165 43.4324L20.4249 43.0016C20.7074 42.8917 20.8345 42.5682 20.7085 42.2792L19.8231 40.245C19.7999 40.1915 19.768 40.1451 19.7323 40.102L19.7449 40.0972C20.0274 39.9875 20.3585 40.1326 20.4844 40.422L21.4428 42.6244C21.5689 42.9135 21.4418 43.2371 21.1592 43.3468L19.0376 44.1714C18.7551 44.2814 18.424 44.1359 18.2982 43.8467L17.3395 41.6444C17.2137 41.3552 17.3407 41.0319 17.6234 40.922L17.6499 40.9116Z"
      fill="#77BED1"
    />
    <path
      d="M57.4934 29.5811C57.4934 29.5811 61.5125 42.6012 49.4152 48.7399C45.4909 50.7312 40.7249 50.3322 37.3012 47.5676C35.9655 46.4888 34.7061 44.9762 33.7225 42.8599C33.7225 42.8599 30.0262 34.8761 24.2055 33.1698C19.6914 31.8463 15.7312 36.3177 17.537 40.6615C17.6253 40.8738 17.7233 41.0912 17.8321 41.314C18.0971 41.8571 18.7476 42.0914 19.3038 41.855C19.8625 41.6174 20.1373 40.9868 19.9328 40.4152C19.7837 39.9983 19.6302 39.485 19.5385 38.9437C19.194 36.9101 21.1096 35.2369 23.088 35.8203C25.2221 36.4499 28.4775 38.4351 31.4669 44.4195C31.4669 44.4195 32.2852 45.7997 33.7322 47.4781C38.6141 53.1415 47.0877 54.0195 53.0285 49.4794C57.8941 45.7609 62.7592 39.0209 57.702 28.2119L57.4934 29.5811Z"
      fill="#53B62D"
    />
    <path
      d="M35.1829 13.7957L34.111 12.1979C33.9956 12.0257 33.7782 11.9543 33.5834 12.0244C33.3051 12.1244 33.1965 12.4604 33.3636 12.7045L34.5394 14.4204L35.1829 13.7957Z"
      fill="#46A522"
    />
    <path
      d="M33.716 15.1293L32.4586 13.2938C32.3387 13.1188 32.1181 13.0428 31.9157 13.1067C31.6072 13.204 31.4804 13.5716 31.6632 13.8384L32.9461 15.7132L33.716 15.1293Z"
      fill="#46A522"
    />
    <path
      d="M32.1561 16.2304L30.6756 14.1483C30.5632 13.9902 30.3608 13.924 30.1767 13.9847C29.8929 14.0785 29.7821 14.4213 29.9573 14.6634L31.3562 16.5981L32.1561 16.2304Z"
      fill="#46A522"
    />
    <path
      d="M30.4256 17.0244L29.0035 15.1025C28.9189 14.988 28.7731 14.9367 28.6356 14.9731C28.3988 15.0353 28.299 15.3161 28.4432 15.5138L29.6644 17.1887L30.4256 17.0244Z"
      fill="#46A522"
    />
    <path
      d="M56.4184 32.9545C56.4184 32.9545 48.524 43.9132 37.4057 35.9964C37.1989 35.8491 37.0101 35.6771 36.8406 35.4877C36.0888 34.6479 33.9765 32.1374 33.085 29.5143C32.9409 29.0901 32.6295 28.7444 32.2332 28.5354C30.3046 27.5188 24.6727 24.25 22.5198 19.7344C22.5198 19.7344 22.5063 22.0914 27.427 26.098C27.427 26.098 29.909 27.9895 31.8019 28.6331C32.3672 28.8255 32.8113 29.2837 32.9705 29.8592C32.9727 29.8664 32.9745 29.874 32.9765 29.8814C32.9765 29.8814 33.4157 33.0135 36.6551 36.2402C36.6551 36.2402 47.2207 46.0938 56.4184 32.9545Z"
      fill="#46A522"
    />
    <path
      d="M40.8236 16.6586C40.8236 16.6586 42.7606 14.6741 41.382 12.9848C41.2747 12.8537 41.1505 12.7385 41.0207 12.6292C41.0126 12.6225 41.0044 12.6156 40.996 12.6085C39.2935 11.1913 36.7369 11.5553 35.4732 13.3746L35.1806 13.7956C35.1806 13.7956 37.234 11.0004 40.4325 13.5631C40.4325 13.5631 42.0591 14.8665 40.8236 16.6586Z"
      fill="#46A522"
    />
    <path
      d="M28.2468 17.2497C28.2468 17.2497 26.1021 16.5857 25.3164 18.2256C25.3164 18.2256 24.5581 18.996 26.4043 20.8587C26.4043 20.8587 25.9503 20.3022 25.8151 19.6044C25.691 18.9634 26.0031 18.3164 26.5551 17.9682C27.0249 17.6719 27.7464 17.2719 28.2468 17.2497Z"
      fill="#46A522"
    />
    <path
      d="M49.8751 14.9004C49.8751 14.9004 37.3605 6.42894 27.3823 13.4758C27.3823 13.4758 23.393 15.4437 22.5201 19.3582C22.5201 19.3582 21.877 13.8265 32.4995 10.5746C32.4995 10.5746 40.1064 7.3438 49.8751 14.9004Z"
      fill="#90DE6E"
    />
    <path
      d="M58.0316 32.5193C58.0316 32.5193 60.4868 41.7365 53.0702 47.6549C53.0702 47.6549 48.1166 51.9427 42.9101 49.9087C42.9101 49.9087 47.0893 51.0452 53.084 46.2866C53.084 46.2866 58.8851 41.7917 58.0316 32.5193Z"
      fill="#46A522"
    />
    <path
      d="M30.4472 42.446C30.4472 42.446 28.1197 36.0933 23.3289 34.8445C21.9466 34.4842 20.4538 35.0172 19.6941 36.2271C19.1023 37.1694 18.8763 38.5872 20.0107 40.6266C20.0107 40.6266 18.6623 37.2425 20.9898 36.0338C21.4662 35.7864 22.0133 35.7017 22.546 35.7676C23.9532 35.9416 26.9992 37.0036 30.4472 42.446Z"
      fill="#46A522"
    />
    <mask
      id="mask0_519_24287"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="13"
      width="20"
      height="20"
    >
      <path
        d="M1.86039 24.0623C2.41603 29.1702 7.00719 32.8605 12.115 32.3049C17.2225 31.7493 20.9128 27.1581 20.3572 22.0502C19.8016 16.9427 15.2104 13.2524 10.1029 13.808C4.99509 14.3637 1.30479 18.9548 1.86039 24.0623Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_519_24287)">
      <path
        d="M1.86039 24.0623C2.41603 29.1702 7.00719 32.8605 12.115 32.3049C17.2225 31.7493 20.9128 27.1581 20.3572 22.0502C19.8016 16.9427 15.2104 13.2524 10.1029 13.808C4.99509 14.3637 1.30479 18.9548 1.86039 24.0623Z"
        fill="#F6A70D"
      />
      <path
        d="M11.444 26.139C14.5653 25.7995 16.8204 22.9939 16.4809 19.8725L15.3631 9.59662L4.0596 10.8262L5.17742 21.1021C5.51697 24.2235 8.32259 26.4786 11.444 26.139Z"
        stroke="#F3F4D6"
        stroke-width="0.963144"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
);

export default DogToy;
