import React, { useEffect, useRef, useState } from "react";
import "./carousel.css";
import ForwardButton from "../../assets/svg/ForwardButton";
import { datadogLogs } from "@datadog/browser-logs";

const DogCarousel: React.FC<{ children: any }> = ({ children }) => {
  const [scrollLeft, setScrollLeft] = useState<Number>(0);
  const [isScrollable, setIsScrollable] = useState<Boolean>(false);

  const carouselRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const current = carouselRef.current as any;
    setIsScrollable(current.scrollWidth > current.clientWidth);
  }, [children]);

  const handleScroll = (event: any) => {
    // const current = carouselRef.current as any;
    const current = carouselRef.current as HTMLDivElement;
    setScrollLeft(current.scrollLeft);

    if (current.scrollLeft >= current.scrollWidth - current.clientWidth - 1) {
      setIsScrollable(false);
      return;
    } 

    if (!isScrollable && current.scrollLeft - current.clientWidth <= 0) {
      setIsScrollable(true);
      return;
    } 
    setIsScrollable(true);
  };

  const handleButtonScrollRight = () => {
    const current = carouselRef.current as HTMLDivElement;
    const scrollWidth = current.clientWidth;
  
    // Scroll to the next page
    current.scrollTo({
      left: current.scrollLeft + scrollWidth,
      behavior: "smooth",
    });
  
    // Check if reached the end
    if (current.scrollLeft + scrollWidth >= current.scrollWidth - 1) {
      setIsScrollable(false);
    }
  
    datadogLogs.logger.info(`Right scroll button clicked on dog carousel.`, {
      url: window.location.href,
    });
  };

  const handleButtonScrollLeft = () => {
    const current = carouselRef.current as HTMLDivElement;
    const scrollWidth = current.clientWidth;
  
    // Scroll to the previous page
    current.scrollTo({
      left: current.scrollLeft - scrollWidth,
      behavior: "smooth",
    });
  
    // Check if reached the beginning
    if (!isScrollable && current.scrollLeft - scrollWidth <= 0) {
      setIsScrollable(true);
    }
  
    datadogLogs.logger.info(`Left scroll button clicked on dog carousel.`, {
      url: window.location.href,
    });
  };

  return (
    <div className="carousel-wrapper">
      <div
        className="carousel"
        onScroll={handleScroll}
        ref={carouselRef as any}
      >
        {children}
        {isScrollable && (
          <div className="right-fade">
            <div className="carousel-forward" onClick={handleButtonScrollRight}>
              <ForwardButton />
            </div>
          </div>
        )}

        {scrollLeft !== 0 && (
          <div className="left-fade">
            <div className="carousel-forward" onClick={handleButtonScrollLeft}>
              <ForwardButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DogCarousel;
