import React, { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Section1 from "./Section1";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import "./home.css";

const Home = () => {
  useEffect(() => {
    document.title = `PetPair | Adopt a certified available dog in your area!`;
    datadogLogs.logger.info("Home page loaded", { url: window.location.href });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="home-root">
        <div className="home-content">
          <Section1 />
          <Section3 />
          <Section4 />
          <Section5 />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
