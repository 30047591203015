import React from "react";
import "./home.css";
import Button from "../../components/Button";
import illustration from "../../assets/images/vacancy.png";
import { useTranslation } from "react-i18next";

const Section5 = () => {
  const { t } = useTranslation();
  return (
    <div className="home-section5">
      <div className="section5-main-content">
        <div className="section5-left">
          <span className="section5-title">{t("home.heading4")}</span>
          <br />
          <br />
          <span className="section5-title2">{t("home.description4")}</span>
          <div className="section5-buttons">
            <Button
              title={t("home.primaryButton4")}
              location="mailto:daniel@petpair.co?subject=Website Contact"
              filled
            />
            <Button
              title={t("home.secondaryButton4")}
              location="/shelters"
            />
          </div>
        </div>
        <div className="section5-right">
          <img alt='PetPair' src={illustration} className="section5-img" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
