import React, { useState } from "react";
import "./payment-tile.css";

const PaymentTiles: React.FC<{icon: any, title: String, onClick: any, isSelected: boolean}> = ({icon, title, onClick, isSelected}) => {
  
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <div className={isMouseOver || isSelected ? "payment-tile-selected" : "payment-tile"} onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)} onClick={() => onClick()}>
      {icon}
      <span className="payment-name">{title}</span>
    </div>
  );
};

export default PaymentTiles;
