import React, { useEffect, useState } from "react";
import "./home.css";
import Button from "../../components/Button";
import DogCarousel from "../../components/DogCarousel";
import DogCard from "../../components/DogCard";
import DogPlate from "../../assets/svg/DogPlate";
import DogToy from "../../assets/svg/DogToy";
import DogBed from "../../assets/svg/DogBed";
import { getDogs } from "../../service/actions";
import { useTranslation } from "react-i18next";

import LoginModal from "../../components/LoginModal";

const Section3 = () => {
  const { t } = useTranslation();
  const [dogs, setDogs] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  const dogsAction = async () => {
    try {
    const request = await getDogs("", "", "", "name", [], [], []);
    setDogs(request.dogs);
    }
    catch {
      console.log("Dogs not loaded.");
    }
  };

  useEffect(() => {
    dogsAction();
  }, []);

  return (
    <div className="home-section3">
      <div className="section3-main-content">
        <div className="section3-left">
          <span className="section3-title">{t("home.heading2")}</span>
          <br />
          <br />
          <span className="section3-title2">{t("home.description2")}</span>
          <div className="perks-container">
            <div className="perk">
              <div className="perk-icon">
                <DogBed />
              </div>
              <span className="perk-text">{t("home.dogBed")}</span>
            </div>
            <div className="perk">
              <div className="perk-icon plate">
                <DogPlate />
              </div>
              <span className="perk-text">{t("home.food")}</span>
            </div>
            <div className="perk">
              <div className="perk-icon toy">
                <DogToy />
              </div>
              <span className="perk-text">{t("home.toys")}</span>
            </div>
          </div>
          <div className="section3-buttons">
            <Button
              title={t("home.primaryButton2")}
              onClick={() => setShowModal(true)}
              filled
            />
            <Button title={t("home.secondaryButton2")} location="/discovery" />
          </div>
        </div>
        <div className="section3-right">
          <DogCarousel>
            {dogs.map((dog: any) => (
              <DogCard
              dog={dog}
                slug={dog.slug}
                key={dog.slug + dog.name + dog.index}
                name={dog.name}
                age={dog.age}
                weight={dog.weight}
                breed={dog.breed.name}
                img={(dog.photos[0] || '')}
                location={"/dog/" + dog.slug}
                id={dog.id}
              />
            ))}
          </DogCarousel>
        </div>
      </div>
      <LoginModal showModal={showModal} handler={setShowModal} createAccount />
    </div>
  );
};

export default Section3;
