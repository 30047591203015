const WhatsAppButtonWhite = () => <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2167_12712)">
<path d="M48 22.5097C48 34.5372 38.2498 44.2875 26.2222 44.2875C14.1947 44.2875 4.44446 34.5372 4.44446 22.5097C4.44446 10.4822 14.1947 0.731934 26.2222 0.731934C38.2498 0.731934 48 10.4822 48 22.5097Z" fill="white"/>
<path d="M18.9062 17.6286C19.0459 16.524 20.4814 15.0228 21.6206 15.1894L21.6188 15.1876C22.7275 15.3982 23.6052 17.3065 24.1 18.1645C24.4507 18.7871 24.223 19.4179 23.8959 19.684C23.4547 20.0397 22.7648 20.5303 22.9095 21.023C23.1669 21.8989 26.2224 24.9544 27.7253 25.823C28.2941 26.1518 28.7066 25.2847 29.0588 24.8404C29.3148 24.4986 29.9461 24.2944 30.5672 24.6317C31.4949 25.172 32.3687 25.7998 33.1769 26.5066C33.5805 26.844 33.6752 27.3425 33.3955 27.8694C32.903 28.7974 31.4784 30.0213 30.4442 29.7725C28.638 29.3383 21.3335 26.5066 18.9873 18.3031C18.8553 17.915 18.8886 17.7683 18.9062 17.6286Z" fill="#53B62D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.2225 35.9543C24.7269 35.9543 23.8995 35.7938 22.5558 35.3432L19.9823 36.63C18.357 37.4426 16.4447 36.2607 16.4447 34.4435V31.6765C13.8127 29.2223 12.778 26.3925 12.778 22.5099C12.778 15.0847 18.7973 9.06543 26.2225 9.06543C33.6476 9.06543 39.6669 15.0847 39.6669 22.5099C39.6669 29.935 33.6476 35.9543 26.2225 35.9543ZM18.8891 30.6136L18.1117 29.8887C16.0669 27.982 15.2225 25.8503 15.2225 22.5099C15.2225 16.4347 20.1473 11.5099 26.2225 11.5099C32.2976 11.5099 37.2225 16.4347 37.2225 22.5099C37.2225 28.5851 32.2976 33.5099 26.2225 33.5099C25.0177 33.5099 24.4527 33.4011 23.3331 33.0256L22.3706 32.7028L18.8891 34.4435V30.6136Z" fill="#53B62D"/>
</g>
<defs>
<filter id="filter0_d_2167_12712" x="0.444458" y="0.731934" width="51.5555" height="51.5555" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2167_12712"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2167_12712" result="shape"/>
</filter>
</defs>
</svg>

export default WhatsAppButtonWhite;
