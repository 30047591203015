// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import AppDogCard from "../../components/AppDogCard";
import {
  getDogById
} from "../../service/actions";
import "./app-entry-point.css";
// import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";

const ApplicationEntryPoint = (props) => {
  // const { t, i18n } = useTranslation();

  const [dog, setDog] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modifier, setModifier] = useState('');
  const routerLocation = useLocation();
  const queryParams = new URLSearchParams(routerLocation.search);



  const navigate = useParamsNavigate();
  const { dogId } = useParams();


  const handleModalClose = () => {
    setIsModalVisible(false);
  };


  useEffect(() => {


    if (!dogId) {
      navigate("/");
      console.log();
    } else {

        getDogById(dogId).then((res) => {
          setDog(res.data);
          console.log(res);
        });

        datadogLogs.logger.info(
          `Application landing page loaded for Test A.`,
          { url: window.location.href }
        );
    }


    datadogLogs.logger.info(
      `Application landing page loaded.`,
      { url: window.location.href }
    );
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    if (modifierParam) setModifier(modifierParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);


  return (
    <div className="adoption">
      <div className="short-entry-left">
        <div className="adoption-left-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            <Counter max={5} current={1} />
          </div>
          <span className="adoption-entry1-left-title">Are you interested in adopting {dog && dog.name}?</span>
          {dog && <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
            app
            dog={dog}
          />}
        </div>
      </div>

      <div className="adoption-email-mobile-button-wrapper">
        {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
        {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
        <div className='app-entry-mobile-bar-wrapper'>
          <Button
            title={'See other dogs'}
            medium
            location={'/quiz'}
          />
          <Button
            title={'Yes!'}
            filled
            medium
            location={`/application/step2/${dog && dog.id}`}
          />
        </div>
      </div>



      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >
        {dog && (
          <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
            modal
          />
        )}
      </Modal>

    </div>
  );
};

export default ApplicationEntryPoint;
