// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'antd';
import { useLocation, useParams } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import Button from "../../components/Button";
import NumberButton from "../../components/NumberButton";
import Counter from "../../components/Counter";
import logo from "../../assets/images/logo.png";
import AppDogCard from "../../components/AppDogCard";
import {
  getDogById,
  createCalendarEventRequest
} from "../../service/actions";
import "./app-schedule.css";
// import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';
import CallSmallIcon from "../../assets/svg/CallSmallIcon";
import CheckBoxIcon from "../../assets/svg/CheckBoxIcon";
import CalendarGreenIcon from "../../assets/svg/CalendarGreenIcon";
import TimerIcon from "../../assets/svg/TimerIcon";
import { fetchCalendarAvailability } from "../../service/actions"; // Import the function to fetch calendar availability
import IndicativeArrow from "../../assets/svg/IndicativeArrow";
import toast, { Toaster } from 'react-hot-toast';


const ApplicationSchedule = (props) => {
  // const { t, i18n } = useTranslation();

  const [dog, setDog] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [availability, setAvailability] = useState(null); // State to hold calendar availability
  const [selectedDay, setSelectedDay] = useState(null);
  const [userTimezone, setUserTimezone] = useState('');
  const [modifier, setModifier] = useState('');
  const [selectedTime, setSelectedTime] = useState(null);
  const [showNavigationArrow, setShowNavigationArrow] = useState(false);
  const [dateOffset, setDateOffset] = useState(0);
  const [adopterEmail, setAdopterEmail] = useState('');
  const [adoptionId, setAdoptionId] = useState('');
  const routerLocation = useLocation();
  const queryParams = new URLSearchParams(routerLocation.search);
  const scrollRef = useRef(null);
 
  const handleConfirm = async () => {
    if (!selectedTime) {
      toast.error('Please select a time.');
      return;
    }

    let userId = '', email = '', name = '', contactNumber = '';
    const browserLanguage = navigator.language || navigator.userLanguage;

    if (browserLanguage.startsWith('es')) {
      userId = '115211561607430773238'; // Spanish language
      email = 'yanett@petpair.co';
      name = 'Yanett';
      contactNumber = '(832) 529-4282';
    } else { //eslint-disable-next-line
      userId = '117375116562203365594'; // Any other language
      email = 'christie@petpair.co';
      name = 'Christie';
      contactNumber = '(469) 518-7582';
    }

    const eventData = {
      organizer: 'adopt@petpair.co',
      invitees: [email, adopterEmail],
      name: `${dog.name} Adoption Inquiry`,
      description: `We can’t wait to chat with you about ${dog.name}! ${name} will call you at ${selectedTime.toLocaleTimeString()} to answer your questions about adoptions — please feel free to reach out to ${contactNumber} if you have any questions or need to reschedule.`,
      startDateTime: selectedTime.toISOString(),
      endDateTime: new Date(selectedTime.getTime() + 30 * 60 * 1000).toISOString(),
      adoptionId,
      userId
    };

    try {
      await createCalendarEventRequest(eventData);
      datadogLogs.logger.info(
        `Call successfully scheduled! Email: ${adopterEmail}`,
        {
          dog: dog,
          adopterEmail,
          eventData
        }
      );
      navigate(`/application/step3/${dog.id}/?email=${adopterEmail}&step=2`);
    } catch (error) {
      console.error('Error creating event:', error);
      datadogLogs.logger.info(
        `Error scheduling call for ${adopterEmail}`,
        {
          dog: dog,
          adopterEmail,
          error
        }
      );
      // Handle error
    }
  };


  const navigate = useParamsNavigate();
  const { dogId } = useParams();

  useEffect(() => {


    if (!dogId) {
      navigate("/");
      console.log();
    } else {
      getDogById(dogId).then((res) => {
        setDog(res.data);
        console.log(res);
      });
    }


    // eslint-disable-next-line
  }, []);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const fetchAvailability = async () => {
    let userId;
    const browserLanguage = navigator.language || navigator.userLanguage;

    if (browserLanguage.startsWith('es')) {
        userId = '115211561607430773238'; // Spanish language
    } else {
        userId = '117375116562203365594'; // Any other language
    }
    try {
      const availabilityData = await fetchCalendarAvailability(userId); // Replace userId and accessToken with actual values
      setAvailability(availabilityData);
    } catch (error) {
      console.error('Error fetching availability:', error);
    }
  };

  useEffect(() => {
    fetchAvailability(); // Fetch availability when component mounts
    const userTimezone = new Date().toLocaleTimeString([], { timeZoneName: 'short' }).split(' ')[2];
    setUserTimezone(userTimezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDaySelect = (date) => {
    setSelectedDay(date);
  };

  const getDateForOffset = (offset) => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Function to handle navigation to future dates
  const goToNextDates = () => {
    setDateOffset(dateOffset + 7); // Move forward by 7 days
  };



  useEffect(() => {
    const modifierParam = queryParams.get('modifier');
    const emailParam = queryParams.get('email');
    const adoptionIdParam = queryParams.get('adoptionId');

    if (modifierParam) setModifier(modifierParam);
    if (emailParam) setAdopterEmail(emailParam);
    if (adoptionIdParam) setAdoptionId(adoptionIdParam);
    // eslint-disable-next-line
  }, [routerLocation.search]);

  useEffect(() => {
    datadogLogs.logger.info(
      `Schedule page loaded! Email: ${adopterEmail}`,
      {
        dog: dog,
        adopterEmail
      }
    );
  // eslint-disable-next-line
  }, [adopterEmail])


  const renderTimeAvailability = () => {
    if (!availability || !selectedDay) return null;
  
    // Define the opening and closing hours (12 PM to 4 PM CST)
    const openingHourCST = 12; // 12 PM CST
    const closingHourCST = 16; // 4 PM CST
  
    // Convert selected day to the user's timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedDayUserTimezone = new Date(selectedDay.toLocaleString("en-US", { timeZone: userTimezone }));
  
    // Generate an array of all business hours for the selected day in the user's timezone
    const businessHours = [];
    for (let hour = openingHourCST; hour <= closingHourCST; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = new Date(selectedDayUserTimezone);
        time.setHours(hour, minute, 0, 0);
        businessHours.push(time);
      }
    }
  
    // Filter busy times for the selected day in the user's timezone
    const busyTimes = availability.busyTimes.calendars.primary.busy
      .map(event => ({
        start: new Date(event.start),
        end: new Date(event.end)
      }))
      .filter(event => event.start.toDateString() === selectedDayUserTimezone.toDateString());
  
    // Categorize available business hours into morning, afternoon, and evening sections
    const morningHours = [];
    const afternoonHours = [];
    const eveningHours = [];
    businessHours.forEach(hour => {
      const hourInUserTimezone = new Date(hour.toLocaleString("en-US", { timeZone: userTimezone }));
      if (hourInUserTimezone.getHours() >= 12 && hourInUserTimezone.getHours() < 17) {
        morningHours.push(hourInUserTimezone);
      } else if (hourInUserTimezone.getHours() >= 17 && hourInUserTimezone.getHours() < 21) {
        afternoonHours.push(hourInUserTimezone);
      } else {
        eveningHours.push(hourInUserTimezone);
      }
    });
  
    // Function to render time button
    const renderTimeButton = (hour, index) => {
      const isBusy = busyTimes.some(event => hour >= event.start && hour < event.end);
      const isSelected = selectedTime && selectedTime.getTime() === hour.getTime();
  
      return (
        <Button
          key={index}
          title={hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          small
          disabled={isBusy}
          filled={isSelected}
          onClick={() => {
            if (!isBusy) {
              setSelectedTime(hour); // Update selected time in state
            }
          }}
        />
      );
    };
  
    // Display available business hours within the specified ranges
    return (
      <div className="time-availability-grid">
        {morningHours.length > 0 && (
          <div className="time-availability-section">
            {/* <h3 className='time-period-title'>Morning</h3> */}
            {morningHours.map((hour, index) => renderTimeButton(hour, index))}
          </div>
        )}
        {afternoonHours.length > 0 && (
          <div className="time-availability-section">
            {/* <h3 className='time-period-title'>Afternoon</h3> */}
            {afternoonHours.map((hour, index) => renderTimeButton(hour, index))}
          </div>
        )}
        {eveningHours.length > 0 && (
          <div className="time-availability-section">
            {/* <h3 className='time-period-title'>Evening</h3> */}
            {eveningHours.map((hour, index) => renderTimeButton(hour, index))}
          </div>
        )}
      </div>
    );
  };
  


  const handleScroll = () => {
    const container = scrollRef.current;
    setShowNavigationArrow(container.scrollLeft !== 0);
  };

  return (
    <div className="adoption">
      <Toaster />
      <div className="short-entry-left">
        <div className="adoption-left-header">
          <div className="short-app-steps-container">
            <img src={logo} alt='PetPair' className='app-logo' onClick={() => navigate('/')} />
            <Counter max={5} current={4} />
          </div>
          <span className='adoption-entry1-left-title'>✔ {dog && dog.name} is a great choice! Next, let’s schedule a call to talk about how she’ll fit in your home.</span>
          <div className='schedule-title-container'>
            <CalendarGreenIcon />
            <span className="adoption-schedule-left-title">Select a day</span>
          </div>
          <div className='day-selector-container'>
          <div className='day-selector' onScroll={handleScroll} ref={scrollRef}>
  {/* NumberButtons and day indicators */}
  {Array.from({ length: 15 }).map((_, index) => {
    const currentDate = new Date(getDateForOffset(index + dateOffset));
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates
    const isToday = currentDate.toDateString() === today.toDateString(); // Compare only date portion
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Get yesterday's date
    const isYesterday = currentDate.toDateString() === yesterday.toDateString(); // Check if currentDate is yesterday
    // Skip Sundays (day 0), Saturdays (day 6), and yesterday
    if (currentDate.getDay() === 0 || currentDate.getDay() === 6 || isYesterday) {
      return null;
    }
    return (
      <div className={`day-button ${isToday ? 'today' : ''}`} key={index} onClick={() => handleDaySelect(currentDate)} style={{ marginRight: '10px' }}>
        <NumberButton medium title={currentDate.getDate()} filled={selectedDay && currentDate.toLocaleDateString() === selectedDay.toLocaleDateString()} />
        <span className='day-indicator'>{isToday ? 'today' : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][currentDate.getDay()]}</span>
      </div>
    );
  })}
</div>




            <div className="navigation-arrow" onClick={goToNextDates} style={{ display: !showNavigationArrow ? 'block' : 'none' }}><IndicativeArrow /></div>
          </div>
          {selectedDay && <><div className='schedule-title-container'>
            <TimerIcon />
            <span className="adoption-schedule-left-title">Select a time ({userTimezone})</span>
          </div>
            {renderTimeAvailability()}</>}
        </div>
      </div>

      <div className="adoption-email-mobile-button-wrapper">
        {modifier === 'call' && <div className="app-bar-top-text"><CallSmallIcon /><span className='app-modifier-span-obj'>We'll call within 24 hours to talk next steps</span></div>}
        {modifier === 'apply-now' && <div className="app-bar-top-text"><CheckBoxIcon /><span className='app-modifier-span-obj'>Apply now to be first in line</span> </div>}
        <div className='app-entry-mobile-bar-wrapper'>
          <Button
            title={'Later'}
            medium
            location={'/discovery'}
          />
          <Button
            title={'Confirm!'}
            filled
            medium
            disabled={!selectedTime}
            onClick={handleConfirm}
          />
        </div>
      </div>



      <Modal
        visible={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <></>
        ]}
        closable={false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }}
        wrapClassName="transparent-modal"
      >
        {dog && (
          <AppDogCard
            slug={dog.slug}
            address={dog.address}
            img={dog.photos[0]}
            name={dog.name}
            age={dog.age}
            breed={dog.breed.name}
            weight={dog.weight}
            description={dog.description}
            modal
          />
        )}
      </Modal>

    </div>
  );
};

export default ApplicationSchedule;
