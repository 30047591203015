const Account = (props: any) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 2.1C8.162 2.1 9.1 3.038 9.1 4.2C9.1 5.362 8.162 6.3 7 6.3C5.838 6.3 4.9 5.362 4.9 4.2C4.9 3.038 5.838 2.1 7 2.1ZM7 12.04C5.25 12.04 3.703 11.144 2.8 9.786C2.821 8.393 5.6 7.63 7 7.63C8.393 7.63 11.179 8.393 11.2 9.786C10.297 11.144 8.75 12.04 7 12.04Z"
      fill="#49992B"
    />
  </svg>
);

export default Account;
