// @ts-nocheck
import React, { useState } from "react";
import { login, register } from "../../service/actions";
import { Modal } from "antd";
import { showToast } from "../../utils";
import Button from "../Button";
import Spinner from "../Spinner";
import Input from "../Input";
import "./loginModal.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';

const LoginModal: React.FC<{
  showModal?: boolean;
  handler: Function;
  createAccount?: boolean;
  applyToAdopt?: boolean;
  dogId?: string;
}> = ({ showModal, handler, createAccount, applyToAdopt, dogId }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountCreation, setAccountCreation] = useState(createAccount);

  const navigate = useParamsNavigate();

  const handleRegister = async () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      showToast(t("user.invalidEmail"));
      return false;
    }

    register(email, password)
      .then((result) => {
        if (result.status === 200) {
          setIsDone(true);
          localStorage.setItem("user", JSON.stringify(result?.data.user));
          localStorage.setItem("token", JSON.stringify(result?.data.token));
          setTimeout(() => {
            if (applyToAdopt) {
              navigate(`/adopt/create/${result?.data.user.id}/${dogId}`, {
                state: { email, token: result.data.token },
              });
            } else
              navigate("/onboarding", {
                state: { email, token: result.data.token },
              });
          }, 1500);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showToast(t("user.accountCreationError"));
      });
  };

  const handleLogin = () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      showToast(t("user.invalidEmail"));
      return false;
    }

    const loginUser = async () => {
      try {
        const request = await login(email, password);
        if (request.data) {
          localStorage.setItem("user", JSON.stringify(request?.data.user));
          localStorage.setItem("token", JSON.stringify(request?.data.token));
          setIsDone(true);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        return request.data ? true : false;
      } catch {
        setIsLoading(false);
        showToast(t("user.loginError"));
      }
    };

    loginUser();
  };

  const handleOk = () => {
    handler(false);
  };

  const validateEmail = (email) => {
    // Simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCancel = () => {
    handler(false);
  };

  if (accountCreation) {
    return (
      <>
        <Modal
          open={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="modal-root">
            <div className="account-creation-header">
              <span className="account-creation-title">
                {t("user.createAccountHeader")}
              </span>
              <span className="account-creation-description">
                {t("user.createAccountDescription")}
              </span>
            </div>
            <Input
              placeholder={t("user.email")}
              label="Email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <Input
              placeholder={t("user.passwordCreation")}
              label={t("user.password")}
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />

            {isLoading ? (
              <div className="spinner-wrapper">
                <Spinner isDone={isDone} />
              </div>
            ) : (
              <div className="modal-button-wrapper">
                <Button
                  filled
                  title={t("user.createAccountButton")}
                  onClick={handleRegister}
                />
              </div>
            )}
            <br />
            <div
              className="login-account-create"
              onClick={() => setAccountCreation(false)}
            >
              <span className="login-grey-text">
                {t("user.existentAccountCta1")}
              </span>
              <span className="login-green-text">
                {t("user.existentAccountCta2")}
              </span>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-root">
          <Input
            placeholder={t("user.email")}
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <Input
            placeholder={t("user.password")}
            label={t("user.password")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="login-forgot-container">
            <span className="login-forgot-password">{t("user.forgotPassword")}</span>
          </div>
          <br />
          {isLoading ? (
            <div className="spinner-wrapper">
              <Spinner isDone={isDone} />
            </div>
          ) : (
            <div className="modal-button-wrapper">
              <Button
                filled
                title={t("user.loginButton")}
                onClick={handleLogin}
              />
            </div>
          )}
          <br />
          <div
            className="login-account-create"
            onClick={() => setAccountCreation(true)}
          >
            <span className="login-grey-text">
              {t("user.createAccountCta1")}
            </span>
            <span className="login-green-text">
              {t("user.createAccountCta2")}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LoginModal;
